import axios from "axios";

class AccountService {
    async getAccount (id,refresh) {
        let responseObject = {};
        await axios.get(`account/${id}`,{headers:{Authorization:`bearer ${sessionStorage.getItem("access_token")}`}})
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
            if(error.response.status === 401 && !refresh) {
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.getAccount(id,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response && error.response.data && error.response.data.message) {
                        responseObject =  error.response.data.message;
                    } else {
                        responseObject =  error.message;
                    }
                }); 
            }
        })
        return responseObject;
    }

    async getAccounts (page,refresh) {
        let responseObject = {};
        await axios.get(`accounts`,{
            params: {
                pageNo:page.pageNo,
                pageSize:page.pageSize,
                sortBy:page.sortBy,
                sortDir:page.sortDir
            },
            headers:{
                Authorization:`bearer ${sessionStorage.getItem("access_token")}`
            }
        })
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data) {
                responseObject =  error.response.data;
            } else {
                responseObject =  error.message;
            }
            if(error.response.status === 401 && !refresh) {
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.getAccounts(page,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response && error.response.data && error.response.data.message) {
                        responseObject =  error.response.data.message;
                    } else {
                        responseObject =  error.message;
                    }
                }); 
            }
        })
        return responseObject;
    }
}

export default new AccountService();