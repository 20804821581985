import React, {useState,useEffect,useContext,useRef} from 'react'
import { LiaAngleLeftSolid,LiaAngleRightSolid } from "react-icons/lia";
import { GlobalContext } from '../contexts/GlobalContext';
import UserService from '../services/UserService';
import RoleService from '../services/RoleService';
import Message from './Message';
import FormValidator from './FormValidator';
import {useFormValidator} from './FormValidator';
import Input from './Input';
import Table from './Table';
import Select from './Select';

  const AddUsersTable = ({pages,pageSize,totalElements,setResults}) => {
    const {setLoading} = useContext(GlobalContext);
    const [pageNo,setPageNo] = useState(0);
    const [last,setLast] = useState(false);
    const [page,setPage] = useState(null);
    const [password,setPassword] = useState('');
    const [message,setMessage] = useState({content:'',success:false});
    const [role,setRole] = useState(null);
    const [roles,setRoles] = useState([]);

    const columns = [
        {
            name:'username',
            label:'Username'
        },
        {
            name:'firstname',
            label:'Firstname'
        },
        {
            name:'lastname',
            label:'Lastname'
        },
        {
            name:'middlename',
            label:'Middlename'
        },
        {
            name:'sex',
            label:'Sex'
        },
        {
            name:'dateOfBirth',
            label:'Date of Birth'
        },
        {
            name:'idType',
            label:'ID Type'
        },
        {
            name:'idNumber',
            label:'ID Number'
        },
        {
            name:'nationality',
            label:'Nationality'
        },
        {
            name:'email',
            label:'Email'
        },
        {
            name:'phone1',
            label:'Phone 1'
        },
        {
            name:'phone2',
            label:'Phone 2'
        },
        {
            name:'physicalAddress',
            label:'Physical Address'
        },
        {
            name:'postalAddress',
            label:'PostalAddress'
        },
        {
            name:'province',
            label:'Province'
        },
        {
            name:'district',
            label:'District'
        },
        {
            name:'program',
            label:'Program'
        },
        {
            name:'institution',
            label:'Institution'
        },
        {
            name:'professionalCategory',
            label:'Professional Category'
        },
        {
            name:'employed',
            label:'Employed'
        },
        {
            name:'employer',
            label:'Employer'
        },
        {
            name:'organizationalUnit',
            label:'Organizational Unit'
        },
        {
            name:'currentPosition',
            label:'Current Position'
        },
        {
            name:'facility',
            label:'Facility'
        }
    ]

    const handleNext = (e) => {
        if(e) e.preventDefault();
        if(pageNo < pages.length - 1) {
            setPageNo(pageNo + 1);
        }
    }

    const handlePrevious = (e) => {
        if(e) e.preventDefault();
        if(pageNo > 0) {
            setPageNo(pageNo - 1);
        }
    }

    const getUsers = async (filter,page) => {
        if(pages && page && page.pageNo < pages.length) {
            return {
                status:'SUCCESSFUL',
                content:pages[page.pageNo],
                pageNo:page.pageNo,
                pageSize:page.pageSize,
                totalElements:(()=>{
                                let count = 0;
                                for(let page of pages) {
                                    count += page.length;
                                }
                                return count;
                            })(),
                totalPages:Math.ceil(pages.length/page.pageSize),
                last:page.pageNo >= Math.ceil(pages.length/page.pageSize)
            }
        } else {
            return {
                status:'FAILED'
            }
        }
    }

    const onSubmit = async  () => {
        setLoading(true);
        setMessage({content:'',success:false});
        let users = [];
        for(let page of pages) {
            for(let user of page) {
                users.push(user);
            }
        }
        await UserService.saveUsers(users,password,role.name,false)
        .then(async (saveResponse) => {
            if(saveResponse.status) {
                if(saveResponse.status === 'SUCCESSFUL' && saveResponse.content) {
                    setResults(saveResponse.content,{content:saveResponse.message,success:true})
                } else if(saveResponse.message) {
                    setMessage({content:saveResponse.message,success:false});
                }
            } else {
                setMessage({content:saveResponse,success:false});
            }
            setLoading(false);
        })
        .catch((error) => {
            setMessage({content:error.message,success:false});
            setLoading(false);
        }); 
    }

    const [register,handleChange,handleSubmit,errors] = useFormValidator(onSubmit);

    const inputWidth = 320;

    useEffect(() => {
        ( async () => {
            setLoading(true);
            await RoleService.getRoles()
            .then((response) => {
                setLoading(false);
                if(response.content) {
                    setRoles(response.content);
                }  else {
                    setRoles([]);
                }
            })
            .catch((error) => {
                console.log(error.message);
                setRoles([]);
            })
        }
        )();
    },[]);

    return (
        <div className='relative flex flex-col w-full h-full'>
            <Table columns={columns} retrieveRecords={getUsers}/>
            <div className='flex flex-row px-8 justify-between'>
                <Input 
                    label="Password"
                    type="password"
                    id="password" 
                    name="password"  
                    value={password}
                    onChange= {(e) => handleChange(e,(e) => {
                        setPassword(e.target.value)
                    })}
                    width={inputWidth}
                    register={register}
                    errors={errors}
                />
                <Select 
                    label="Role"
                    type="text"
                    id="role" 
                    name="role"  
                    value={role?role.name:''}
                    onChange= {(e) => handleChange(e,(e) => {
                        if(roles) {
                            let value = roles.find(role => {return role.name == e.target.value});
                            if(value) {
                                setRole(value);
                            }
                        }
                    })}
                    width={inputWidth}
                    register={register}
                    errors={errors}
                >{roles && roles.map((role,i) => 
                        <option key={i} value={role.name}>{role.name}</option>
                    )
                }
                </Select>
            </div>
            <Message message={message}/>
            <button style={{'--width':inputWidth+'px'}}
                onClick={handleSubmit} className='flex shrink-0 w-[var(--width)] h-10 mx-auto rounded-lg items-center justify-center bg-[rgb(0,175,240)] hover:bg-[rgba(0,175,240,.7)] text-white'>
                Submit
            </button>
        </div>
    )
}

export default AddUsersTable

