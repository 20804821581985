import React,{useEffect,useContext} from 'react';
import { GlobalContext } from '../contexts/GlobalContext';
import DropDownItem from './DropDownItem';


const DropDown = ({parentDir,show,setShow,dropDown,setDropDown}) => {
    const {convertToId} = useContext(GlobalContext);
    const handleMouseLeave = (e) => {
        setShow(false);;
    }
    useEffect(() => { 
        let dropDownElem = document.getElementById(dropDown.id);
        if(show) {
            if(dropDownElem) {
                let rect = dropDownElem.parentElement.getBoundingClientRect();
                dropDownElem.style.top = rect.bottom+'px';
                dropDownElem.style.left = rect.left+'px';
                dropDownElem.style.visibility = 'visible';
                dropDownElem.style.opacity = 1;
                dropDownElem.animate({opacity:[0,1]},{duration:500});
            }
        } else {
            if(dropDownElem) {
                dropDownElem.animate({opacity:[1,0]},{duration:500}).addEventListener('finish',() => {
                    dropDownElem.style.visibility = 'hidden';
                    setDropDown(null);
                });
            };
        }
    },[dropDown,show]);
  return (
    <div id={dropDown.id} 
        onMouseLeave={(e) => handleMouseLeave(e)} 
        className='fixed flex flex-col space-y-4 w-auto h-auto py-6 shrink-0 invisible opacity-0 z-30 bg-[rgb(238,238,238)] shadow-md'>
        {dropDown.menus && dropDown.menus.map((menu,i) => <DropDownItem key={i} id={menu.link} link={menu.link}  name={menu.name} menus={menu.menus} show={show} setShow={setShow}/>)}
    </div>
  )
}

export default DropDown