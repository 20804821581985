import axios from "axios";

class TransactionService {
    async getTransactions (filter,page,refresh) {
        let responseObject = null;
        await axios.get("transactions",{
            params: {
                userId:filter.userId,
                paymentType:filter.paymentType,
                currency:filter.currency,
                amount:filter.amount,
                accountNumber:filter.accountNumber,
                ipAddress:filter.ipAddress,
                message:filter.message,
                transactionId:filter.transactionId,
                externalId:filter.externalId,
                createdOnDay:filter.createdOnDay,
                createdOnMonth:filter.createdOnMonth,
                createdOnYear:filter.createdOnYear,
                status:filter.status,
                pageNo:page.pageNo,
                pageSize:page.pageSize,
                sortBy:page.sortBy,
                sortDir:page.sortDir
            },
            headers:{
                Authorization:`bearer ${sessionStorage.getItem("access_token")}`
            }
        })
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.getUsers(filter,page,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response && error.response.data && error.response.data.message) {
                        responseObject =  error.response.data.message;
                    } else {
                        responseObject =  error.message;
                    }
                }); 
            } 
        })

        return responseObject;
    }

    async getMyTransactions (userId,filter,page,refresh) {
        let responseObject = null;
        await axios.get("transactions/my",{
            params: {
                userId:userId,
                paymentType:filter.paymentType,
                currency:filter.currency,
                amount:filter.amount,
                accountNumber:filter.accountNumber,
                ipAddress:filter.ipAddress,
                message:filter.message,
                transactionId:filter.transactionId,
                createdOnDay:filter.createdOnDay,
                createdOnMonth:filter.createdOnMonth,
                createdOnYear:filter.createdOnYear,
                status:filter.status,
                pageNo:page.pageNo,
                pageSize:page.pageSize,
                sortBy:page.sortBy,
                sortDir:page.sortDir
            },
            headers:{
                Authorization:`bearer ${sessionStorage.getItem("access_token")}`
            }
        })
        .then((response) => {

            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.getMyTransactions(filter,page,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response && error.response.data && error.response.data.message) {
                        responseObject =  error.response.data.message;
                    } else {
                        responseObject =  error.message;
                    }
                }); 
            } 
        })

        return responseObject;
    }
}

export default new TransactionService();