import React, {useEffect,useState,useContext,useRef} from 'react'
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate } from 'react-router-dom';
import RoleService from '../services/RoleService';
import UserService from '../services/UserService';
import EditRole from './EditRole';
import { LiaMoneyBillWaveSolid ,LiaUserTieSolid,LiaUsersSolid,LiaBuilding,LiaClockSolid } from "react-icons/lia";
import { PiMoneyLight} from "react-icons/pi";
import { AiOutlineEdit } from "react-icons/ai";
import { SlLocationPin } from "react-icons/sl";
import { TbShield} from "react-icons/tb";
import ExpandableList from './ExpandableList';

const Role = ({id}) => {
    const {setReloadUser,setDialog,hasAuthority} = useContext(GlobalContext);
    const [user,setUser] = useState(null);
    const [role,setRole] = useState(null);
    const [imageSize,setImageSize] = useState({width:0,height:0});
    const roleRef = useRef(null);

    let USDecimal = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    useEffect(() => {
        ( async () => {
            await UserService.getCurrentUser()
            .then((response) => {
                if(response.content) {
                    setUser(response.content);
                }  else {
                    setUser(null);
                }
                setReloadUser({reload:true});
            })
            .catch((error) => {
                console.log(error.message);
                setUser(null);
                setReloadUser({reload:true});
            })
        }
        )();
        ( async () => {
            await RoleService.getRole(id,false)
            .then((response) => {
                if(response.content) {
                    setRole(response.content);
                }  else {
                    setRole(null);
                }
                setReloadUser({reload:true});
            })
            .catch((error) => {
                console.log(error.message);
                setRole(null);
                setReloadUser({reload:true});
            })
        }
        )();

        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                let width = entry.target.getBoundingClientRect().width;
                let height = (width * 9)/16;
                setImageSize({width:width,height:height});
            }
        });

        if(roleRef.current) {
            observer.observe(roleRef.current);
        }
        return () => {
            observer.disconnect();
        }
    },[])
  return (
    <div className='flex flex-col w-full h-auto overflow-hidden'>
        {role?
            <div className='flex flex-col w-full h-auto max-h-[80vh] py-8 items-center no-scrollbar overflow-x-hidden overflow-y-auto bg-white font-jostBook'>
                <div ref={roleRef} style={{boxShadow:'0 4px 20px 5px rgba(0, 0, 0, 0.05), 0 10px 20px 20px rgba(0, 0, 0, 0.03)'}} 
                    className='flex flex-col w-[90%] lg:w-3/4 h-auto bg-white rounded-md'>
                    <div className='flex flex-col w-full h-auto p-8'>
                        {role.displayName &&
                            <p className='flex w-full h-auto pt-4 text-2xl text-[rgb(59,59,59)] font-semibold font-leBeauneNew'>
                                {role.displayName}
                            </p>
                        }
                        {role.description &&
                            <p className='flex w-full text-lg text-[rgb(100,100,100)] font-jostMedium capitalize'>
                                 {role.description}
                            </p>
                        }
                        <div className='flex flex-col space-y-2 w-auto h-auto mt-8'>
                            {user && hasAuthority(user,'UPDATE_ROLE') && 
                            <button 
                                onClick={(e) => {
                                    e.preventDefault();
                                    setDialog({
                                        show:true,
                                        Component:() => 
                                            <EditRole id={id} setOriginalRole={(updatedRole)=> setRole(updatedRole)}/>
                                    })
                                }}
                                className='flex w-fit flex-row space-x-2 items-center text-sm shrink-0 text-[rgb(0,175,240)] font-jostSemi hover:underline'>
                                <AiOutlineEdit size={24}/>
                                <p>Edit</p>
                            </button>}
                        </div>
                    </div>
                    <div className='flex flex-col space-y-8 w-full h-auto px-8 py-16 shrink-0 bg-[rgb(243,244,245)]'>
                        <div className='flex flex-col w-full h-auto space-y-4'>
                            <p className='text-3xl text-[rgb(59,59,59)] font-semibold'>Authorities</p>
                            {role.authorities && role.authorities.map((authority,i) => 
                                <div key={i} className='flex flex-row w-full h-auto space-x-4'>
                                    <TbShield size={20} className='flex text-[rgb(85,85,85)] shrink-0'/>
                                    <p className='text-sm tracking-wider font-jostBook text-[rgb(85,85,85)] whitespace-nowrap overflow-ellipsis capitalize'>
                                        {authority}
                                    </p>
                                </div>)
                            }
                        </div>
                        <ExpandableList name='Tariffs'>
                            {role.tariffs && role.tariffs.map((tariff,i) => 
                                <div key={i} className={`flex flex-row w-full h-auto space-x-4 font-jostBook ${role.defaultTariff && role.defaultTariff.id == tariff.id?'text-[rgb(0,175,240)]':'text-[rgb(85,85,85)]'}`}>
                                    <LiaMoneyBillWaveSolid  size={20} className='flex m-auto shrink-0'/>
                                    <div className='flex flex-row w-full h-auto'>
                                        <p className='w-1/3 text-sm tracking-wider  whitespace-nowrap overflow-hidden overflow-ellipsis capitalize'>
                                            {tariff.name}
                                        </p>
                                        <p className='w-1/3 text-sm tracking-wider whitespace-nowrap overflow-hidden overflow-ellipsis capitalize'>
                                            {tariff.description}
                                        </p>
                                        <p className='w-1/3 text-sm text-right tracking-wider whitespace-nowrap overflow-hidden overflow-ellipsis capitalize'>
                                            {USDecimal.format(tariff.price)}
                                        </p>
                                    </div>
                                </div>)
                            }
                        </ExpandableList>
                    </div>
                </div>
            </div> :
            <div>
                
            </div>
        }
    </div>
  )
}

export default Role