import React from 'react'
import { FaRegCircleDot } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";

const DeliveringCare = () => {
  return (
    <div className='flex flex-col space-y-16 w-full h-auto'>
        <div className='flex flex-col w-full h-auto'>
            <p className='w-full h-auto text-lg text-[rgb(100,100,100)] font-jostBook'>
                COAZ is dedicated to promoting excellence in professional practice within the healthcare sector. We provide 
                resources, guidelines, and support to empower our member healthcare professionals to deliver high-quality 
                care to their patients. Our focus is enhancing a culture of continuous improvement and innovation in 
                healthcare delivery
            </p>
        </div>
        <div className='flex flex-col space-y-4 w-full h-auto'>
            <p className='flex w-full h-auto font-semibold font-leBeauneNew text-[22px] lg:text-4xl text-[rgb(59,59,59)]'>
              Guidelines and Resources
            </p>
            <div className='flex flex-row space-x-4'>
              <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>Standard treatment guidelines</p>
            </div>
            <div className='flex flex-row space-x-4'>
              <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>HIV treatment Guidelines</p>
            </div>
            <div className='flex flex-row space-x-4'>
              <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>Zambia national Formulary</p>
            </div>
            <div className='flex flex-row space-x-4'>
              <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>Any other useful guidelines</p>
            </div>
        </div>
        <div className='flex flex-col space-y-4 w-full h-auto'>
            <p className='flex w-full h-auto font-semibold font-leBeauneNew text-[22px] lg:text-4xl text-[rgb(59,59,59)]'>Health Equity in Zambia</p>
            <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
              The COAZ is committed to creating a culturally 
              aware and diverse Clinical Officers’ workforce. Learn more about the COAZ's efforts to drive diversity & inclusion with 
              the latest articles and studies
            </p>
            <p className='text-lg tracking-wider font-jostSemi text-[rgb(85,85,85)] uppercase'>Explore Topics</p>
            <div className='flex flex-row space-x-4'>
              <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>Health Insurance</p>
            </div>
            <div className='flex flex-row space-x-4'>
              <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>Health Care Equity</p>
            </div>
            <div className='flex flex-row space-x-4'>
              <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>Health Care Disparities</p>
            </div>
            <div className='flex flex-row space-x-4'>
              <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>Social Determinants of Health</p>
            </div>
            <div className='flex flex-row space-x-4'>
              <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>Cultural Humility in Health Care</p>
            </div>
            <div className='flex flex-row space-x-4'>
              <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>Commitment to End Health Care Disparities</p>
            </div>
            <div className='flex flex-row space-x-4'>
              <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px]  shrink-0'/>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>Clinical Professions Diversity</p>
            </div>
            <div className='flex flex-row space-x-4'>
              <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>COAZ Health Equity Task Force</p>
            </div>
            <div className='flex flex-row space-x-4'>
              <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>Decentralization and role of clinical officers</p>
            </div>
            <p className='text-lg tracking-wider font-jostSemi text-[rgb(85,85,85)]'>Health Insurance</p>
            <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                Zambia’s current state of the National health insurance access presents various challenges, particularly 
                for those in underserved areas. Many people in these communities either lack access to health insurance 
                entirely or face significant barriers to enrollment due to factors such as poverty, lack of awareness, 
                and limited availability of insurance services
            </p>
            <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                Under the theme “Improving National Health Insurance Access for Underserved Communities”, COAZ As a 
                professional association committed to promoting health equity and advocating for improved healthcare access 
                for all Zambians, our organization is actively engaged in advocating for the following:
            </p>
            <div className='flex flex-row space-x-4'>
              <p className='font-jostSemi text-[rgb(85,85,85)] text-lg'>1.</p>
              <div className='flex flex-col '>
                <p className='text-lg tracking-wider font-jostSemi text-[rgb(85,85,85)]'>Expansion of NHIMA Coverage:</p>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    We advocating for the expansion of the National Health Insurance scheme to ensure coverage for 
                    individuals in underserved communities. This includes advocating for policies that prioritize the 
                    inclusion of rural and remote populations, as well as vulnerable groups such as low-income earners and 
                    informal sector workers
                </p>
              </div>
            </div>
            <div className='flex flex-row space-x-4'>
              <p className='font-jostSemi text-[rgb(85,85,85)] text-lg'>2.</p>
              <div className='flex flex-col '>
                <p className='text-lg tracking-wider font-jostSemi text-[rgb(85,85,85)]'>Affordability and Accessibility:</p>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    We advocating for measures to make NHIMA premiums affordable for individuals in underserved communities, 
                    such as subsidies or sliding-scale fee structures based on income levels. Additionally, we support efforts 
                    to improve the accessibility of insurance enrollment through community-based outreach programs and simplified 
                    registration processes.
                </p>
              </div>
            </div>
            <div className='flex flex-row space-x-4'>
              <p className='font-jostSemi text-[rgb(85,85,85)] text-lg'>3.</p>
              <div className='flex flex-col '>
                <p className='text-lg tracking-wider font-jostSemi text-[rgb(85,85,85)]'>Quality Healthcare Services:</p>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    In conjunction with advocating for increased health insurance access, we also emphasizing the importance 
                    of ensuring the availability of quality healthcare services in underserved areas. This includes advocating 
                    for investments in healthcare infrastructure, the recruitment and retention of Clinical Officer professionals, 
                    and the provision of essential medical supplies and equipment.
                </p>
              </div>
            </div>
            <div className='flex flex-row space-x-4'>
              <p className='font-jostSemi text-[rgb(85,85,85)] text-lg'>4.</p>
              <div className='flex flex-col '>
                <p className='text-lg tracking-wider font-jostSemi text-[rgb(85,85,85)]'>Health Education and Awareness:</p>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    We constantly advocating for initiatives aimed at raising awareness about the importance of health 
                    insurance and promoting health literacy in underserved communities. By empowering individuals with 
                    knowledge about their rights and options regarding health insurance, we are aiming to increase uptake 
                    and utilization of NHIMA services.
                </p>
              </div>
            </div>
            <div className='flex flex-row space-x-4'>
              <p className='font-jostSemi text-[rgb(85,85,85)] text-lg'>5.</p>
              <div className='flex flex-col '>
                <p className='text-lg tracking-wider font-jostSemi text-[rgb(85,85,85)]'>Community Engagement and Participation:</p>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    We are advocating for the involvement of local communities in the design, implementation, and monitoring 
                    of NHI programs. Community engagement ensures that NHI initiatives are tailored to the specific needs and 
                    priorities of underserved populations, thereby enhancing their effectiveness and sustainability.
                </p>
              </div>
            </div>
            <p className='text-lg tracking-wider font-jostSemi text-[rgb(85,85,85)]'>Health Care Equity</p>
            <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                Under the theme  “health equity as quality”. This advocacy through the executive seeks to accelerate this 
                growing revolution in equitable care delivery by broadening education, spreading and scaling the 
                implementation of this framework over time and continuing to influence and shape national policy and best 
                practices.
            </p>
            <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                The COAZ member Network for advancing equity through quality and safety, leverages the rigor and success in 
                quality, safety and harm reduction by:
            </p>
            <div className='flex flex-row space-x-4'>
              <p className='font-jostSemi text-[rgb(85,85,85)] text-lg'>a.</p>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                  Systematically revealing and measuring the omnipresent and toxic effects of human resource for health 
                  structures and other inequities on the health and well-being of patients, families, health care workers 
                  and communities.
              </p>
            </div>
            <div className='flex flex-row space-x-4'>
              <p className='font-jostSemi text-[rgb(85,85,85)] text-lg'>b.</p>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                  Highlighting the critical role of Clinical Professions led - health care organizations in preventing 
                  inequities.
              </p>
            </div>
            <div className='flex flex-row space-x-4'>
              <p className='font-jostSemi text-[rgb(85,85,85)] text-lg'>c.</p>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                  Incorporating equity into the operational heart of healthcare delivery and innovation.
              </p>
            </div>
            <div className='flex flex-row space-x-4'>
              <p className='font-jostSemi text-[rgb(85,85,85)] text-lg'>d.</p>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                  Promoting high-quality, safe and equitable outcomes for every patient, family and community served.
              </p>
            </div>
            <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                This Professional Peer Network is a key new program within the pillars of the COAZ strategic plan, which 
                seeks to effectively integrate clinical and social care as well as medicine and public health under 
                decentralized health care at primary and secondary levels through:
            </p>
            <div className='flex flex-row space-x-4'>
              <p className='font-jostSemi text-[rgb(85,85,85)] text-lg'>a.</p>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                  Strengthening Clinical Officers’ understanding of public health and structural/social drivers of health 
                  and inequities.
              </p>
            </div>
            <div className='flex flex-row space-x-4'>
              <p className='font-jostSemi text-[rgb(85,85,85)] text-lg'>b.</p>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                  Empowering Clinical officers and health systems to dismantle structural  professional rigidities and 
                  intersecting systems of oppression.
              </p>
            </div>
            <div className='flex flex-row space-x-4'>
              <p className='font-jostSemi text-[rgb(85,85,85)] text-lg'>c.</p>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                  Equipping Clinicians and health systems to improve services, technology, partnerships and payment models 
                  that advance public health and health equity.
              </p>
            </div>
            <div className='flex flex-row space-x-4'>
              <p className='font-jostSemi text-[rgb(85,85,85)] text-lg'>d.</p>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                  This program seeks apply this Quality, Safety, Equity and community participation, tapping high-reliability 
                  tools, to reduce inequities and drive systems-level change.
              </p>
            </div>
            <p className='text-lg tracking-wider font-jostSemi text-[rgb(85,85,85)]'>Health Care Disparities</p>
            <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                Our mission for health equity in Zambia starts with recognizing disparities in healthcare access, especially 
                for underserved communities. We focus on areas like geography, income, ethnicity, and gender to tailor our 
                efforts effectively. We work closely with community, civic leaders and healthcare providers to spread 
                awareness about the importance of health insurance, and health service distribution particularly in regions 
                with limited coverage.
            </p>
            <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                We advocate for policy changes to expand National Health Insurance (NHI) coverage to essential services and 
                remove barriers to enrollment. Our aim is to address underlying issues like poverty, education, and housing 
                that contribute to health disparities.
            </p>
            <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                We track progress through data collection and collaborate with government agencies, NGOs, and healthcare 
                providers to amplify our impact. Together, we strive for a future where every Zambian has equal access to 
                quality healthcare and health insurance.
            </p>
            <p className='text-lg tracking-wider font-jostSemi text-[rgb(85,85,85)]'>Cultural Humility in Healthcare</p>
            <div className='flex flex-row space-x-4'>
              <GoDotFill size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                  Addresses health disparities and inequities by understanding specific healthcare needs of diverse populations.
              </p>
            </div>
            <div className='flex flex-row space-x-4'>
              <GoDotFill size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                  COAZ collaborates with community organizations and leaders.
              </p>
            </div>
            <div className='flex flex-row space-x-4'>
              <GoDotFill size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                  Promotes policies that prioritize workforce diversity.
              </p>
            </div>
            <div className='flex flex-row space-x-4'>
              <GoDotFill size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
              <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                  Providing resources and workshops on cultural competence, COAZ equips healthcare providers with the skills 
                  needed to communicate effectively and empathize with patients from various cultural backgrounds.
              </p>
            </div>
        </div>
    </div>
  )
}

export default DeliveringCare