import axios from "axios";

class CPDService {

    async updateCPD(cpd,refresh){
        let responseObject = {};
        await axios.put("cpd", cpd,{headers:{Authorization:`bearer ${sessionStorage.getItem("access_token")}`}})
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data) {
                responseObject =  error.response.data;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.updateCPD(cpd,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response && error.response.data && error.response.data.message) {
                        responseObject =  error.response.data.message;
                    } else {
                        responseObject =  error.message;
                    }
                }); 
            }
        })
        return responseObject;
    }

    async getCPD (id) {
        let responseObject = null;
        await axios.get(`cpd/${id}`)
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data) {
                responseObject =  error.response.data;
            } else {
                responseObject =  error.message;
            }
        })

        return responseObject;
    }

    async getCPDs (refresh) {
        let responseObject = null;
        await axios.get("cpds")
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data) {
                responseObject =  error.response.data;
            } else {
                responseObject =  error.message;
            }
        })

        return responseObject;
    }

    async getEnrolledCPDs (id,refresh) {
        let responseObject = null;
        await axios.get(`moodle/courses/${id}`)
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data) {
                responseObject =  error.response.data;
            } else {
                responseObject =  error.message;
            }
        })

        return responseObject;
    }

    async enrollUser(userId,cpdId,cpdRoleId,refresh){
        let responseObject = {};
        await axios.post("cpd/enrolluser", null,
            {params:{
                userId:userId,
                cpdId:cpdId,
                cpdRoleId:cpdRoleId
            },
            headers:{
                Authorization:`bearer ${sessionStorage.getItem("access_token")}`
            }
        })
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data) {
                responseObject =  error.response.data;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.enrollUser(userId,cpdId,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response && error.response.data && error.response.data.message) {
                        responseObject =  error.response.data.message;
                    } else {
                        responseObject =  error.message;
                    }
                }); 
            }
        })
        return responseObject;
    }
}

export default new CPDService();