import React, {useContext} from 'react'
import { GlobalContext } from '../contexts/GlobalContext';
import {Outlet} from 'react-router-dom';


const Content = () => {
  const {selMenuItem} = useContext(GlobalContext);

  return (
    <div className='relative flex w-full h-auto'>
        <Outlet/>
    </div>
  )
}

export default Content