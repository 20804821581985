import React, {useState, useEffect, useContext} from 'react';
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate } from 'react-router-dom';
import UserService from '../services/UserService';
import { ImUser } from "react-icons/im";
import YesNoDialog from './YesNoDialog';
import Message from './Message';

const Login = () => {
    const {setReloadUser,setDialog,setLoading,hasAuthority} = useContext(GlobalContext);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState(""); 
    const [message,setMessage] = useState({content:'',success:false}); 
    const navigate = useNavigate();

    const login = async (e) => {
        setLoading(true);
        e.preventDefault();
        setMessage({content:'',success:false});
        UserService.login(username,password)
        .then((loginResponse) => {
            setLoading(false);
            if(loginResponse.message && loginResponse.message.toLowerCase().includes('successfull')) {
                ( async () => {
                    await UserService.getCurrentUser(false)
                    .then(async (userResponse) => {
                        if(userResponse.status && userResponse.status === 'SUCCESSFUL' && userResponse.content) {
                            await UserService.checkUser(userResponse.content)
                            .then((urlResponse) => {
                                if(urlResponse && urlResponse.path) {
                                    if(urlResponse.path === 'error') {
                                        setMessage({content:'Unknown error',success:false});
                                    } else if(urlResponse.params) {
                                        navigate(urlResponse.path,{state:{params:urlResponse.params}});
                                    } else {
                                        navigate(urlResponse.path);
                                    }
                                } else if(urlResponse.path === '') {
                                    UserService.createMoodleUser(userResponse.content.id);
                                    navigate('/home');
                                }
                            })
                            .catch((error) => {
                                setMessage({content:error.message,success:false});
                            })
                        }  else {
                            console.log(userResponse);
                            setMessage({content:userResponse.message?userResponse.message:userResponse,success:false});
                        }
                    })
                    .catch((error) => {
                        console.log(error.message);
                        setMessage({content:error.message,success:false});
                    })
                }
                )(); 
            } else if(loginResponse.error_message) {
                setMessage({content:loginResponse.error_message,success:false});
            } else {
                setMessage({content:loginResponse,success:false});
            }
        })
        .catch((error) => {
            setMessage({content:error.message,success:false});
            setLoading(false);
        })
        setReloadUser({reload:true});
    };

    const onForgotPassword = async (e) => {
        setLoading(true);
        setMessage({content:'',success:false});
        UserService.forgotPassword(username)
        .then((response) => {
            if(response.status) {
                if(response.status === 'SUCCESSFUL') {
                    setMessage({content:response.message,success:true});
                } else {
                    setMessage({content:response.message,success:false});
                }
            } else  {
                setMessage({content:response,success:false});
            }
            setLoading(false);
        })
        .catch((error) => {
            setMessage({content:error.message,success:false});
            setLoading(false);
        });
    };

  return (
    <div className='flex flex-col w-[315px] h-full justify-center m-auto shrink-0 rounded-r-xl overflow-hidden'>
        <ImUser size={64} className='flex mx-auto mb-4 text-[rgba(84,84,87,.7)]'/>
        <input 
            type="text" 
            name="username"
            value={username}
            onChange={e => setUsername(e.target.value)}
            placeholder="User name..."
            className='flex w-full h-10 shrink-0 p-2 mb-4 focus:outline-none font-thin text-sm whitespace-nowrap bg-transparent border rounded-lg'
        />
        <input 
            type="password" 
            name="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder='Password...'
            className='flex w-full h-10 shrink-0 p-2 focus:outline-none font-thin text-sm whitespace-nowrap bg-transparent border rounded-lg'
        />
        <Message message={message}/>
        <button 
            onClick={(e) => {
                setDialog({
                    show:true,
                    Component:() => 
                        <YesNoDialog 
                            title='Forgot password' 
                            message='By clicking here your password will be reset and an OTP will be sent to your email. Are you sure your want to proceed?'
                            onYes={async (e) => {
                                await onForgotPassword(username);
                            }}
                        />
                })
            }}
            className='flex shrink-0 w-full h-6 mb-4 items-center justify-center text-sm font-thin italic text-gray-600 hover:underline'>
            Forgot Password?
        </button>
        <button onClick={(e) => login(e)} className='flex shrink-0 w-full h-10 rounded-lg items-center justify-center bg-[rgb(0,175,240)] hover:bg-[rgba(0,175,240,.7)] text-white'>
            Login
        </button>
    </div>
  )
}

export default Login