import React,{useContext} from 'react'
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate } from 'react-router-dom';
import UserService from '../services/UserService'
import Table from './Table';
import User from './User';
import YesNoDialog from './YesNoDialog';
import AddUser from './AddUser';
import AddUsers from './AddUsers';
import { TbUserPlus,TbUsersPlus,TbUser,TbUserX,TbPasswordUser} from "react-icons/tb";

const Users = () => {
    const {setDialog,hasAuthority,setLoading,setReloadUser} = useContext(GlobalContext);
    const navigate = useNavigate();
    const columns = [
        {
            name:'username',
            label:'Username',
            type:'text',
            filter:''
        },
        {
            name:'firstname',
            label:'Firstname',
            type:'text',
            filter: ''
        },
        {
            name:'lastname',
            label:'Lastname',
            type:'text',
            Filter: ''
        },
        {
            name:'email',
            label:'Email',
            type:'text',
            filter:''
        },
        {
            name:'role',
            label:'Role',
            type:'text',
            filter:''
        },
        {
            name:'status',
            label:'Status',
            type:'text',
            filter:''
        }
    ]

    const getUsers = async (filter,page) => {
        let object = {}
        let current = null;
        await UserService.getCurrentUser()
        .then((response) => {
            if(response.content) {
                current = response.content;
            } 
        })
        .catch((error) => {
            console.log(error.message);
        });

        if(current && hasAuthority(current,'READ_USER')) {
            await UserService.getUsers(filter,page,false)
            .then((response) => {
                if(response.content) {
                    for(let user of response.content) {
                        user.role = user.role? user.role.displayName:'';
                    }
                    object = {
                        status:'SUCCESSFUL',
                        content:response.content,
                        pageNo:response.pageNo,
                        pageSize:response.pageSize,
                        totalElements:response.totalElements,
                        totalPages:response.totalPages,
                        last:response.last
                    }
                } else {
                    object = {
                        status:'FAILED',
                        content:[]
                    };
                }
            })
            .catch((error) => {
                console.log(error.message);
                object = {
                    status:'ERROR',
                    content:[]
                };
            });
        } else if(current) {
            current.role = current.role.name
            object = {
                status:'SUCCESSFUL',
                content:[current]
            }
        } else {
            object = {
                        status:'FAILED',
                        content:[]
                    };
        }
        return object;
    }

    const handleDelete = async (id,setMessage,reload) => {
        setLoading(true);
        setMessage({content:'',success:false});
        await UserService.deleteUser(id,false)
        .then((response) => {
            setLoading(false);
            if(response.status) {
                if(response.status && response.status === 'SUCCESSFUL' && response.message) {
                    setMessage({content:response.message,success:true});
                    reload();
                }else if(response.message) {
                    setMessage({content:response.message,success:false});
                }
            } else {
                setMessage({content:response,success:false});
            }
        })
        .catch((error) => {
            setLoading(false);
            setMessage({content:error.message,success:false});
        })
        setReloadUser({reload:true});
    }

    const handleResetPassword = async (username,setMessage) => {
        setMessage({content:'',success:false});
        await UserService.resetPassword(username,false)
        .then((response) => {
            if(response.status) {
                if(response.status === 'SUCCESSFUL' && response.message) {
                    setMessage({content:response.message,success:true});
                } else if(response.status === '401') {
                    navigate('/home');
                }else if(response.message) {
                    setMessage({content:response.message,success:false});
                }
            } else {
                setMessage({content:response,success:false});
            }
        })
        .catch((error) => {
            setMessage({content:error.message,success:false});
        })
        setReloadUser({reload:true});
    }

    const getButtons = (user,reload,setMessage) => {
        let buttons = [];

        buttons.push({
            name:'Open User',
            onClick:(e,selected) => {
                if(selected) {
                    navigate(`/settings/users/${selected.id}`)
                }
            },
            disabled:(selected) => {
                return selected?false:true;
            },
            Icon:TbUser
        });

        if(hasAuthority(user,'CREATE_USER')) {
            buttons.push(
            {
                name:'Add User',
                disabled:(selected) => {
                    return false;
                },
                onClick:(e) => setDialog({
                    show:true,
                    Component:() => <AddUser reload={reload}/>
                }),
                Icon:TbUserPlus
             });

            buttons.push(
            {
                name:'Add Users',
                disabled:(selected) => {
                    return false;
                },
                onClick:(e) => setDialog({
                    show:true,
                    Component:() => <AddUsers/>
                }),
                Icon:TbUsersPlus
            });

            buttons.push({
                name:'Create CPD Account',
                onClick:(e,selected) => {
                    if(selected) {
                        setDialog({
                            show:true,
                            Component:() => 
                                <YesNoDialog 
                                    title='Create CPD Account' 
                                    message={`Are you sure you want to create a CPD account for user '${selected.firstname+' '+selected.lastname}'?`} 
                                    onYes={async (e) => {
                                        setLoading(true);
                                        await UserService.createMoodleUser(selected.id,false)
                                        .then((response) => {
                                            setLoading(false);
                                            if(response.content){
                                                setMessage({content:response.message,success:true})
                                            } else if(response.message) {
                                                setMessage({content:response.message,success:false})
                                            } else {
                                                setMessage({content:response,success:false})
                                            }
                                        })
                                        .catch((error) => {
                                            setLoading(false);
                                            setMessage({content:error.message,success:false})
                                        })
                                    }}
                                />
                        })
                    }
                },
                disabled:(selected) => {
                    return !selected?true:selected && selected.moodleId?true:false;
                },
                Icon:TbUserPlus
            });
        }
    
        if(hasAuthority(user,'DELETE_USER')) {
            buttons.push({
                name:'Delete User',
                onClick:(e,selected) => {
                    if(selected) {
                        setDialog({
                            show:true,
                            Component:() => 
                                <YesNoDialog 
                                    title='Delete User' 
                                    message={`Are you sure you want to permanently delete user '${selected.firstname+' '+selected.lastname}' from the database?`} 
                                    onYes={async (e) => {
                                        await handleDelete(selected.id,setMessage,reload);
                                    }}
                                />
                        })
                    }
                }, 
                disabled:(selected) => {
                    return selected?false:true;
                },
                Icon:TbUserX
            });
        }
        if(hasAuthority(user,'RESET_PASSWORD')) {
            buttons.push({
                name:'Reset Password', 
                onClick:(e,selected) => {
                    if(selected) {
                        setDialog({
                            show:true,
                            Component:() => 
                                <YesNoDialog 
                                    title='Reset Password' 
                                    message={`Are you sure you want to reset the password for '${selected.firstname+' '+selected.lastname}'?`} 
                                    onYes={async (e) => {
                                        await handleResetPassword(selected.username);
                                    }}
                                />
                        })
                    }
                },
                disabled:(selected) => {
                    return selected?false:true;
                },
                Icon:TbPasswordUser
            });
        }
        return buttons;
    }

  return (
    <div className='relative flex w-full h-auto'>
        <Table columns={columns} retrieveRecords={getUsers} getButtons={getButtons}/>
    </div>
  )
}

export default Users