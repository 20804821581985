import { FaRegCircleDot } from "react-icons/fa6";
import { TbSettings2 } from "react-icons/tb";
import {CIUserSettings,CIRole,CIAccount,CITransaction} from "./components/CoazIcons";
import { BsCalendar4Event } from "react-icons/bs";
import Users from "./components/Users";
import User from "./components/User";
import Roles from "./components/Roles";
import Role from "./components/Role";
import Accounts from "./components/Accounts";
import Account from "./components/Account";
import Transactions from "./components/Transactions";
import CPDs from "./components/CPDs";
import CPD from "./components/CPD";
import Events from "./components/Events";
import Event from "./components/Event";
import AwardsAndCertificates from "./components/AwardsAndCertificates";
import Partners from "./components/Partners";
import Careers from "./components/Careers";

export const people = [
    {
        name:'Jones Neba',
        gendar:'male',
        position:'Hon President',
        tenure:'2022 - 2026',
        about:[
            'Jones Neba is a Fully licensed clinical officer General with a Bachelor of Science in Clinical Medicine, a Licensed Medical Licentiate Practitioner (MLP). He has served as a Lecturer in various institutions,  a consultant in programme development, a professional examiner with various examination bodies and has served as an external examiner in various universities and colleges. Mr. Neba has experience of over 10 years in leadership, corporate governance and health service delivery. He is currently working as HOD for Clinical Medicine at a University and also providing consultancy works in Higher education. He has taught over 3000 clinical officers in Zambia',
            'He has contributed and guided drafting of various policy documents, from COAZ to health professions. Further, Neba is a trainer of trainers in HIV and AIDS management'
        ],
        qualifications:[
            'Master of Science in Clinical Pathology',
            'Bachelor Science of Human biology',
            'Bachelor of science in Clinical Medicine',
            'Diploma in clincal medicine',
            'Certificate in HIV AIDS',
            'Certificate in Corporate governance',
            'Certificate from Institute of Directors in governance'
        ],
        contributions:[
            "Mr. Neba has been instrumental in the growth of COAZ where he has provided overall leadership for the organization, seeing it grow from Less than 30 members to over 8000 in the space of 2 years. He has been instrumental in guiding organization's overall policy direcion and is charged as the organization's Chairman",
            'Neba previously served as vice president for the association',
            'Neba is a board Member of the Health Professions council of Zambia',
            'He is a member of HPCZ training committee of the board'
        ],
        email:'jones.neba1986@gmail.com',
        image:'/images/neba.jpg'
    },
    {
        name:'Musonda Kamfwa',
        gendar:'male',
        position:'Hon Secretary General',
        tenure:'2022 - 2026',
        about:[
            "Mr. Kamfwa is a Fully Licensed Clinical Officer General with a Diploma In Clinical Medicine.  Hes additionally "+
            "a licensed public health Specialist and scientist. With over 5 years Experience in Health service management, program"+
            " management and consultancy. As a freelance consultant, he's provided consultancy work with various organizations "+
            "from HIV/AIDs programming to planning and implementation of Health systems strengthening programs, policy development"+
            " and analysis to training institution learning programme development",
            "Professionally, Mr. Kamfwa has served as Manager for HIV/TB/cervical cancer, prevention programs in 3 "+
            "provinces in Zambia in various districts, where he led Technical assistance teams and supervised District "+
            "technical teams under care and treatment PEPFAR funded projects. Additionally, he has serviced as a "+
            "Consultant for reputable institutions such as LIAS University,  World Bank, the Ministry of Health, and "+
            "others. outside the association, Mr kamfwa serves on the committee of the HPCZ board - Registrations committee",
            "Mr. Kamfwa has participated in drafting of the following ;",
            "- National Health policy", 
            "- National Health strategic plan for the ministry of health",
            "- health professions bill",
            "- Higher education amendment bill", 
            "- participated in committee review of legislation at parliament", 
            "- drafting of National Health research authority training guidelines",
            "- BSc and Diploma clinical Medicine training standards with Higher education Authority", 
            "- and more"
        ],
        qualifications:[
            'He is a Public Health specialist and clinical officer by profession',
            'Master of Public Health',
            'Master of Public Administration',
            'Bachelor of medical science',
            'Bachelor of science in public health',
            'Diploma in clinical medicine',
            'Postgraduate diploma in project management',
            'Certificate in Project management',
            'Certificate in pediatrics and adult HIV management',
            'Certificate in monitoring and evaluation in Global Health',
            'He is a Ph.D candidate in Public Health (health policy)'
        ],
        contributions:[
            "Mr. Kamfwa has been at the centre of enrolling members into the organization,  moving the organization membership from less than 30 to over 8000",
            'He has been involved in the drafting of the organizations policies and all administrative processes, setting a foundation for the association',
            'Led Drafting of RoadMap for Clinical Officer workforce in the ministry of health',
            'Led drafting of COAZ strategic plan',
            'Led the drafting of COA Training and CPD policy',
            'Founder of the COAZ page'
        ],
        publications:'https://www.google.com/url?sa=t&source=web&rct=j&opi=89978449&url=https://socialinnovationsjournal.com/index.php/sij/article/download/991/740/3781%23:~:text%3DA%2520clinical%2520officer%2520in%2520Zambia%2520performs%2520general%2520and%2520specialized%2520medical,managing%2520health%2520departments%252C%2520institutions%252C%2520projects&ved=2ahUKEwiZz5vow8eHAxXWwAIHHU5gDOQQFnoECBMQBQ&usg=AOvVaw1hkgd10Mt0Dv2jtF9TTYmm',
        email:'joseph.kamfwa@yahoo.com',
        image:'/images/kamfwa.jpg'
    },
    {
        name:'Jonathan Tapalu',
        gendar:'male',
        position:'Hon National Publicity Secretary',
        tenure:'2022 - 2026',
        about:[
            'Jonathan Tapalu is a Fully licensed clinical officer General with a Bachelor of Science in Clinical Medicine, a Licensed Medical Licentiate Practitioner (MLP). He has been practicing for more than 10years, and has dip. Healthy Public emergency operating systems.  He is currently working attached with the Ministry of Health HQ and overseeing medical emergencies as Chief Emergency Officer',
            'He has contributed in drafting of various policy documents, at the Ministry of Health and COAZ. Further, Mr. Tapalu is a trainer of trainers in BLS/ALS management'
        ],
        qualifications:[
            'Bachelor of science in Clinical Medicine',
            'Diploma in clincal medicine',
            'Certificate in public Health Emergency operations systems',
            'Certificate in HIV AIDS',
            'Certificate in leadership and Management'
        ],
        contributions:[
            'Mr. Tapalu has been instrumental as he was the one that came up with a modified logo of COAZ',
            'Mr. Tapalu previously served as a committee member'
        ],
        email:'jtapalu@gmail.com',
        image:'/images/tapalu.jpg'
    },
    {
        name:'Emmanuel Kashimu',
        gendar:'male',
        position:'Hon Deputy National Publicity',
        tenure:'2022 - 2026',
        about:[
            'Mr. Kashimu Emmanuel is a clinical officer - General, fully Licensed by the health professions Council of Zambia. He is currently working from Sinda District in the Eastern province. He is substantively employed as a Senior Clinical Officer - HIV/TB/STI/Leprosy for Sinda under the ministry of health',
            'Mr. Kashimu Emmanuel has been a member of COAZ for over 5 yrs and is additionally serving as Acting Eastern provincial Chairperson for COAZ Eastern',
            'Mr. Kashimu Emmanuel has served as Health Centre In-charge for 2 different Health Centres and Mini Hospital both Administratively and on Promotional Basis'
        ],
        qualifications:[
            'Diploma in Clinical Medical Sciences',
            'Certificate in Project Management in Global Health',
            'Certificate Clinical Management of HIV in Global Health',
            'Certificate in Leadership and Management in Global Health',
            'Certificate in Monitoring and Evaluation in Global Health'
        ],
        contributions:[
            'He has contributed to the Association by spearheading social media publications under the guidance of the office the national publicity secretary, with support from the secretary General'
        ],
        image:'/images/kashimu.jpg'
    },
    {
        name:'Martha Mwiya',
        gendar:'female',
        position:'Hon National Treasurer',
        tenure:'2022 - 2026',
        about:[
            'Martha is a Licensed Clinical officer General working at Ndola Teaching hospital, the largest teaching hospital on the copperbelt province'
        ],
        qualifications:[
            'Diploma in clinical Medicine',
            'Certificate of competence in financial management'
        ],
        contributions:[
            'Has been a member of the association for over 7 years.',
            'One of the earliest members of the association',
            "She's the National Treasurer for the association"
        ],
        image:'/images/mwiya.jpg'
    },
    {
        name:'Peter Mwanaumbi',
        gendar:'male',
        position:'Hon Deputy Secretary General',
        tenure:'2022 - 2026',
        about:[
            'Mr. Mwanaumbi is a Licensed Clinical officer General working at Ndola Teaching hospital, the largest teaching hospital on the copperbelt province. He is the Deputy Secretary General for the association'
        ],
        qualifications:[
            'Diploma in clinical Medicine'
        ],
        contributions:[
            'Has been a member of the association for over 7 years.',
            'One of the earliest members of the association'
        ]
    }
]

export const findPerson = (position) => {
    return people.find((person) => {return person.position.toLowerCase().includes(position.toLowerCase())})
}

export const association = {
    name:'The Association',
    link:'/association',
    menus:[
        {
          image:'/images/img_26.jpg',
          name:'Delivering Care',
          link:'/association/delivering_care',
          content:'COAZ is dedicated to promoting excellence in professional practice within the healthcare sector. We provide'+ 
                    ' resources, guidelines, and support to empower our member healthcare professionals to deliver high-quality'+ 
                    ' care to their patients. Our focus is enhancing a culture of continuous improvement and innovation in healthcare delivery'
        },
        {
          image:'/images/img_17.jpg',
          name:'Professional Practice',
          link:'/association/professional_practice',
          content:'Patients in Primary Health care facilities deserve care led by Clinical practioners—the most highly educated, '+
                'trained and skilled health care professionals at such levels. Through research, advocacy and education, the COAZ'+
                ' vigorously defends the Clinical practice against scope of practice expansions that threaten patient safety'
    
        },
        {
          image:'/images/img_10.jpg',
          name:'Training Institutions and Students',
          link:'/association/training_institutions_and_students',
          content:'At the state level, the COAZ shapes the policy of influential national policymaking, partners with outside '+
                'experts and stakeholder groups and influences the enactment and/or defeat of state legislation and regulation'
        },
        {
          image:'/images/img_8.jpg',
          name:'Advocacy in Health Care',
          link:'/association/advocacy_in_health_care',
          content:'At the state level, the COAZ shapes the policy of influential national policymaking, partners with outside '+
                'experts and stakeholder groups and influences the enactment and/or defeat of state legislation and regulation'
        },
        {
          image:'/images/img_5.jpg',
          name:'Member Benefits',
          link:'/association/member_benefits',
          content:'Joining the nation’s largest Health professional Organization in Zambia has considerable benefits. In addition '+
                'to opportunities to shape the future of health care, COAZ membership benefits include exclusive access to savings'+
                ' and resources designed to enhance the personal and professional lives of Members'
        }
    ]
}

export const services = {
    name:'Services',
    link:'/services',
    menus:[
        {
            name:'CPD Platform',
            link:'https://learning.coaz.org'
        },
        {
            image:'/images/img_9.jpg',
            name:'Health Insurance',
            link:'/services/healthinsurance'
        },
        {
            image:'/images/bg_8.jpg',
            name:'Awards and Certificates',
            link:'/services/awards_and_certificates',
            Component: AwardsAndCertificates
        },
        {
            image:'/images/img_28.jpg',
            name:'Resource Banking',
            link:'/services/resource_banking'
        },
        {
            image:'/images/img_6.jpg',
            name:'Research and Journal Publication',
            link:'/services/research_and_journal_publication'
        },
        {
            image:'/images/img_32.jpg',
            name:'Partners',
            link:'/services/partners',
            Component: Partners
        },
        {image:'/images/img_47.jpg',
            name:'Careers',
            link:'/services/careers',
            Component: Careers
        }
    ]
}

export const categories = [
    {
        name:'Clinical Officers',
        professionalCatories:[
            {
                name:'General',
                link:'/categories/general',
                qualification:'Diploma in Clinical Medicine/medical Sciences',
                zambiaQualificationAuthority:'Level 6',
                minimumCreditRequirement:'280 credits or 2800 notional Hours',
                areaOfPractice:'General Practitioner in medicine, maternal and child health, community medicine especially at Primary health care level and secondary health facilities such as Health posts, health centres and first level hospitals such as district hospitals. May practice at general hospital and tertiary if needed',
                limitationsInDrugPrescription:'Non',
                limitationInStatutoryAndLegalDocumentSignatory:'yes, cannot sign certain documents unless under consultation with a medical doctor or as prescribed',
                administration:'yes, allowed legally to supervise Class C facilities such as health posts, health centers and can run these facilities independently',
                scope:''
            },
            {
                name:'Psychiatry',
                link:'/categories/psychiatry',
                qualification:'Diploma in Clinical Medicine - Psychiatry',
                zambiaQualificationAuthority:'Level 6',
                minimumCreditRequirement:'280 credits or 2800 notional Hours',
                areaOfPractice:'General Practitioner in medicine and psychiatry, maternal and child health, community medicine especially at Primary health care level and secondary health facilities such as Health posts, health centres and first level hospitals such as district hospitals. May practice at general hospital and tertiary if needed',
                limitationsInDrugPrescription:'Non, may be limited by procedures',
                limitationInStatutoryAndLegalDocumentSignatory:'Yes, cannot sign certain documents unless under consultation with a medical doctor or as prescribed',
                administration:'Yes, allowed legally to supervise Class C facilities such as health posts, health centers and can run these facilities independently',
                scope:''
            },
            {
                name:'HIV/TB',
                link:'/categories/hiv_tb',
                qualification:'',
                zambiaQualificationAuthority:'',
                minimumCreditRequirement:'',
                areaOfPractice:'',
                limitationsInDrugPrescription:'',
                limitationInStatutoryAndLegalDocumentSignatory:'',
                administration:'',
                scope:''
            },
            {
                name:'Advanced',
                link:'/categories/advanced',
                qualification:'Diploma in Clinical Medicine + Advanced diploma in various areas',
                zambiaQualificationAuthority:'Level 6',
                minimumCreditRequirement:'280 credits or 2800 notional Hours',
                areaOfPractice:'General Practitioner in medicine, maternal and child health, community medicine especially at Primary health care level and secondary health facilities such as Health posts, health centres and first level hospitals such as district hospitals. May practice at general hospital and tertiary if needed',
                limitationsInDrugPrescription:'Non',
                limitationInStatutoryAndLegalDocumentSignatory:'Yes, cannot sign certain documents unless under consultation with a medical doctor or as prescribed',
                administration:'Yes, allowed legally to supervise Class C facilities such as health posts, health centers and can run these facilities independently',
                scope:''
            }
        ]
    },
    {   
        name:'Licentiate',
        professionalCatories:[
            {
                name:'Medical Licentiate Officer/ Practitioner',
                link:'/categories/medical_licentiate_officer_practitioner',
                qualification:'Bachelor of Science in Clinical Medicine',
                zambiaQualificationAuthority:'Level 7',
                minimumCreditRequirement:'480 credits or 4800 notional Hours',
                areaOfPractice:'General Practitioner in medicine, maternal and child health, surgeries including cesarean  sections, community medicine especially at Primary health care level and secondary health facilities such as Health posts, health centres and first level hospitals such as district and general hospitals. May practice at tertiary if needed',
                limitationsInDrugPrescription:'Non',
                limitationInStatutoryAndLegalDocumentSignatory:'Yes, cannot sign certain documents unless under consultation with a medical doctor or as prescribed',
                administration:'Yes, allowed legally to supervise Class C facilities such as health posts, health centers and can run these facilities independently. Supervise clinical officers and others',
                scope:''
            },
            {
                name:'Medical Licentiates',
                link:'/categories/medical_licentiates',
                qualification:'Diploma in Clinical Medicine + Advanced Diploma In CM',
                zambiaQualificationAuthority:'Level 6',
                minimumCreditRequirement:'280 credits or 2800 notional Hours',
                areaOfPractice:'General Practitioner in medicine, maternal and child health, community medicine, perfom surgeries including C/S, especially at Primary health care level and secondary health facilities such as Health posts, health centres and first level hospitals such as district hospitals. May practice at general hospital and tertiary if needed',
                limitationsInDrugPrescription:'Non',
                limitationInStatutoryAndLegalDocumentSignatory:'yes, cannot sign certain documents unless under consultation with a medical doctor or as prescribed',
                administration:'yes, allowed legally to supervise Class C facilities such as health posts, health centers and can run these facilities independently',
                scope:''
            }
        ]
    },
    {
        name:'Others',
        professionalCatories:[
            {
                name:'Clinical Anaesthetic Officers',
                link:'/categories/clinical_anaesthetic_offices',
                qualification:'Bachelor of Science in Clinical Anaesthesia',
                zambiaQualificationAuthority:'Level 7',
                minimumCreditRequirement:'480 credits or 4800 notional Hours',
                areaOfPractice:'General Practitioner in medicine, provide Anesthesia and critical care services at all levels of health care; primary, secondary and tertiary/teaching/specialist hospitals',
                limitationsInDrugPrescription:'Non',
                limitationInStatutoryAndLegalDocumentSignatory:'Yes, cannot sign certain documents  unless under consultation with a medical doctor or as prescribed',
                administration:'Yes, allowed legally to supervise Class C facilities such as health posts, health centers and can run these facilities independently. Supervise juniors and others',
                scope:''
            },
            {
                name:'Mental Health Officers',
                link:'/categories/medical_psychiatry_officers',
                qualification:'Bachelor of Science in Mental Health & Clinical Psychiatry',
                zambiaQualificationAuthority:'Level 7',
                minimumCreditRequirement:'480 credits or 4800 notional Hours',
                areaOfPractice:'General Practitioner in medicine, provide mental health and Psychiatry, rehabilitative service care services in all aspects of mental health, treats complicated clinical psychiatry cases at all levels of health care; primary, secondary and tertiary/teaching/specialist hospitals',
                limitationsInDrugPrescription:'Non',
                limitationInStatutoryAndLegalDocumentSignatory:'Yes, cannot sign certain documents unless under consultation with a medical doctor or as prescribed',
                administration:'Yes, allowed legally to supervise Class C facilities such as health posts, health centers and can run these facilities independently. Supervise juniors and others',
                scope:''
            },
            {
                name:'Clinical Ophthalmic Officers',
                link:'/categories/clinical_ophthalmic_offices',
                qualification:'Bachelor of Science in Clinical Ophthalmology',
                zambiaQualificationAuthority:'Level 7',
                minimumCreditRequirement:'480 credits or 4800 notional Hours',
                areaOfPractice:'General Practitioner in medicine, providing eye services, including cataract surgeries and other eye procedures as per scope at all levels of health care; Primary, secondary and tertiary/teaching/specialist',
                limitationsInDrugPrescription:'Non',
                limitationInStatutoryAndLegalDocumentSignatory:'Yes, cannot sign certain documents such as those going to police reports unless under consultation with a medical doctor or prescribed',
                administration:'Yes, allowed legally to supervise Class C facilities such as health posts, health centers and can run these facilities independently. Allowed to supervise stand alone eye clinics',
                scope:''
            },
            {
                name:'Specialized Clinicians',
                link:'/categories/specialized_cllinicians',
                qualification:'',
                zambiaQualificationAuthority:'',
                minimumCreditRequirement:'',
                areaOfPractice:'',
                limitationsInDrugPrescription:'',
                limitationInStatutoryAndLegalDocumentSignatory:'',
                administration:'',
                scope:''
            }
        ]
    }
]

const getProfessionalCategories = () => {
    let pfCats = [];
    for(let category of categories) {
        for(let pfCat of category.professionalCatories) {
            pfCats.push(pfCat);
        }
    }
    return pfCats;
}

export const professionalCategories = {
    name:'Categories',
    link:'/categories',
    menus:(() => {return getProfessionalCategories()})()
};

export const organisation = {
    name:'Organisation',
    link:'/organisation',
    menus:[
        {
            name:'Governance',
            link:'/organisation/governance',
            menus:[
                {
                    name:'National Executive Committee',
                    link:'/organisation/governance/national_executive_committee',
                    about: [
                        'The National Executive Committee (NEC) is the governing body of the Clinical Officers Association of Zambia. Responsible for decision making, formulate and implement policies, and oversee the affairs of the Association',
                        'Has Authority to establish branches, committees and defend the association, members and the profession of clinical officer and its division at any level in Zambia and in signatory jurisdiction'
                    ],
                    composition:[
                        {
                            name:'',
                            position:'Hon President',
                            image:''
                        },
                        {
                            name:'',
                            position:'Hon Vice President',
                            image:''
                        },
                        {
                            name:'',
                            position:'Hon Secretary General',
                            image:''
                        },
                        {
                            name:'',
                            position:'Hon Deputy Secretary general',
                            image:''
                        },
                        {
                            name:'',
                            position:'Hon National Treasurer',
                            image:''
                        },
                        {
                            name:'',
                            position:'Hon National Publicity',
                            image:''
                        },
                        {
                            name:'',
                            position:'Nominated Members',
                            image:''
                        }
                    ]
                },
                {
                    name:'National Investment Board',
                    link:'/organisation/governance/national_investment_board',
                    about:[
                        'Presides over investments of the association and other related matters'
                    ],
                    composition:[
                        {
                            name:'',
                            position:'The Chairperson',
                            image:''
                        },
                        {
                            name:'',
                            position:'Vice Chairperson',
                            image:''
                        },
                        {
                            name:'',
                            position:'Board Member 1',
                            image:''
                        },
                        {
                            name:'',
                            position:'Board Member 2',
                            image:''
                        },
                        {
                            name:'',
                            position:'Board Member 3',
                            image:''
                        }
                    ]
                },
                {
                    name:'Committees of the Executine',
                    link:'/organisation/governance/committees_of_the_executive',
                    menus:[
                        {
                            name:'The Disciplinary, Ethics, and Professionalism',
                            link:'/organisation/governance/committees_of_the_executive/disciplinary_ethics_and_professionalism',
                            about:[
                                'Its mandate encompasses the development, enforcement, and continuous enhancement of ethical guidelines and codes of conduct within the professional community. Through rigorous investigation, fair adjudication, and education initiatives, strives to maintain an environment where ethical behavior remains of highest standard'
                            ],
                            composition:[
                                {
                                    name:'',
                                    position:'The Chairperson',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Vice Chairperson',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Board Member 1',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Board Member 2',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Board Member 3',
                                    image:''
                                }
                            ]
                        },
                        {
                            name:'Training and Education',
                            link:'/organisation/governance/committees_of_the_executive/training_and_education',
                            about:[
                                "Has a strategic focus in cultivating a dynamic and innovative educational system that equips members with the knowledge, skills, and competencies essential for leadership and excellence in clinical practice, research, and advocacy",
                                "Through cutting-edge curriculum development, Continuing professional development (CPD/CME), transformative pedagogical approaches, and strategic partnerships, the committee provides a direction, implementation and advocacy for a lifelong learning and continuous professional growth for the clinical professions"
                            ],
                            composition:[
                                {
                                    name:'',
                                    position:'The Chairperson',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Vice Chairperson',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Board Member 1',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Board Member 2',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Board Member 3',
                                    image:''
                                }
                            ]
                        },
                        {
                            name:'Finance and Administration',
                            link:'/organisation/governance/committees_of_the_executive/finance_and_administration',
                            about:[
                                "the steward for the profession's financial sustainability, operational effectiveness, and governance excellence. With a strategic focus on fiscal management, transparency, and accountability",
                                "The committee is mandated in the development of financial management practices, the optimization of administrative processes, and the cultivation of a governance framework that improves organizational resilience"
                            ],
                            composition:[
                                {
                                    name:'',
                                    position:'The Chairperson',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Vice Chairperson',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Board Member 1',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Board Member 2',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Board Member 3',
                                    image:''
                                }
                            ]
                        },
                        {
                            name:'Health Services',
                            link:'/organisation/governance/committees_of_the_executive/health_service',
                            about:[
                                "A committee of the executive that is a strategic driver for optimizing healthcare delivery, enhancing quality of care, and promoting equitable access to services",
                                "The committee focuses on identifying systemic barriers and inefficiencies, formulating evidence-based strategies for service improvement, and advocating for policies and initiatives that prioritize patient-centered care, health equity, and population health outcomes",
                                "Through collaboration, innovation, and advocacy, the committee endeavors to shape a healthcare landscape that ensures high-quality, accessible, and equitable care for all individuals and communities"
                            ],
                            composition:[
                                {
                                    name:'',
                                    position:'The Chairperson',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Vice Chairperson',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Board Member 1',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Board Member 2',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Board Member 3',
                                    image:''
                                }
                            ]
                        },
                        {
                            name:'Legislation and Judicial',
                            link:'/organisation/governance/committees_of_the_executive/legistration_and_judicial',
                            about:[
                                "The principal advocate and strategist for the profession's policy agenda, legislative priorities, and legal interests. The Committee’s mandate is to anticipate, analyze, and influence policy and legislative developments, champion initiatives that advance the interests and welfare of the profession and to provide expert guidance and representation in legal matters affecting members",
                            ],
                            composition:[
                                {
                                    name:'',
                                    position:'The Chairperson',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Vice Chairperson',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Board Member 1',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Board Member 2',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Board Member 3',
                                    image:''
                                }
                            ]
                        },
                        {
                            name:'Public Health and Research',
                            link:'/organisation/governance/committees_of_the_executive/public_health_and_research',
                            about:[
                                "A committee of the executive that is charged with advancing scientific discovery, research excellence, and public health impact. At the intersection of inquiry and action, the committee has a mandate that encompasses the promotion of rigorous scientific inquiry, the dissemination of evidence-based knowledge, and the translation of research findings into policies and practices that promote health equity and societal well-being",
                                "Committed to scientific integrity, innovation, and collaboration. Strives to leverage the collective expertise and influence of the profession to address pressing health challenges and drive meaningful change at local, national, and global levels"
                            ],
                            composition:[
                                {
                                    name:'',
                                    position:'The Chairperson',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Vice Chairperson',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Board Member 1',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Board Member 2',
                                    image:''
                                },
                                {
                                    name:'',
                                    position:'Board Member 3',
                                    image:''
                                }
                            ]
                        }
                    ]
                },
                {
                    name:'National Program Coordinators',
                    link:'/organisation/governance/national_program_coordinator',
                },
                {
                    name:'Provincial Executive Committee',
                    link:'/organisation/governance/provincial_executive_committee',
                },
                {
                    name:'Distrect Executive Committees',
                    link:'/organisation/governance/distrect_executive_committees',
                }
            ]
        },
        {
            name:'Administration',
            link:'/organisation/administration',
            menus:[
                {
                    name:'Employees of the Association',
                    link:'/organisation/administration/employees',
                    employees:[
                        {
                            name:'',
                            position:'Administrative secretary – Legal Services',
                            image:''
                        },
                        {
                            name:'',
                            position:'Administrative assistant – finance and administration',
                            image:''
                        },
                        {
                            name:'',
                            position:'Administrative assistant – front office services',
                            image:''
                        },
                        {
                            name:'',
                            position:'Information Technology officer (ITO)',
                            image:''
                        },
                        {
                            name:'',
                            position:'IT assistant – systems management',
                            image:''
                        },
                        {
                            name:'',
                            position:'Project officer – Hospital and infrastructure services',
                            image:''
                        },
                        {
                            name:'',
                            position:'Drivers',
                            image:''
                        }
                    ]
                },
                {
                    name:'Executive Functions',
                    link:'/organisation/administration/functions'
                }
            ]
        }
    ]
}

export const membership = {
    name:'Membership',
    link:'/membership',
    menus:[
        {
            name:'Full Membership',
            link:'/membership/full_membersip',
            EligibilityCriteria:() => 
                <div className='flex flex-col space-y-8 w-full h-auto'>
                    <p className='flex w-full h-auto font-semibold font-leBeauneNew text-[22px] lg:text-4xl text-[rgb(59,59,59)]'>
                        Eligibility Criteria
                    </p>
                    <p className='w-full h-auto text-lg text-[rgb(100,100,100)] font-jostBook'>
                        Full Membership within the Clinical Officers Association of Zambia (COAZ) shall be individuals who meet 
                        the following criteria:
                    </p>
                    <div className='flex flex-row space-x-4'>
                        <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Successful Completion: Prospective Full Members must have successfully completed accredited clinical 
                            officer training programs recognized by relevant regulatory bodies
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Valid Practicing license: As a prerequisite, individuals seeking Full Membership must hold valid 
                            practicing license issued by the Health Professions council of Zambia
                        </p>
                    </div>
                </div>,
            RightsAndPrivileges:() => 
                <div className='flex flex-col space-y-8 w-full h-auto'>
                    <p className='flex w-full h-auto font-semibold font-leBeauneNew text-[22px] lg:text-4xl text-[rgb(59,59,59)]'>
                        Rights and Privileges
                    </p>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'a)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Participation: Full Members have the right to actively participate in all activities organized by the Association
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'b)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Voting Rights: Full Members shall have the right to cast votes in elections, referendums, and any other matters subjected to a vote by the membership
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'c)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Candidacy for Elective Offices: Full Members are eligible to stand for elective offices within 
                            COAZ, subject to compliance with the relevant provisions outlined in the Constitution
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'d)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Access to Resources: Full Members shall have unrestricted access to educational resources, 
                            publications, and any other deemed beneficial by the Association
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'e)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Benefits Determined by the National Executive Committee: The National Executive Committee 
                            reserves the authority to confer additional benefits and privileges upon Full Members as deemed 
                            appropriate
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'f)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Access to Due Process: Members facing disciplinary actions or disputes with the association 
                            shall be entitled to due process, including the right to be heard, present evidence, and appeal 
                            decisions through established procedures
                        </p>
                    </div>
                </div>,
            ObligationsAndResponsibilities:() => 
                <div className='flex flex-col space-y-8 w-full h-auto'>
                    <p className='flex w-full h-auto font-semibold font-leBeauneNew text-[22px] lg:text-4xl text-[rgb(59,59,59)]'>
                        Obligations and Responsibilities
                    </p>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'a)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Adherence to Constitution: All Full members of the Clinical Officers Association of Zambia 
                            (COAZ) shall comply with the provisions outlined in this Constitution and any regulations 
                            constituted by the association
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'b)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Knowledge of Constitution: Members are obligated to familiarize themselves with the content of 
                            this Constitution and stay informed about any amendments or updates made by the association
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'c)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Professional Ethics: Members shall uphold the highest standards of professional ethics in the 
                            practice, maintaining the integrity and reputation of the profession, practice, and the 
                            association
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'d)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Compliance with Regulations: Members shall adhere to any ethical guidelines, codes of conduct, 
                            or regulations established
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'e)'}</p>
                        <div className='flex flex-col w-full h-auto space-y-4'>
                            <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                                Financial Responsibilities
                            </p>
                            <div className='flex flex-row space-x-4'>
                                <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'i.'}</p>
                                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                                    Timely Payment: Members are required to pay membership subscriptionfees, or any other 
                                    financial obligations to COAZ promptly, ensuring the continuous financial 
                                    sustainability of the association
                                </p>
                            </div>
                            <div className='flex flex-row space-x-4'>
                                <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'ii.'}</p>
                                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                                    Consequences of Non-Payment: Failure to fulfill financial obligations may result in the 
                                    suspension of membership benefits or other disciplinary actions as stipulated in this 
                                    Constitution
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'e)'}</p>
                        <div className='flex flex-col w-full h-auto space-y-4'>
                            <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                                Financial Transparency
                            </p>
                            <div className='flex flex-row space-x-4'>
                                <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'i.'}</p>
                                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                                    Access to Financial Reports: Members shall have the right to access summarized 
                                    financial reports, including income, expenditure, and budgetary allocations, subject to 
                                    relevant provisions governing access to information in Zambia
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        },
        {
            name:'Associate Membership',
            link:'/membership/associate_membersip',
            EligibilityCriteria:() => 
                <div className='flex flex-col space-y-8 w-full h-auto'>
                    <p className='flex w-full h-auto font-semibold font-leBeauneNew text-[22px] lg:text-4xl text-[rgb(59,59,59)]'>
                        Eligibility Criteria
                    </p>
                    <p className='w-full h-auto text-lg text-[rgb(100,100,100)] font-jostBook'>
                        Associate Membership is designated for individuals engaged in professions allied to clinical medicine, including 
                        but not limited to medical researchers, healthcare administrators, or educators. The eligibility criteria for 
                        Associate Membership shall be defined in the Association's guidelines
                    </p>
                </div>,
            RightsAndPrivileges:() => 
                <div className='flex flex-col space-y-8 w-full h-auto'>
                    <p className='flex w-full h-auto font-semibold font-leBeauneNew text-[22px] lg:text-4xl text-[rgb(59,59,59)]'>
                        Rights and Privileges
                    </p>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'a)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Attendance at General Meetings: Associate Members have the right to attend general meetings 
                            convened by the Association
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'b)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Non-voting Participation: While Associate Members may participate in discussions and activities, 
                            they shall not have voting rights in Association matters
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'c)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Access to Designated Resources: Associate Members shall have access to specific resources and 
                            materials as determined by the National Executive Committee
                        </p>
                    </div>
                </div>
        },
        {
            name:'Student Membership',
            link:'/membership/student_membersip',
            EligibilityCriteria:() => 
                <div className='flex flex-col space-y-8 w-full h-auto'>
                    <p className='flex w-full h-auto font-semibold font-leBeauneNew text-[22px] lg:text-4xl text-[rgb(59,59,59)]'>
                        Eligibility Criteria
                    </p>
                    <p className='w-full h-auto text-lg text-[rgb(100,100,100)] font-jostBook'>
                        Student Membership is open to individuals actively enrolled in recognized clinical officer/clinical 
                        Sciences/clinical medicine training programs. The eligibility requirements and conditions for 
                        Student Membership shall be explicitly set forth in the Association's regulations
                    </p>
                </div>,
            RightsAndPrivileges:() => 
                <div className='flex flex-col space-y-8 w-full h-auto'>
                    <p className='flex w-full h-auto font-semibold font-leBeauneNew text-[22px] lg:text-4xl text-[rgb(59,59,59)]'>
                        Rights and Privileges
                    </p>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'a)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Educational Event Participation: Student Members shall be entitled to attend educational events 
                            organized by COAZ to support their academic and professional development
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'b)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Mentorship Opportunities: Student Members may receive mentorship from Full Members of the 
                            Association, fostering their growth within the clinical officer profession
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'c)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Access to Resources: Student Members shall have access to resources aimed at supporting their 
                            academic endeavors and professional aspirations
                        </p>
                    </div>
                </div>
        },
        {
            name:'Life Membership',
            link:'/membership/life_membersip',
            EligibilityCriteria:() => 
                <div className='flex flex-col space-y-8 w-full h-auto'>
                    <p className='flex w-full h-auto font-semibold font-leBeauneNew text-[22px] lg:text-4xl text-[rgb(59,59,59)]'>
                        Eligibility Criteria
                    </p>
                    <p className='w-full h-auto text-lg text-[rgb(100,100,100)] font-jostBook'>
                        Life Membership may be conferred upon Full Members in recognition of exceptional contributions to 
                        COAZ or the broader field of clinical medicine. The specific criteria for conferring Life 
                        Membership shall be outlined in the Association's member regulations
                    </p>
                </div>,
            RightsAndPrivileges:() => 
                <div className='flex flex-col space-y-8 w-full h-auto'>
                    <p className='flex w-full h-auto font-semibold font-leBeauneNew text-[22px] lg:text-4xl text-[rgb(59,59,59)]'>
                        Rights and Privileges
                    </p>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'a)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Lifetime Access: Life Members shall have lifetime access to Association activities, resources, 
                            and benefits, same as full members
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'b)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Exemption fromSubscription Fees: Life Members shall be exempt from any annual subscription fees
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'c)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            All current and former National executive members shall have life membership status
                        </p>
                    </div>
                </div>
        },
        {
            name:'Honorary Membership',
            link:'/membership/honorary-membership',
            EligibilityCriteria:() => 
                <div className='flex flex-col space-y-8 w-full h-auto'>
                    <p className='flex w-full h-auto font-semibold font-leBeauneNew text-[22px] lg:text-4xl text-[rgb(59,59,59)]'>
                        Eligibility Criteria
                    </p>
                    <p className='w-full h-auto text-lg text-[rgb(100,100,100)] font-jostBook'>
                        Honorary Membership may be granted to individuals who have made significant contributions to the 
                        advancement of clinical medicine. The criteria and procedures for conferring Honorary Membership 
                        shall be established by the National Executive Committee
                    </p>
                </div>,
            RightsAndPrivileges:() => 
                <div className='flex flex-col space-y-8 w-full h-auto'>
                    <p className='flex w-full h-auto font-semibold font-leBeauneNew text-[22px] lg:text-4xl text-[rgb(59,59,59)]'>
                        Rights and Privileges
                    </p>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'a)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Special Recognition: Honorary Members shall be accorded special recognition by the association 
                            for their distinguished contributions health or education or similar
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'b)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Attendance at Designated Events: Honorary Members may be invited to attend specific Association 
                            events as determined by the Executive Committee
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'c)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Exemption from Membership Fees: Honorary Members shall be exempt from any membership fees as 
                            prescribed by the Association
                        </p>
                    </div>
                </div>
        },
        {
            name:'Grounds for Termination',
            link:'/membership/grounds_for_termination',
            GroundsForTermination:() => 
                <div className='flex flex-col space-y-8 w-full h-auto'>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'a)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Non-payment of Dues: Membership may be terminated due to non-payment of membership dues or any 
                            prescribed fees within the stipulated timeframes
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'b)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Violation of Code of Ethics: Termination may result from a member's violation of the COAZ Code 
                            of Ethics or Health professions code of ethics
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'c)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Conduct Prejudicial to the Association: Membership may be terminated if a member engages in 
                            conduct deemed prejudicial to the interests and objectives of the Association
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'d)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Resignation: membership shall be terminated upon resignation
                        </p>
                    </div>
                </div>
        },
        {
            name:'Termination Procedure',
            link:'/membership/termination_procedure',
            TerminationProcedure:() => 
                <div className='flex flex-col space-y-8 w-full h-auto'>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'a)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Notice: Prior to termination, a member shall be provided with written notice specifying the 
                            grounds for termination and affording an opportunity to respond
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'b)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Appeal Mechanism: The member subject to termination shall have the right to appeal against the 
                            decision through established appeal mechanisms outlined in the Association's regulations
                        </p>
                    </div>
                    <div className='flex flex-row space-x-4'>
                        <p className='font-jostBook text-[rgb(85,85,85)] text-lg'>{'c)'}</p>
                        <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                            Resignation: Any member may voluntarily resign from COAZ by submitting written notice to the 
                            Secretary General. The resignation shall become effective after 90 days
                        </p>
                    </div>
                </div>
        }
    ]
}

export const menus = [
    {
        name:'Home',
        link:'/home'
    },
    (() => {return association})(),
    (() => {return services})(),
    (() => {return professionalCategories})(),
    (() => {return organisation})(),
    (() => {return membership})(),
    {
        name:'About',
        link:'/about'
    },
    {
        name:'News and Events',
        link:'/news'
    }
];

export const partners = [
    {
        image:'/images/hpcz.png',
        description:'Collaborates in enforcement of regulations, standards of practice and other functions'
    },
    {
        image:'/images/hea.png',
        description:'Collaborates with the higher education Authority in matters of training and other matters related'
    },
    {
        image:'/images/moh.png',
        description:'Providing Health services and enforcing standards in health service delivery'
    },
    {
        image:'/images/local_authority.png',
        description:'Works to strengthen local involvement in Health services and others'
    }
]

export const  news = [
    {
        tag:'agm',
        image:'/images/img_35.jpg',
        date:'April 15, 2024',
        heading:'delivering care',
        content:'COAZ is dedicated to promoting excellence in professional practice within the healthcare sector. We provide resources, guidelines, and support to empower our member healthcare professionals to deliver high-quality care to their patients. Our focus is enhancing a culture of continuous improvement and innovation in healthcare delivery'
    },
    {
        image:'/images/img_36.jpg',
        date:'April 15, 2024',
        heading:'Professional practice',
        content:'Patients in Primary Health care facilities deserve care led by Clinical practioners—the most highly educated, trained and skilled health care professionals at such levels. Through research, advocacy and education, the COAZ vigorously defends the Clinical practice against scope of practice expansions that threaten patient safety'
    },
    {
        tag:'agm',
        image:'/images/img_37.jpg',
        date:'April 15, 2024',
        heading:'Advocacy in Health Care',
        content:'At the state level, the COAZ shapes the policy of influential national policymaking, partners with outside experts and stakeholder groups and influences the enactment and/or defeat of state legislation and regulation'
    },
    {
        tag:'agm',
        image:'/images/img_40.jpg',
        date:'April 15, 2024',
        heading:'Member Benefits',
        content:'Joining the nation’s largest Health professional Organization in Zambia has considerable benefits. In addition to opportunities to shape the future of health care, COAZ membership benefits include exclusive access to savings and resources designed to enhance the personal and professional lives of Members'
    }
]
export const  events = [
    {
        image:'/images/img_38.jpg',
        date:'April 16, 2024',
        title:'annual general meeting',
        start:'7:00 am',
        end:'10:00 am',
        venue:'Coaz headquoters boardroom'
    },
    {
        image:'/images/img_21.jpg',
        date:'April 18, 2024',
        title:'the vice president will be visiting our headquoter offices in lusaka',
        start:'8:00 am',
        end:'10:00 am',
        venue:'Coaz headquoters'
    },
    {
        image:'/images/img_41.jpg',
        date:'April 20, 2024',
        title:"Appointment of the association's president",
        start:'8:00 am',
        end:'10:00 am',
        venue:'Coaz headquoters boardroom'
    },
    {
        image:'/images/img_39.jpg',
        date:'April 22, 2024',
        title:'welcoming of the new president',
        start:'10:00 am',
        end:'1:00 pm',
        venue:'Coaz headquoters main hall'
    }
]



export const getSettings = (user,hasAuthority) => {
    let menus = [];
    menus.push(
        {
            name:'Users',
            Icon: CIUserSettings,
            link:'/settings/users',
            Component:Users,
            ItemComponent: User
        }
    )   
    if(hasAuthority(user,'READ_ACCOUNT')) {
        menus.push(
            {
                name:'Accounts',
                Icon: CIAccount,
                link:'/settings/accounts',
                Component: Accounts,
                ItemComponent: Account
            }
        )
    }
    if(hasAuthority(user,'READ_ACCOUNT')) {
        menus.push(
            {
                name:'Transactions',
                Icon:CITransaction,
                link:'/settings/transactions',
                Component: Transactions
            }
        )
    }
    menus.push(
        {
            name:'Roles',
            Icon: CIRole,
            link:'/settings/roles',
            Component: Roles,
            ItemComponent: Role
        }
    )
    menus.push(
        {
            name:'CPDs',
            Icon:CITransaction,
            link:'/settings/cpds',
            Component: CPDs,
            ItemComponent: CPD
        }
    )
    menus.push(
        {
            name:'Events',
            Icon:BsCalendar4Event,
            link:'/settings/events',
            Component: Events,
            ItemComponent: Event
        }
    )
    return {
        name:'settings',
        Icon: TbSettings2,
        link:'settings',
        menus:menus
    }
}

export const sex = [
    'MALE',
    'FEMALE'
]

export const sectors = [
    'GRZ',
    'PRIVATE'
]

export const idTypes = [
    "NRC",
    "PASSPORT",
    "DRIVER_LICENSE"
];

export const userStatuses = [
    'ACTIVE',
	'SUSPENDED',
	'DELETED'
];
export const provinces = [
    {
        name: 'Central',
        districts: [
            'Chibombo',
            'Chisamba',
            'Chitambo',
            'Kabwe',
            'Kapiri Mposhi',
            'Luano',
            'Mkushi',
            'Mumbwa',
            'Ngabwe',
            'Serenje',
            'Shibuyunji'
        ]
    },
    {
        name:'Copperbelt',
        districts: [
            'Chililabombwe',
            'Chingola',
            'Kalulushi',
            'Kitwe',
            'Luanshya',
            'Lufwanyama',
            'Masaiti',
            'Mpongwe',
            'Mufulira',
            'Ndola'
        ]
    },
    {
        name:'Eastern',
        districts:[
            'Chadiza',
            'Chama',
            'Chasefu',
            'Chipangali',
            'Chipata',
            'Kasenengwa',
            'Katete',
            'Lumezi',
            'Lundazi',
            'Lusangazi',
            'Mambwe',
            'Nyimba',
            'Petauke',
            'Sinda',
            'Vubwi'
        ]
    },
    {
        name:'Luapula',
        districts:[
            'Chembe',
            'Chiengi',
            'Chifunabuli',
            'Chipili',
            'Kawambwa',
            'Lunga',
            'Mansa',
            'Milenge',
            'Mwansabombwe',
            'Mwense',
            'Nchelenge',
            'Samfya'
        ]
    },
    {
        name:'Lusaka',
        districts:[
            'Chilanga', 
            'Chirundu',
            'Chongwe', 
            'Kafue', 
            'Luangwa',
            'Lusaka',
            'Rufunsa'
        ]
    },
    {
        name:'Muchinga',
        districts:[
            'Chinsali',
            'Isoka',
            'Mafinga',
            'Mpika',
            'Nakonde',
            'Shiwangandu',
            'Kanchibiya',
            'Lavushimanda'
        ]
    },
    {
        name:'North-Western',
        districts:[
            'Chavuma',
            'Ikelenge',
            'Kabompo',
            'Kalumbila',
            'Kasempa',
            'Manyinga',
            'Mufumbwe',
            'Mushindamo',
            'Mwinilunga',
            'Solwezi',
            'Zambezi'
        ]
    },
    {
        name:'Northern',
        districts:[
            'Kasama',
            'Chilubi',
            'Kaputa',
            'Luwingu',
            'Mbala',
            'Mporokoso',
            'Mpulungu',
            'Mungwi',
            'Nsama',
            'Lupososhi',
            'Lunte',
            'Senga Hill'
        ]
    },
    {
        name:'Southern',
        districts:[
            'Chikankata',
            'Chirundu',
            'Choma',
            'Gwembe',
            'Itezhi-Tezhi',
            'Kalomo',
            'Kazungula',
            'Livingstone',
            'Mazabuka',
            'Monze',
            'Namwala',
            'Pemba',
            'Siavonga',
            'Sinazongwe',
            'Zimba'
        ]
    },
    {
        name:'Western',
        districts:[
            'Kalabo',
            'Kaoma',
            'Limulunga',
            'Luampa',
            'Lukulu',
            'Mitete',
            'Mongu',
            'Mulobezi',
            'Mwandi',
            'Nalolo',
            'Nkeyema',
            'Senanga',
            'Sesheke',
            'Shangombo',
            'Sikongo',
            'Sioma'
        ]
    }
];

export const nationalities = [
    'Algerian',
    'Angolan',
    'Beninese',
    'Botswana',
    'Burkinabé',
    'Burundian',
    'Cameroonian',
    'Cape Verdean',
    'Central African Republic',
    'Chadian',
    'Comorian',
    'Congolese(DRC)',
    'Congolese(Congo)',
    'Djiboutian',
    'Egyptian',
    'Equatoguinean',
    'Eritrean',
    'Ethiopian',
    'Gabonese',
    'Gambian',
    'Ghanaian',
    'Bissau-Guinean',
    'Guinean',
    'Ivorian',
    'Kenyan',
    'Lesotho',
    'Liberian',
    'Libyan',
    'Malagasy',
    'Malawian',
    'Malian',
    'Mauritanian',
    'Mauritian',
    'Moroccan',
    'Mozambican',
    'Namibian',
    'Nigerian',
    'Nigerien',
    'Rhodesian',
    'Rwandan',
    'Sahrawi',
    'São Tomé and Príncipe',
    'Senegalese',
    'Seychellois',
    'Sierra Leonean',
    'Somalian',
    'Somaliland',
    'South African',
    'South Sudanese',
    'Sudanese',
    'Swazi',
    'Tanzanian',
    'Togolese',
    'Tunisian',
    'Ugandan',
    'Zambian',
    'Zimbabwean',
    'Other'
];