import React, {useState, useContext} from 'react';
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate,useParams } from 'react-router-dom';
import UserService from '../services/UserService';
import { RiLockPasswordFill } from "react-icons/ri";
import FormValidator, {useFormValidator} from './FormValidator';
import Input from './Input';
import Message from './Message';

const ChangePassword = () => {
    const {setLoading} = useContext(GlobalContext);
    const {username} = useParams();
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState(""); 
    const [confirmPassword, setConfirmPassword] = useState(""); 
    const [message,setMessage] = useState({content:'',success:false}); 
    const navigate = useNavigate();

    const inputWidth = 315;

    const onOldPassword = (e) => {
        setOldPassword(e.target.value);
    }

    const onNewPassword = (e) => {
        setNewPassword(e.target.value);
    }

    const onConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
    }

    const submit = async (e) => {
        setMessage({content:'',success:false});
        if(newPassword === confirmPassword) {
            setLoading(true);
            UserService.setPassword(username,oldPassword,newPassword)
            .then((response) => {
                setLoading(false);
                UserService.logout();
                if(response.status) {
                    if(response.status === 'SUCCESSFUL') {
                        setMessage({content:response.message,success:true});

                        navigate('/access/login');
                    } else {
                        setMessage({content:response.message,success:false});
                    }
                } else  {
                    setMessage({content:response,success:false});
                }
            })
            .catch((error) => {
                UserService.logout();
                setMessage({content:error.message,success:false});
                setLoading(false);
            });
        } else {
            setMessage({content:"New password and confirm password didn't match, try again!",success:false});
        }
    };

    const [register,handleChange,handleSubmit,errors] = useFormValidator(submit);

    return (
        <FormValidator>
            <div className='flex flex-col w-full lg:w-auto h-full justify-center space-y-4 m-auto no-scrollbar overflow-auto'>
                <RiLockPasswordFill size={64} className='flex mx-auto text-[rgba(84,84,87,.7)]'/>
                <div className='flex flex-col lg:flex-row w-auto h-auto space-y-4 lg:space-y-0 lg:space-x-4'>
                    <Input 
                        label="Username"
                        type="text" 
                        id="username" 
                        name="username}"  
                        value={username}
                        disabled={true}
                        width={inputWidth}
                    />
                    <Input 
                        label="Old Password"
                        type="password"
                        id="oldPassword" 
                        name="oldPassword"  
                        value={oldPassword}
                        placeholder="Enter old password..."
                        onChange= {(e) => handleChange(e,onOldPassword)}
                        register={register}
                        errors={errors}
                        width={inputWidth}
                    />
                </div>
                <div className='flex flex-col lg:flex-row w-auto h-auto space-y-4 lg:space-y-0 lg:space-x-4'>
                    <Input 
                        label="New Password"
                        type="password"
                        id="newPassword" 
                        name="newPassword"  
                        value={newPassword}
                        placeholder="Enter new password..."
                        onChange= {(e) => handleChange(e,onNewPassword)}
                        register={register}
                        errors={errors}
                        width={inputWidth}
                    />
                    <Input 
                        label="Confirm Password"
                        type="password"
                        id="confirmPassword" 
                        name="confirmPassword"  
                        value={confirmPassword}
                        placeholder="Confirm password..."
                        onChange= {(e) => handleChange(e,onConfirmPassword)}
                        register={register}
                        errors={errors}
                        width={inputWidth}
                    />
                </div>
                <Message message={message}/>
                <button style={{'--width':inputWidth+'px'}} 
                    onClick={handleSubmit} className='flex shrink-0 w-full lg:w-[var(--width)] h-10 mx-auto rounded-lg items-center justify-center bg-[rgb(0,175,240)] hover:bg-[rgba(0,175,240,.7)] text-white'>
                    Submit
                </button>
            </div>
        </FormValidator>
    )
}

export default ChangePassword
