import React, {useState,useEffect,useContext} from 'react'
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate } from 'react-router-dom'
import {sex,provinces,nationalities,idTypes,professionalCategories} from '../data';
import UserService from '../services/UserService';
import UserForm, {Page,ImageUpload} from './UserForm'
import {useFormValidator} from './FormValidator';
import { BiSolidLogInCircle,BiSolidContact } from "react-icons/bi";
import { ImUser } from "react-icons/im";
import { IoSchoolSharp,IoCloseOutline } from "react-icons/io5";
import { FaUserDoctor } from "react-icons/fa6";
import { MdImage } from "react-icons/md";
import FormDialog from './FormDialog';
import User from './User';

const EditUser = ({id,update,self=false}) => {
    const {setReloadUser,setDialog,screenSize,setLoading} = useContext(GlobalContext);
    const [user,setUser] = useState(null);
    const [province,setProvince] = useState(provinces[0]);
    const [image, setImage] = useState(null);
    const [message,setMessage] = useState({content:'',success:false});
    const [setPassword,setSetPassword] = useState(false);
    const [newPassword,setNewPassword] = useState('');
    const [confirmPassword,setConfirmPassword] = useState('');
    const [oldPassword,setOldPassword] = useState('');

    const inputWidth = 315;

    const onChange = (e) => {
        const value = e.target.value;
        if(value === '') {
            setUser({...user, [e.target.name]: null});
        } else {
            setUser({...user, [e.target.name]: value});
        }
    };

    const onNewPassword = (e) => {
        const value = e.target.value;
        if(value === '') {
            setNewPassword(null);
        } else {
            setNewPassword(value);
        }
    }; 

    const onConfirmPassword = (e) => {
        const value = e.target.value;
        if(value === '') {
            setConfirmPassword(null);
        } else {
            setConfirmPassword(value);
        }
    }; 

    const onOldPassword = (e) => {
        const value = e.target.value;
        if(value === '') {
            setOldPassword(null);
        } else {
            setOldPassword(value);
        }
    }; 

    const onDateOfBirth = (e) => {
        const value = e.target.value;
        setUser({...user, dateOfBirth: new Date(value)});
    };

    const onProvince = (e) => {
        const value = e.target.value;
        const province = provinces.find((province) => {return province.name == value});
        if(province) {
            setProvince(province);
            setUser({...user,province:province.name});
        }
    };

    const onEmployed = (e) => {
        setUser({...user,employed: !user.employed});
    }

    const submit = async () => {
        setLoading(true);
        setMessage({content:'',success:false});
        if(self) {
            await UserService.updateUserSelf(user,setPassword,oldPassword,newPassword,false)
            .then(async (updateResponse) => {
                setLoading(false);
                if(updateResponse.status){
                    if(updateResponse.status === 'SUCCESSFUL' && updateResponse.content) {
                        setMessage({content:updateResponse.message,success:true});
                        update(updateResponse.content);
                        setDialog(null);
                    }  else {
                        setMessage({content:updateResponse.message,success:false});
                    }
                }  else {
                    setMessage({content:updateResponse,success:false});
                }
            })
            .catch((error) => {
                setLoading(false);
                setMessage({content:error.message,success:false});
            }); 
        } else {
            await UserService.updateUser(user,false)
            .then(async (updateResponse) => {
                setLoading(false);
                if(updateResponse.status){
                    if(updateResponse.status === 'SUCCESSFUL' && updateResponse.content) {
                        setMessage({content:updateResponse.message,success:true});
                        update(updateResponse.content);
                        setDialog(null);
                    }  else {
                        setMessage({content:updateResponse.message,success:false});
                    }
                }  else {
                    setMessage({content:updateResponse,success:false});
                }
            })
            .catch((error) => {
                setLoading(false);
                setMessage({content:error.message,success:false});
            }); 
        }
        setReloadUser({reload:true});
    };

    const [register,handleChange,handleSubmit,errors] = useFormValidator(submit);
    const minWidth = screenSize !== 'lg'?240:320;
    const pages = [
        self?
        <Page
            key={1}
            Icon={BiSolidLogInCircle}
            id='1'
            name='Login Details'
            inputs={[
                {
                    label:'Username',
                    type:'text',
                    name:'username', 
                    value:user && user.username?user.username:'',
                    placeholder:'Enter username...',
                    onChange:(e) => handleChange(e,onChange),
                    register:register,
                    errors:errors
                },
                {
                    label:'Set Password',
                    type:'checkbox', 
                    name:'setPassword',
                    value:setPassword,   
                    onChange:(e) => setSetPassword(!setPassword)
                },
                {
                    label:'Old Password',
                    type:'password',
                    name:'oldPassword', 
                    value:oldPassword?oldPassword:'',
                    placeholder:'Enter old password...',
                    disabled:!setPassword,
                    onChange:(e) => handleChange(e,onOldPassword)
                },
                {
                    label:'New Password',
                    type:'password',
                    id:'04_newPassword',
                    name:'newPassword', 
                    value:newPassword?newPassword:'',
                    placeholder:'Enter new password...',
                    disabled:!setPassword,
                    onChange:(e) => handleChange(e,onNewPassword)
                },
                {
                    label:'Confirm Password',
                    type:'password',
                    name:'confirmPassword', 
                    value:confirmPassword?confirmPassword:'',
                    placeholder:'Confirm password...',
                    disabled:!setPassword,
                    onChange:(e) => handleChange(e,onConfirmPassword)
                }
            ]}
            minWidth={minWidth}
        />:
        <Page
            key={1}
            Icon={BiSolidLogInCircle}
            id='1'
            name='Login Details'
            inputs={[
                {
                    label:'Username',
                    type:'text',
                    name:'username', 
                    value:user && user.username?user.username:'',
                    placeholder:'Enter username...',
                    onChange:(e) => handleChange(e,onChange),
                    register:register,
                    errors:errors
                }
            ]}
            minWidth={minWidth}
        />
        ,
        <Page
            key={2}
            Icon={ImUser}
            id='2'
            name='Personal Details'
            inputs={[
                {
                    label:'Firstname',
                    type:'text', 
                    name:'firstname',
                    value:user && user.firstname?user.firstname:'',   
                    placeholder:'Enter firstname...',
                    onChange:(e) => {handleChange(e,onChange)},
                    register:register,
                    errors:errors
                },
                {
                    label:'Lastname',
                    type:'text',
                    name:'lastname', 
                    value:user && user.lastname?user.lastname:'',
                    placeholder:'Enter lastname...',
                    onChange:(e) => handleChange(e,onChange),
                    register:register,
                    errors:errors
                },
                {
                    label:'Middlename',
                    type:'text',
                    name:'middlename', 
                    value:user && user.middlename?user.middlename:'',
                    placeholder:'Enter middlename...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Sex',
                    type:'select',
                    options:() => {
                        let options = [];
                        sex.map((option,i) => options.push(<option key={i} value={option}>{option}</option>));
                        return options;
                    },
                    name:'sex', 
                    value:user && user.sex?user.sex:'',
                    placeholder:'Select sex...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Date of Birth',
                    type:'date',
                    name:'dateOfBirth', 
                    value:user && user.dateOfBirth?user.dateOfBirth.toISOString().slice(0, 10):'',
                    placeholder:'Pick date of birth...',
                    onChange:(e) => handleChange(e,onDateOfBirth)
                },
                {
                    label:'ID type',
                    type:'select',
                    options:() => {
                        let options = [];
                        idTypes.map((option,i) => options.push(<option key={i} value={option}>{option}</option>));
                        return options;
                    },
                    name:'idType', 
                    value:user && user.idType?user.idType:'',
                    placeholder:'Select ID type...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'ID number',
                    type:'text',
                    name:'idNumber', 
                    value:user && user.idNumber?user.idNumber:'',
                    placeholder:'Enter ID number...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Nationality',
                    type:'select',
                    options:() => {
                        let options = [];
                        nationalities.map((option,i) => options.push(<option key={i} value={option}>{option}</option>));
                        return options;
                    },
                    name:'nationality', 
                    value:user && user.nationality?user.nationality:'',
                    placeholder:'Select nationality...',
                    onChange:(e) => handleChange(e,onChange)
                }
            ]}
            minWidth={minWidth}
        />,
        <Page
            key={3}
            Icon={BiSolidContact}
            id='3'
            name='Contact Details'
            inputs={[
                {
                    label:'Email',
                    type:'text',
                    id:'13_email', 
                    name:'email',
                    value:user && user.email?user.email:'',   
                    placeholder:'Enter email...',
                    onChange:(e) => {handleChange(e,onChange)}
                },
                {
                    label:'Phone 1',
                    type:'text',
                    name:'phone1', 
                    value:user && user.phone1?user.phone1:'',
                    placeholder:'Enter phone 1...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Phone 2',
                    type:'text',
                    name:'phone2', 
                    value:user && user.phone2?user.phone2:'',
                    placeholder:'Select phone2...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Physical address',
                    type:'text',
                    name:'physicalAddress', 
                    value:user && user.physicalAddress?user.physicalAddress:'',
                    placeholder:'Enter physical address...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Postal Address',
                    type:'text',
                    name:'postalAddress', 
                    value:user && user.postalAddress?user.postalAddress:'',
                    placeholder:'Enter postal address...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Province',
                    type:'select',
                    options:() => {
                        let options = [];
                        provinces.map((option,i) => options.push(<option key={i} value={option.name}>{option.name}</option>));
                        return options;
                    },
                    name:'province', 
                    value:user && user.province?user.province:'',
                    placeholder:'Enter province...',
                    onChange:(e) => handleChange(e,onProvince)
                },
                {
                    label:'District',
                    type:'select',
                    options:() => {
                        let options = [];
                        province.districts.map((option,i) => options.push(<option key={i} value={option}>{option}</option>));
                        return options;
                    },
                    name:'district', 
                    value:user && user.district?user.district:'',
                    placeholder:'Select district...',
                    onChange:(e) => handleChange(e,onChange)
                }
            ]}
            minWidth={minWidth}
        />,
        <Page
            key={4}
            Icon={IoSchoolSharp}
            id='4'
            name='Educational Details'
            inputs={[
                {
                    label:'Program',
                    type:'text', 
                    name:'program',
                    value:user && user.program?user.program:'',   
                    placeholder:'Enter program...',
                    onChange:(e) => {handleChange(e,onChange)}
                },
                {
                    label:'Institution',
                    type:'text',
                    name:'institution', 
                    value:user && user.institution?user.institution:'',
                    placeholder:'Enter Institution...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Professional Category',
                    type:'select',
                    options:() => {
                        let options = [];
                        professionalCategories.menus.map((option,i) => options.push(<option key={i} value={option.name}>{option.name}</option>));
                        return options;
                    },
                    id:'23_professionalCategory',
                    name:'professionalCategory', 
                    value:user && user.professionalCategory?user.professionalCategory:'',
                    placeholder:'Select professional category...',
                    onChange:(e) => handleChange(e,onChange)
                }
            ]}
            minWidth={minWidth}
        />,
        <Page
            key={5}
            Icon={FaUserDoctor}
            id='5'
            name='Employment Details'
            inputs={[
                {
                    label:'Employed',
                    type:'checkbox',
                    name:'employed',
                    value:user?user.employed:'',   
                    onChange:(e) => onEmployed(e)
                },
                {
                    label:'Employer',
                    type:'text',
                    name:'employer', 
                    value:user && user.employer?user.employer:'',
                    disabled:user?!user.employed:true,
                    placeholder:'Enter employer...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Organizational Unit',
                    type:'text',
                    name:'organizationalUnit', 
                    value:user && user.organizationalUnit?user.organizationalUnit:'',
                    disabled:user?!user.employed:true,
                    placeholder:'Enter organizational unit...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Current Position',
                    type:'text',
                    name:'currentPosition', 
                    value:user && user.currentPosition?user.currentPosition:'',
                    disabled:user?!user.employed:true,
                    placeholder:'Enter current position...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Facility',
                    type:'text',
                    name:'facility', 
                    value:user && user.facility?user.facility:'',
                    disabled:user?!user.employed:true,
                    placeholder:'Enter facility...',
                    onChange:(e) => handleChange(e,onChange)
                }
            ]}
            minWidth={minWidth}
        />,
        <ImageUpload 
            key={6}
            Icon={MdImage}
            id='6'
            image={image}
            setImage={setImage}
        />
    ]

    const navigate = useNavigate();

    useEffect(() => {
        ( async () => {
            setLoading(true);
            if(self) {
                await UserService.getCurrentUser(false)
                .then((response) => {
                    setLoading(false);
                    if(response.content) {
                        response.content.dateOfBirth = new Date(response.content.dateOfBirth);
                        setUser(response.content);
                    }  else {
                        setUser(null);
                        setDialog(null);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    setUser(null);
                    setDialog(null);
                })
            } else {
                await UserService.getUser(id,false)
                .then((response) => {
                    setLoading(false);
                    if(response.content) {
                        response.content.dateOfBirth = new Date(response.content.dateOfBirth);
                        setUser(response.content);
                    }  else {
                        setUser(null);
                        setDialog(null);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    setUser(null);
                    setDialog(null);
                })
            }
            
            setReloadUser({reload:true});
        }
        )();
    },[]);
 
    return (
        <FormDialog title='Edit User' height={600}>
            <UserForm pages={pages} onSubmit={handleSubmit} errors={errors} message={message}/>
        </FormDialog>
    )
}

export default EditUser
