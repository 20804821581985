import React,{useContext} from 'react'
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate } from 'react-router-dom';
import UserService from '../services/UserService';
import TransactionService from '../services/TransactionService';
import Table from './Table';
import User from './User';
import { GrTransaction } from "react-icons/gr";

const Transactions = () => {
    const {setDialog,hasAuthority,setLoading,setReloadUser} = useContext(GlobalContext);
    const navigate = useNavigate();
    const columns = [
        {
            name:'transactionId',
            label:'Transaction Id',
            type:'text',
            filter:''
        },
        {
            name:'externalId',
            label:'External Id',
            type:'text',
            filter: ''
        },
        {
            name:'amount',
            label:'Amount',
            type:'number',
            filter: ''
        },
        {
            name:'accountNumber',
            label:'Phone Number',
            type:'text',
            Filter: ''
        },
        {
            name:'message',
            label:'Message',
            type:'text',
            filter:''
        },
        {
            name:'status',
            label:'Status',
            type:'text',
            filter:''
        }
    ]

    const getTransactions = async (filter,page) => {
        let object = {}
        let current = null;
        await UserService.getCurrentUser()
        .then((response) => {
            if(response.content) {
                current = response.content;
            } 
        })
        .catch((error) => {
            console.log(error.message);
        });

        if(current && hasAuthority(current,'READ_TRANSACTION')) {
            await TransactionService.getTransactions(filter,page,false)
            .then((response) => {
                if(response.content) {
                    object = {
                        status:'SUCCESSFUL',
                        content:response.content,
                        pageNo:response.pageNo,
                        pageSize:response.pageSize,
                        totalElements:response.totalElements,
                        totalPages:response.totalPages,
                        last:response.last
                    }
                } else {
                    object = {
                        status:'FAILED',
                        content:[]
                    };
                }
            })
            .catch((error) => {
                console.log(error.message);
                object = {
                    status:'ERROR',
                    content:[]
                };
            });
        } else if(current) {
            current.role = current.role.name
            object = {
                status:'SUCCESSFUL',
                content:[current]
            }
        } else {
            object = {
                        status:'FAILED',
                        content:[]
                    };
        }
        return object;
    }

    const getButtons = (user,reload,setMessage) => {
        let buttons = [];
        return buttons;
    }

  return (
    <div className='relative flex w-full h-auto'>
        <Table columns={columns} retrieveRecords={getTransactions} getButtons={getButtons}/>
    </div>
  )
}

export default Transactions