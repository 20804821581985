import React,{useEffect,useState,useContext} from 'react'
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate,useParams } from 'react-router-dom';
import FormDialog from './FormDialog'
import Inputs from './Inputs'
import Message from './Message'
import CPDService from '../services/CPDService';
import CPDRoleService from '../services/CPDRoleService';
import PaymentService from '../services/PaymentService';

const CPDRoleSelector = ({user,cpd}) => {
    const {setLoading,setDialog} = useContext(GlobalContext);
    const [cpdRole,setCpdRole] = useState(null);
    const [cpdRoles,setCpdRoles] = useState([]);
    const [tariff,setTariff] = useState(null);
    const [message,setMessage] = useState({content:'',success:false}); 
    const minWidth = 240;
    const [inputWidth,setInputWidth] = useState(minWidth);
    const navigate = useNavigate();

    const handleContinue = async (e) => {
        if(tariff) {
            setDialog(null);
            let params = new Map();
            params.set('receivableId',cpd.id);
            params.set('role',user.role.id);
            cpdRole && params.set('cpd_role',cpdRole.id);
            navigate('/access/payment',{state:{params:params}});
        } else if(cpdRole) {
            await CPDService.enrollUser(user.id,cpd.id,cpdRole.id)
            .then((response) => {
                if(response.status && response.status === 'SUCCESSFUL' && response.message) {
                    setMessage({content:response.message,success:true});
                } else if(response.message) {
                    setMessage({content:response.message,success:false});
                } else {
                    setMessage({content:response,success:false});
                }
            })
            .catch((error) => {
                setMessage({content:error.message,success:false});
            })
        }
    }

    const getTariff = async (cpdRole) => {
        let criteria = new Map();
        criteria.set('receivableId',cpd.id);
        criteria.set('role',user.role.id);
        cpdRole && criteria.set('cpd_role',cpdRole.id);
        await PaymentService.getPaymentTariff(criteria)
        .then((response) => {
            setLoading(false);
            if(response.content) {
                setTariff(response.content);
            }  else {
                setTariff(null);
            }
        })
        .catch((error) => {
            setLoading(false);
            setTariff(null);
        })
    }

    const onChange = async (e) => {
        let id = e.target.value;
        let value = cpdRoles.find(cpdRole => {return cpdRole.id == id});
        if(value) {
            setLoading(true);
            setCpdRole(value);
            getTariff(value);
        }
    }

    const inputs = [
        {
            label:'Username',
            type:'text', 
            name:'username',
            disabled:true,
            value:user && user.username?user.username:'',   
            placeholder:'Enter username...'
        },
        {
            label:'CPD Name',
            type:'text', 
            name:'cpdName',
            disabled:true,
            value:cpd && cpd.fullname?cpd.fullname:'',   
            placeholder:'Enter fullname...'
        },
        {
            label:'CPD Role',
            type:'select',
            options:() => {
                let options = [];
                cpdRoles && cpdRoles.map((option,i) => options.push(<option key={i} value={option.id}>{option.name}</option>));
                return options;
            },
            name:'cpdRole', 
            disabled: !(cpdRoles && cpdRoles.length > 0),
            value: cpdRole && cpdRole.id?cpdRole.id:'',
            placeholder:'CPD Role...',
            onChange: onChange
        },
        {
            label:'Price',
            type:'text', 
            name:'price',
            disabled:true,
            value: tariff && tariff.price?tariff.price:'0'
        }
    ]

    useEffect(() => {
        ( async () => {
            setLoading(true);
            await CPDRoleService.getCPDRoles()
            .then((response) => {
                setLoading(false);
                if(response.content) {
                    setCpdRoles(response.content);
                    getTariff(cpdRole?cpdRole:response.content[0]);
                }  else {
                    setCpdRoles([]);
                }
            })
            .catch((error) => {
                setLoading(false);
                setCpdRoles([]);
            })
            getTariff(cpdRole?cpdRole:cpdRoles)
        }
        )();
    },[]);

  return (
    <FormDialog title='CPD Role'>
        <div className='flex flex-col p-8 items-center'>
            <Inputs inputs={inputs} minWidth={minWidth} paddingX={0} spaceX={32} id='roleSelector' setCalcWidth={setInputWidth}/>
            <Message message={message}/>
            <button style={{'--width':inputWidth+'px'}} 
                onClick={handleContinue} className='flex shrink-0 w-[var(--width)] h-10 mx-auto rounded-lg items-center justify-center bg-[rgb(0,175,240)] hover:bg-[rgba(0,175,240,.7)] text-white'>
                Continue
            </button>
        </div>
    </FormDialog>
  )
}

export default CPDRoleSelector