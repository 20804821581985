import React,{useEffect,useRef} from 'react'
import DropDownItem from './DropDownItem' ;


const SubMenu = ({show,setShow,showSubMenu,setShowSubMenu,subMenu,setSubMenu}) => {
    const subMenuRef = useRef(null);
    const handleMouseLeave = (e) => {
        setShowSubMenu(false);
    }
    useEffect(() => { 
        if(showSubMenu) {
            if(subMenuRef.current) {
                let width = 240;
                let rect = subMenuRef.current.parentElement.getBoundingClientRect();
                if((rect.right + width) > document.documentElement.clientWidth) {
                    subMenuRef.current.style.left = (rect.left-width)+'px';
                } else {
                    subMenuRef.current.style.left = rect.right+'px';
                }
                subMenuRef.current.style.width = width+'px'
                subMenuRef.current.style.top = rect.top+'px';
                subMenuRef.current.style.visibility = 'visible';
                subMenuRef.current.style.opacity = 1;
                subMenuRef.current.animate({opacity:[0,1]},{duration:500});
                
            }
        } else {
            if(subMenuRef.current) {
                subMenuRef.current.animate({opacity:[1,0]},{duration:500}).addEventListener('finish',() => {
                    if(subMenuRef.current) {
                        subMenuRef.current.style.visibility = 'hidden';
                    }
                    setSubMenu(null);
                });
            };
        }
    },[subMenu,showSubMenu]);
  return (
    <div id={subMenu.id} 
        ref={subMenuRef}
        onMouseLeave={(e) => handleMouseLeave(e)} 
        className='fixed flex flex-col space-y-4 max-w-sm h-auto py-6 shrink-0 invisible opacity-0 z-30 bg-[rgb(238,238,238)] shadow-md overflow-hidden'>
        {subMenu.menus.map((menu,i) => <DropDownItem key={i} id={menu.link} link={menu.link} name={menu.name} menus={menu.menus} show={show} setShow={setShow}/>)}
    </div>
  )
}

export default SubMenu