import React,{useContext} from 'react'
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate } from 'react-router-dom';
import RoleService from '../services/RoleService'
import Table from './Table';
import YesNoDialog from './YesNoDialog';
import { TbShield ,TbShieldPlus ,TbShieldX } from "react-icons/tb";
import AddRole from './AddRole';
import Role from './Role';

const Roles = () => {
    const {setDialog,hasAuthority,setLoading,setReloadUser} = useContext(GlobalContext);

    const navigate = useNavigate();

    const columns = [
        {
            name:'name',
            label:'Name',
            type:'text',
            filter:''
        },
        {
            name:'displayName',
            label:'Display Name',
            type:'text',
            filter: ''
        },
        {
            name:'description',
            label:'Description',
            type:'text',
            filter: ''
        }
    ]

    const handleDelete = async (id,setMessage,reload) => {
        setLoading(true);
        setMessage({content:'',success:false});
        await RoleService.deleteRole(id,false)
        .then((response) => {
            setLoading(false);
            if(response.status) {
                if(response.status && response.status === 'SUCCESSFUL' && response.message) {
                    setMessage({content:response.message,success:true});
                    reload();
                }else if(response.message) {
                    setMessage({content:response.message,success:false});
                }
            } else {
                setMessage({content:response,success:false});
            }
        })
        .catch((error) => {
            setLoading(false);
            setMessage({content:error.message,success:false});
        })
        setReloadUser({reload:true});
    }

    const getRoles = async (filter,page) => {
        let object = {}
        await RoleService.getRoles(page,false)
        .then((response) => {
            if(response.content) {
                object = {
                    status:'SUCCESSFUL',
                    content:response.content
                }
            } else {
                console.log(response);
                object = {
                    status:'FAILED',
                    content:[]
                };
            }
        })
        .catch((error) => {
            console.log(error.message);
            object = {
                status:'ERROR',
                content:[]
            };
        });
        return object;
    }

    const getButtons = (user,reload,setMessage) => {
        let buttons = [];
        buttons.push({
            name:'Open Role',
            onClick:(e,selected) => {
                if(selected) {
                    navigate(`/settings/roles/${selected.id}`)
                }
            },
            disabled:(selected) => {
                return selected?false:true;
            },
            Icon:TbShield
        });
        if(hasAuthority(user,'CREATE_ROLE')) {
            buttons.push(
            {
                name:'Add Role',
                disabled:(selected) => {
                    return false;
                },
                onClick:(e) => setDialog({
                    show:true,
                    Component:() =>  <AddRole reloadTable={reload}/>
                }),
                Icon:TbShieldPlus
            });
        }  
        if(hasAuthority(user,'DELETE_ROLE')) {
            buttons.push({
                name:'Delete Role',
                onClick:(e,selected) => {
                    if(selected) {
                        setDialog({
                            show:true,
                            Component:() => 
                                <YesNoDialog 
                                    title='Delete Role' 
                                    message={`Are you sure you want to permanently delete '${selected.name}' from the database?`} 
                                    onYes={async (e) => {
                                        await handleDelete(selected.id,setMessage,reload);
                                    }}
                                />
                        })
                    }
                }, 
                disabled:(selected) => {
                    return selected?false:true;
                },
                Icon:TbShieldX
            });
        }  
        return buttons;
    }

  return (
    <div className='relative flex w-full h-full'>
        <div className='flex w-full h-full no-scrollbar overflow-y-auto'>
            <Table columns={columns} retrieveRecords={getRoles} getButtons={getButtons}/>
        </div>
    </div>
  )
}

export default Roles