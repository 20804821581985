import React from 'react'
import { FaRegCircleDot } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";

const ProfessionalPractice = () => {
  return (
    <div className='flex flex-col space-y-16 w-full h-auto'>
        <div className='flex flex-col space-y-8 w-full h-auto'>
            <p className='flex w-full h-auto font-semibold font-leBeauneNew text-[22px] lg:text-4xl text-[rgb(59,59,59)]'>
                Scope of Practice
            </p>
            <p className='w-full h-auto text-lg text-[rgb(100,100,100)] font-jostBook'>
                Patients in Primary Health care facilities deserve care led by Clinical practioners—the most highly educated, 
                trained and skilled health care professionals at such levels. Through research, advocacy and education, the 
                COAZ vigorously defends the Clinical practice against scope of practice expansions that threaten patient safety
            </p>
        </div>
        <div className='flex flex-col space-y-8 w-full h-auto'>
            <p className='flex w-full h-auto font-semibold font-leBeauneNew text-[22px] lg:text-4xl text-[rgb(59,59,59)]'>
                Clinicians' vs Non-Clinicians 
            </p>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    Non-clinical providers such as nurse practitioners and community health assistants are an essential part 
                    of the Clinical-led care team and can help health care system and clinical practices deliver high-quality 
                    care
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    But it is easier than ever for patients and the public to get confused about who does what in Zambia 
                    health care, and mixed up about what distinguishes the training and skill of Clinicians from those of 
                    other health professionals
                </p>
            </div>
        </div>
        <div className='flex flex-col space-y-8 w-full h-auto'>
            <p className='flex w-full h-auto font-semibold font-leBeauneNew text-[22px] lg:text-4xl text-[rgb(59,59,59)]'>
                What’s at stake 
            </p>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    There is room for improvement in the Zambia health system but allowing non-clinicians such as nurse 
                    practitioners (NPs) or Community health practitioners to diagnose and treat patients, especially at 
                    primary health care levels and secondary health care levels without any physician oversight is a step in 
                    the wrong direction. The best way to support high-quality care and lower costs is to keep clinicians as 
                    the leader of the health care teams
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    There is substantive evidence is diagnostic gaps. The University teaching Hospital – cancer diseases 
                    Hospital, in 2023 presented that 63% of all cancer cases received at CDH are in 63%. This shows that 
                    there is a GAP in diagnostics in Primary care sites
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    Zambian people in underserved areas deserve better health care services. COAZ is advocating for Changes 
                    in the Human resources Establishment at all primary health care and secondary health care levels to 
                    reflect the current health care needs, especially in clinical care services
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    Clinicians are highly trained in diagnostics. Nurse practitioners and axillary providers have a limited 
                    scope to provide comprehensive care services. They require clinical guidance from Clinicians
                </p>
            </div>
        </div>
        <div className='flex flex-col space-y-8 w-full h-auto'>
            <p className='flex w-full h-auto font-semibold font-leBeauneNew text-[22px] lg:text-4xl text-[rgb(59,59,59)]'>
                The COAZ position 
            </p>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    Patients deserve care led by Clinicians. The most highly educated, trained and skilled health care 
                    professionals. That’s why the COAZ vigorously defends clinical care practice against scope-of-practice 
                    expansions that threaten patient safety, and it’s why fighting scope creep has become a critical 
                    component of our Health services delivery advocacy plan
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    In addition, COAZ  is aiming at Truth in Advertising campaign, that help ensure patients can answer the 
                    simple question, “Who is a Clinical Officer?”, COAZ believes that all health care professionals. 
                    Clinicians and non-Clinical Practitioners—should be required to accurately and clearly disclose their 
                    training and qualifications to patients
                </p>
            </div>
        </div>
        <div className='flex flex-col space-y-8 w-full h-auto'>
            <p className='flex w-full h-auto font-semibold font-leBeauneNew text-[22px] lg:text-4xl text-[rgb(59,59,59)]'>
                COAZ advocacy in action 
            </p>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    In 2023, through Ministry of Health; changed policy on regulation of Clinical Anaesthetist and 
                    ophthalmic practitioners in Zambia
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    COAZ argued that Nursing and Mid-wifery council has no capacity and legal mandate to regulate the 
                    practice or provide monitoring in training. We stressed that only the health professions council of 
                    Zambia had such legal mandate
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    Saw a change in regulatory mandate of the two professions given to HPCZ as submitted by the 
                    association
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    Submitted regulatory documents for opening registers for BSc in clinical Medicine programs: mental 
                    health, Ophthalmology, Anaesthesia
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    Advocated for creation and funding of New positions for clinical officers including senior posts such as 
                    Principal (H) and clinical care (K) positions
                </p>
            </div>
        </div>
        <div className='flex flex-col space-y-8 w-full h-auto'>
            <p className='flex w-full h-auto font-semibold font-leBeauneNew text-[22px] lg:text-4xl text-[rgb(59,59,59)]'>
                The COAZ is pushing 
            </p>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    Pushing the Ministry of Health and HPCZ to reject implementation of inappropriate scope of practice 
                    expansions
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    The ministry of health to develop the <strong>National standards of practice</strong> for all Health care providers in 
                    Zambia
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    For an annual publishing of a <strong>Health workforce Mapping data</strong> by the Ministry of Health to Health in 
                    Health workforce distribution planning and recruitment
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    Review current scope of practice for non-clinician practioners and state boundaries
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    Establishing degree salaries for members holding bachelors degrees and more
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    Training of MSc programs in Critical areas such as Dermatology, Cancer disease, ENT, Anaesthesia etc
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    Creation of Bridging Programs for Medical Licentiates into MBChB and advancing into MSc Clinical Medicine programs
                </p>
            </div>
        </div>
    </div>
  )
}

export default ProfessionalPractice