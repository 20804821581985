import React, {useEffect,useState,useContext,useRef} from 'react'
import { GlobalContext } from '../contexts/GlobalContext';
import AccountService from '../services/AccountService';
import UserService from '../services/UserService';
import ExpandableList from './ExpandableList';

const Account = ({id}) => {
    const {setReloadUser,setDialog,hasAuthority} = useContext(GlobalContext);
    const [user,setUser] = useState(null);
    const [account,setAccount] = useState(null);
    const eventRef = useRef(null);

    let USDecimal = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    useEffect(() => {
        ( async () => {
            await UserService.getCurrentUser()
            .then((response) => {
                if(response.content) {
                    setUser(response.content);
                }  else {
                    setUser(null);
                }
                setReloadUser({reload:true});
            })
            .catch((error) => {
                console.log(error.message);
                setUser(null);
                setReloadUser({reload:true});
            })

            await AccountService.getAccount(id,false)
            .then((response) => {
                if(response.content) {
                    response.content.date = new Date(response.content.date);
                    setAccount(response.content);
                }  else {
                    setAccount(null);
                }
                setReloadUser({reload:true});
            })
            .catch((error) => {
                console.log(error.message);
                setAccount(null);
                setReloadUser({reload:true});
            })
        }
        )();
    },[])
  return (
    <div className='flex flex-col w-full h-auto overflow-hidden'>
        {account?
            <div className='flex flex-col w-full h-auto max-h-[80vh] py-8 items-center no-scrollbar overflow-x-hidden overflow-y-auto bg-white font-jostBook'>
                <div style={{boxShadow:'0 4px 20px 5px rgba(0, 0, 0, 0.05), 0 10px 20px 20px rgba(0, 0, 0, 0.03)'}} 
                    className='flex flex-col w-[90%] lg:w-3/4 h-auto bg-white rounded-md'>
                    <div className='flex flex-col w-full h-auto p-8'>
                        <p className='flex w-full h-auto pt-4 text-lg text-[rgb(59,59,59)] font-semibold font-leBeauneNew'>
                            {account.name}
                        </p>
                        <p className='flex w-full h-auto pt-4 lg:text-lg text-[rgb(0,175,240)] font-semibold font-leBeauneNew'>
                            ZMW {USDecimal.format(account.balance)}
                        </p>
                    </div>
                    <div className='flex flex-col space-y-8 w-full h-auto px-8 py-16 shrink-0 bg-[rgb(243,244,245)]'>
                        <ExpandableList name='Entries'>
                            {account.entries && account.entries.map((entry,i) => 
                                <div key={i} className='flex flex-row w-full h-auto space-x-4'>
                                    <p className='w-1/3 text-sm tracking-wider font-jostBook text-[rgb(85,85,85)] whitespace-nowrap overflow-hidden overflow-ellipsis capitalize'>
                                        {entry.transaction?entry.transaction.transactionId:''}
                                    </p>
                                    <p className='w-1/3 text-sm tracking-wider font-jostBook text-[rgb(85,85,85)] whitespace-nowrap overflow-hidden overflow-ellipsis capitalize'>
                                        {entry.entryType}
                                    </p>
                                    <p className='w-1/3 text-sm tracking-wider font-jostBook text-right text-[rgb(85,85,85)] whitespace-nowrap overflow-hidden overflow-ellipsis capitalize'>
                                        {entry.transaction?USDecimal.format(entry.transaction.amount):''}
                                    </p>
                                </div>)
                            }
                        </ExpandableList>
                    </div>
                </div>
            </div> :
            <div>
                
            </div>
        }
    </div>
  )
}

export default Account