import React,{useEffect,useState} from 'react'
import { useNavigate } from 'react-router-dom';
import CPDService from '../services/CPDService'
import Table from './Table'
import { IoSchoolOutline } from "react-icons/io5";

const CPDs = ({id}) => {
    const [subDialog,setSubDialog] = useState(null);

    const navigate = useNavigate();

    const columns = [
        {
            name:'shortname',
            label:'Shortname',
            type:'text',
            filter: ''
        },
        {
            name:'fullname',
            label:'Fullname',
            type:'text',
            Filter: ''
        },
        {
            name:'idNumber',
            label:'Id Number',
            type:'text',
            Filter: ''
        },
        {
            name:'startDate',
            label:'Start Date',
            type:'date',
            filter:''
        }
    ]

    const getCPDs = async () => {
        let object = {}
        await CPDService.getCPDs(false)
        .then((response) => {
            if(response.content) {
                for(let cpd of response.content) {
                    cpd.startDate = new Date(cpd.startDate);
                }
                object = {status:'SUCCESSFUL',content:response.content?response.content:[]}
            } else {
                object = {status:'FAILED',content:[]};
            }
        })
        .catch((error) => {
            console.log(error.message);
            object = {status:'ERROR',content:[]};
        });
        return object;
    }

    const getButtons = (user) => {
        let buttons = [
            {
                name:'Open User',
                onClick:(e,selected) => {
                    if(selected) {
                        navigate(`/settings/cpds/${selected.id}`)
                    }
                },
                disabled:(selected) => {
                    return selected?false:true;
                },
                Icon:IoSchoolOutline
            }
        ];   
        return buttons;
    }
  return (
    <div className='relative flex w-full h-full'>
        <div className='flex w-full h-full no-scrollbar overflow-y-auto'>
            <Table columns={columns} retrieveRecords={getCPDs} getButtons={getButtons}/>
        </div>
    </div>
  )
}

export default CPDs