import React, {useEffect,useState, useContext} from 'react';
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate } from 'react-router-dom';
import EventService from '../services/EventService';
import RoleService from '../services/RoleService';
import FormValidator, {useFormValidator} from './FormValidator';
import { IoCloseOutline } from "react-icons/io5";
import { BsCalendar4Event } from "react-icons/bs";
import { PiTrashLight,PiPlusSquareFill,PiMinusSquareFill,PiXSquareFill  } from "react-icons/pi";
import { LiaPlusSolid,LiaMinusSolid } from "react-icons/lia";
import { AiOutlineEdit } from "react-icons/ai";
import Input from './Input';
import Select from './Select';
import Checkbox from './Checkbox';
import Message from './Message';

const EditEvent = ({id,update}) => {
    const {setLoading,setDialog,setReloadUser} = useContext(GlobalContext);
    const [event,setEvent] = useState(null);
    const [addingTariff,setAddingTariff] = useState(false);
    const [editingTariff,setEditingTariff] = useState(false);
    const [newTariff,setNewTariff] = useState(null);
    const [editTariff,setEditTariff] = useState(null);
    const [selectedTariff,setSelectedTariff] = useState(null);
    const [tariffCriterias,setTariffCriterias] = useState([]);
    const [message,setMessage] = useState({content:'',success:false});

    const inputWidth = 315;

    let USDecimal = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const onChange = (e) => {
        const value = e.target.value;
        if(value === '') {
            setEvent({...event, [e.target.name]: null});
        } else {
            setEvent({...event, [e.target.name]: value});
        }
    }

    const onDate = (e) => {
        const value = e.target.value;
        setEvent({...event, [e.target.name]: new Date(value)});
    };


    const navigate = useNavigate();

    const submit = async (e) => {
        setMessage({content:'',success:false});
        setLoading(true);
        EventService.updateEvent(event)
        .then((response) => {
            setLoading(false);
            if(response.status) {
                if(response.status === 'SUCCESSFUL' && response.content) {
                    response.content.date = new Date(response.content.date);
                    update(response.content);
                    setDialog(null);
                } else {
                    setMessage({content:response.message,success:false});
                }
            } else  {
                setMessage({content:response,success:false});
            }
        })
        .catch((error) => {
            setMessage({content:error.message,success:false});
            setLoading(false);
        });
    };

    const [register,handleChange,handleSubmit,errors] = useFormValidator(submit);

    useEffect(() => {
        ( async () => {
            setLoading(true);
            await EventService.getEvent(id)
            .then((response) => {
                setLoading(false);
                if(response.content) {
                    response.content.date = new Date(response.content.date);
                    if(!response.content.defaultTariff && response.content.tariffs && response.content.tariffs.length > 0) {
                        response.content.defaultTariff = response.content.tariffs[0];
                    }
                    setEvent(response.content);
                }  else {
                    setEvent(null);
                    setDialog(null);
                }
                setReloadUser({reload:true});
            })
            .catch((error) => {
                console.log(error.message);
                setLoading(false);
                setEvent(null);
                setDialog(null);
                setReloadUser({reload:true});
            })
            
            await RoleService.getRoles()
            .then((response) => {
                setLoading(false);
                if(response.content) {
                    setTariffCriterias(response.content);
                }  else {
                    setTariffCriterias([]);
                }
            })
            .catch((error) => {
                console.log(error.message);
                setTariffCriterias(null);
            })
        }
        )();
    },[]);

    return (
        <div className='flex flex-col w-full lg:w-[60vw] h-auto shadow-2xl bg-[rgb(252,252,252)]'>
            <div className='flex flex-row w-full items-center h-10 pl-4 pr-4 shrink-0 text-[rgb(150,150,150)] text-sm font-jostBook tracking-wider bg-[rgb(247,247,247)]'>
                <div className='flex flex-row space-x-2 items-center w-full'>
                    <p>Edit Event</p>
                </div>
                <button onClick={(e) => setDialog(null)} className='flex w-6 h-6 shrink-0 hover:bg-[rgb(235,235,235)]'>
                    <IoCloseOutline size={24}/>
                </button>
            </div>
            <BsCalendar4Event size={64} className='flex mx-auto my-2 shrink-0  text-[rgba(84,84,87,.7)]'/>
            <FormValidator>
                <div className='flex flex-col w-full lg:w-auto h-[500px] py-8 justify-center  m-auto'>
                    <div className='flex flex-col w-full h-full space-y-4 no-scrollbar overflow-auto'>
                        <div className='flex flex-col lg:flex-row w-auto h-auto space-y-4 lg:space-y-0 lg:space-x-4'>
                            <Input 
                                label="name"
                                type="text" 
                                id="name" 
                                name="name"  
                                value={event && event.name?event.name:''}
                                placeholder="Enter name..."
                                onChange= {(e) => handleChange(e,onChange)}
                                register={register}
                                errors={errors}
                                width={inputWidth}
                            />
                            <Input 
                                label="Description"
                                type="text"
                                id="description" 
                                name="description"  
                                value={event && event.description?event.description:''}
                                placeholder="Enter description..."
                                onChange= {(e) => handleChange(e,onChange)}
                                width={inputWidth}
                            />
                        </div>
                        <div className='flex flex-col lg:flex-row w-auto h-auto space-y-4 lg:space-y-0 lg:space-x-4'>
                            <Input 
                                label="Date"
                                type="date"
                                id="date" 
                                name="date"  
                                value={event?event.date.toISOString().slice(0, 10):''}
                                onChange= {(e) => handleChange(e,onDate)}
                                register={register}
                                errors={errors}
                                width={inputWidth}
                            />
                            <Input 
                                label="Venue"
                                type="text"
                                id="venue" 
                                name="venue"  
                                value={event?event.venue:''}
                                placeholder="Enter description..."
                                onChange= {(e) => handleChange(e,onChange)}
                                width={inputWidth}
                            />
                        </div>
                        <div className='flex flex-col lg:flex-row w-auto h-auto space-y-4 lg:space-y-0 lg:space-x-4'>
                            <div style={{width:inputWidth+'px'}} 
                                className='flex flex-col h-auto space-y-2'>
                                <label className='text-gray-600 text-sm capitalize'>Start</label>
                                <div className='flex flex-row w-full h-auto items-center space-x-2'>
                                    <input
                                        type='number'
                                        id='startHour'
                                        name='startHour'
                                        list='hours'
                                        min={0}
                                        max={23}
                                        placeholder='Hr'
                                        value={event?event.startHour:''}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            if(value < 24) {
                                                setEvent({...event,startHour:value});
                                            }
                                        }}
                                        className='flex w-20 h-10 shrink-0 p-2 focus:outline-none font-thin text-sm whitespace-nowrap bg-transparent border rounded-lg' 
                                    />
                                    <datalist id='hours'>
                                        {(() => {
                                            let hours = [];
                                            for(let i = 0;i < 24;i++) {
                                                hours.push(<option>{i}</option>);
                                            }
                                            return hours
                                        })()}
                                    </datalist>
                                    <p className='font-thin text-sm'>:</p>
                                    <input
                                        type='number'
                                        id='startMin'
                                        name='startMin'
                                        list='mins'
                                        min={0}
                                        max={23}
                                        placeholder='Min'
                                        value={event?event.startMin:''}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            if(value < 60) {
                                                setEvent({...event,startMin:value});
                                            }
                                        }}
                                        className='flex w-20 h-10 shrink-0 p-2 focus:outline-none font-thin text-sm whitespace-nowrap bg-transparent border rounded-lg' 
                                    />
                                    <datalist id='mins'>
                                        {(() => {
                                            let mins = [];
                                            for(let i = 0;i < 60;i++) {
                                                mins.push(<option>{i}</option>);
                                            }
                                            return mins
                                        })()}
                                    </datalist>
                                </div>
                            </div>
                            <div style={{width:inputWidth+'px'}} 
                                className='flex flex-col h-auto space-y-2'>
                                <label className='text-gray-600 text-sm capitalize'>End</label>
                                <div className='flex flex-row w-full h-auto items-center space-x-2'>
                                    <input
                                        type='number'
                                        id='endHour'
                                        name='endHour'
                                        list='hours'
                                        min={0}
                                        max={23}
                                        placeholder='Hr'
                                        value={event?event.endHour:''}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            if(value < 24) {
                                                setEvent({...event,endHour:value});
                                            }
                                        }}
                                        className='flex w-20 h-10 shrink-0 p-2 focus:outline-none font-thin text-sm whitespace-nowrap bg-transparent border rounded-lg' 
                                    />
                                    <datalist id='hours'>
                                        {(() => {
                                            let hours = [];
                                            for(let i = 0;i < 24;i++) {
                                                hours.push(<option>{i}</option>);
                                            }
                                            return hours
                                        })()}
                                    </datalist>
                                    <p className='font-thin text-sm'>:</p>
                                    <input
                                        type='number'
                                        id='endMin'
                                        name='endMin'
                                        list='mins'
                                        min={0}
                                        max={23}
                                        placeholder='Min'
                                        value={event?event.endMin:''}
                                        onChange={(e) => {
                                            let value = e.target.value;
                                            if(value < 60) {
                                                setEvent({...event,endMin:value});
                                            }
                                        }}
                                        className='flex w-20 h-10 shrink-0 p-2 focus:outline-none font-thin text-sm whitespace-nowrap bg-transparent border rounded-lg' 
                                    />
                                    <datalist id='mins'>
                                        {(() => {
                                            let mins = [];
                                            for(let i = 0;i < 60;i++) {
                                                mins.push(<option>{i}</option>);
                                            }
                                            return mins
                                        })()}
                                    </datalist>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col lg:flex-row w-auto h-auto space-y-4 lg:space-y-0 lg:space-x-4'>
                            <Checkbox 
                                name='Tariff Applicable'
                                width={inputWidth} 
                                checked={event?event.tariffApplicable:''} 
                                disabled={false}
                                onClick={(e) => {
                                    setEvent({...event,tariffApplicable: !event.tariffApplicable});
                                }}
                            />
                            <Select 
                                label="Default Tariff"
                                type="text"
                                id="defaultTariff" 
                                name="defaultTariff"  
                                value={event && event.defaultTariff?event.defaultTariff.name:''}
                                onChange= {(e) => {
                                    if(event && event.tariffs) {
                                        let value = event.tariffs.find(tariff => {return tariff.name == e.target.value});
                                        if(value) {
                                            setEvent({...event,defaultTariff:value});
                                        }
                                    }
                                }}
                                width={inputWidth}
                            >
                                {event && event.tariffs && event.tariffs.map((tariff,i) => 
                                    <option key={i} value={tariff.name}>{tariff.name}</option>
                                )
                                }
                            </Select>
                        </div>
                        <div className='flex flex-col w-full h-auto space-y-2'>
                            <p className='text-gray-600 text-sm capitalize'>Tariffs</p>
                            <div className='flex flex-col w-full h-auto p-4 space-y-4 border rounded-lg'>
                                {event && event.tariffs && event.tariffs.map((tariff,i) => 
                                   <div key={i} 
                                        onClick={(e) => {
                                           setSelectedTariff(tariff); 
                                        }}
                                        className='flex flex-row space-x-4'>
                                        <div className={`flex flex-row w-full h-auto text-sm tracking-wider font-jostBook ${selectedTariff && selectedTariff.name === tariff.name?'text-[rgb(0,175,240)]':''} cursor-pointer`}>
                                            <p className='w-1/3 whitespace-nowrap overflow-ellipsis capitalize'>
                                                {tariff.name}
                                            </p>
                                            <p className='w-1/3 whitespace-nowrap overflow-ellipsis capitalize'>
                                                {tariff.description}
                                            </p>
                                            <p className='w-1/3 text-right whitespace-nowrap overflow-ellipsis capitalize'>
                                                {USDecimal.format(tariff.price)}
                                            </p>
                                        </div>
                                    </div>)
                                }
                            </div>
                            <div className='flex flex-row w-auto h-auto space-x-4'>
                                <button onClick={(e) => {
                                        setAddingTariff(!addingTariff);
                                        setNewTariff(null);
                                        setSelectedTariff(null);
                                        setEditingTariff(null);
                                    }} 
                                    className={`flex w-6 h-6 items-center justify-center ${addingTariff?'border border-[rgb(0,175,240)] text-[rgb(0,175,240)]':'bg-[rgb(0,175,240)] text-white hover:bg-[rgba(0,175,240,.7)]'}  rounded-sm`}>
                                    <LiaPlusSolid size={16}/>
                                </button>
                                <button onClick={(e) => {
                                        setEditingTariff(!editingTariff);
                                        setAddingTariff(false);
                                        setNewTariff(null);
                                        setEditTariff(selectedTariff);
                                    }} 
                                    disabled={!selectedTariff}
                                    className={`flex w-6 h-6 items-center justify-center ${editingTariff?'border border-[rgb(0,175,240)] text-[rgb(0,175,240)]':selectedTariff?'bg-[rgb(0,175,240)] text-white hover:bg-[rgba(0,175,240,.7)]':'text-white bg-[rgba(0,175,240,.7)]'}  rounded-sm`}>
                                    <AiOutlineEdit size={16}/>
                                </button>
                                <button onClick={(e) => {
                                        let tariffs = event.tariffs.filter((tariff => tariff.name !== selectedTariff.name));
                                        setEvent({...event,tariffs:tariffs});
                                        setSelectedTariff(null);
                                    }} 
                                    disabled={!selectedTariff}
                                    className={`flex w-6 h-6 items-center justify-center ${selectedTariff?'bg-[rgb(0,175,240)] hover:bg-[rgba(0,175,240,.7)]':'bg-[rgba(0,175,240,.7)]'} text-white rounded-sm`}>
                                    <PiTrashLight size={16}/>
                                </button>
                            </div>
                            <div style={{transition:'all .5s ease-in-out'}}
                                onKeyDown={(e) => {
                                        setMessage({content:'',success:false});
                                        if (addingTariff && e.key === "Enter") {
                                            if(!newTariff || !newTariff.name || newTariff.name === '' ||
                                                !newTariff.price || newTariff.price === ''
                                            ) {
                                                setMessage({content:'Missing tariff fields',success:false});
                                            } else {
                                                let duplicate = event.tariffs.find(tariff => {return tariff.name == newTariff.name})
                                                if(!duplicate) {
                                                    event.tariffs.push(newTariff);
                                                    setEvent({...event,tariffs:event.tariffs});
                                                    setNewTariff(null);
                                                    setSelectedTariff(null);
                                                    setAddingTariff(false);
                                                }
                                            }
                                        }
                                        if (editingTariff && e.key === "Enter") {
                                            if(!editTariff || !editTariff.name || editTariff.name === '' ||
                                                !editTariff.price || editTariff.price === ''
                                            ) {
                                                alert(JSON.stringify(editTariff));
                                                setMessage({content:'Missing tariff fields',success:false});
                                            } else {
                                                if(!editTariff.id) {
                                                    setMessage({content:'Tariff has to be saved first',success:false}); 
                                                } else {
                                                    let duplicate = event.tariffs.find(tariff => {return tariff.name == editTariff.name})
                                                    if(!duplicate || (duplicate.id && duplicate.id === editTariff.id)) {
                                                        let tariffs = event.tariffs.filter(tariff => tariff.id !== editTariff.id);
                                                        tariffs.push(editTariff)
                                                        setEvent({...event,tariffs:tariffs});
                                                        setEditTariff(null);
                                                        setSelectedTariff(null);
                                                        setEditingTariff(false);
                                                    }
                                                }
                                            }
                                        }
                                        
                                    }
                                }
                                className={`flex flex-col w-auto ${addingTariff || editingTariff?'h-[340px] border-t':'h-0'} pt-1 space-y-4 overflow-hidden`}>
                                <Input 
                                    label="Name"
                                    type="text"
                                    id="tariffName" 
                                    name="tariffName"  
                                    value={addingTariff?newTariff && newTariff.name?newTariff.name:'':editingTariff?editTariff && editTariff.name?editTariff.name:'':''}
                                    onChange= {(e) => {
                                        if(addingTariff) {
                                            setNewTariff({...newTariff,name:e.target.value})
                                        } else if(editingTariff) {
                                            setEditTariff({...editTariff,name:e.target.value});
                                        }
                                    }}
                                    width={inputWidth}
                                />
                                <Input 
                                    label="Description"
                                    type="text"
                                    id="tariffDescription" 
                                    name="tariffDescription"  
                                    value={addingTariff?newTariff && newTariff.description?newTariff.description:'':editingTariff?editTariff && editTariff.description?editTariff.description:'':''}
                                    onChange= {(e) => {
                                        if(addingTariff) {
                                            setNewTariff({...newTariff,description:e.target.value});
                                        } else if(editingTariff) {
                                            setEditTariff({...editTariff,description:e.target.value});
                                        }
                                    }}
                                    width={inputWidth}
                                />
                                <Input 
                                    label='Price'
                                    type='text'
                                    inputMode='numeric'
                                    id='price' 
                                    name='price'  
                                    value={addingTariff?newTariff && newTariff.price?newTariff.price:'':editingTariff?editTariff && editTariff.price?editTariff.price:'':''}
                                    onChange= {(e) => {
                                        let value = e.target.value;
                                        if(isNaN(value)) {
                                            return;
                                        }
                                        if(addingTariff) {
                                            setNewTariff({...newTariff,price:value});
                                        } else if(editingTariff) {
                                            setEditTariff({...editTariff,price:value});
                                        }
                                    }}
                                    width={inputWidth}
                                />
                                <Select 
                                    label="Criteria"
                                    type="text"
                                    id="criteria" 
                                    name="criteria"  
                                    value={addingTariff?newTariff && newTariff.criteria && newTariff.criteria.name?newTariff.criteria.name:'':editingTariff?editTariff && editTariff.criteria && editTariff.criteria.name?editTariff.criteria.name:'':''}
                                    onChange= {(e) => {
                                        if(addingTariff) {
                                            if(newTariff && tariffCriterias) {
                                                let value = tariffCriterias.find(criteria => {return criteria.name == e.target.value});
                                                if(value) {
                                                    setNewTariff({...newTariff,criteria:value});
                                                }
                                            }
                                        } else if(editingTariff) {
                                            if(editTariff && tariffCriterias) {
                                                let value = tariffCriterias.find(criteria => {return criteria.name == e.target.value});
                                                if(value) {
                                                    setEditTariff({...editTariff,criteria:value});
                                                }
                                            }
                                        }
                                    }}
                                    width={inputWidth}
                                >
                                    {tariffCriterias && tariffCriterias.map((criteria,i) => 
                                        <option key={i} value={criteria.name}>{criteria.name}</option>
                                    )
                                    }
                                </Select>
                            </div>
                        </div>
                        <Message message={message}/>
                        <button style={{'--width':inputWidth+'px'}} 
                            onClick={handleSubmit} className='flex shrink-0 w-full lg:w-[var(--width)] h-10 mx-auto rounded-lg items-center justify-center bg-[rgb(0,175,240)] hover:bg-[rgba(0,175,240,.7)] text-white'>
                            Submit
                        </button>
                    </div>
                </div>
            </FormValidator>
        </div>
      )
}

export default EditEvent