import React, {useEffect,useState,useContext,useRef} from 'react'
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate } from 'react-router-dom';
import CPDService from '../services/CPDService';
import UserService from '../services/UserService';
import UserCPDRoleService from '../services/UserCPDRoleService'
import EditCPD from './EditCPD';
import ExpandableList from './ExpandableList';
import { LiaMoneyBillWaveSolid ,LiaUserTieSolid,LiaUsersSolid,LiaBuilding,LiaClockSolid } from "react-icons/lia";
import { PiMoneyLight} from "react-icons/pi";
import { AiOutlineEdit } from "react-icons/ai";
import { SlLocationPin } from "react-icons/sl";
import { TbShield,TbUser} from "react-icons/tb";

const CPD = ({id}) => {
    const {setReloadUser,setDialog,hasAuthority} = useContext(GlobalContext);
    const [user,setUser] = useState(null);
    const [cpd,setCpd] = useState(null);
    const [userCpdRoles,setUserCpdRoles] = useState([]);
    const [imageSize,setImageSize] = useState({width:0,height:0});
    const cpdRef = useRef(null);

    let USDecimal = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const getUserCPDRoles = async (cpd) => {
        if(!cpd) {
            return;
        }
        await UserCPDRoleService.getUserCPDRolesCPD(cpd.id)
        .then(async (response) => {
            if(response.content) {
                console.log(response.content);
                setUserCpdRoles(response.content);
            }  else {
                setUserCpdRoles([]);
            }
        })
        .catch((error) => {
            alert('no');
            setUserCpdRoles([]);
        })
    }
 
    useEffect(() => {
        ( async () => {
            await UserService.getCurrentUser()
            .then((response) => {
                if(response.content) {
                    setUser(response.content);
                }  else {
                    setUser(null);
                }
                setReloadUser({reload:true});
            })
            .catch((error) => {
                console.log(error.message);
                setUser(null);
                setReloadUser({reload:true});
            })
        }
        )();
        ( async () => {
            await CPDService.getCPD(id)
            .then(async (response) => {
                if(response.content) {
                    response.content.startDate = new Date(response.content.startDate);
                    setCpd(response.content);
                    getUserCPDRoles(response.content);
                }  else {
                    setCpd(null);
                }
                setReloadUser({reload:true});
            })
            .catch((error) => {
                console.log(error.message);
                setCpd(null);
                setReloadUser({reload:true});
            })
        }
        )();

        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                let width = entry.target.getBoundingClientRect().width;
                let height = (width * 9)/16;
                setImageSize({width:width,height:height});
            }
        });

        if(cpdRef.current) {
            observer.observe(cpdRef.current);
        }
        return () => {
            observer.disconnect();
        }
    },[])
  return (
    <div className='flex flex-col w-full h-auto overflow-hidden'>
        {cpd?
            <div className='flex flex-col w-full h-auto max-h-[80vh] py-8 items-center no-scrollbar overflow-x-hidden overflow-y-auto bg-white font-jostBook'>
                <div ref={cpdRef} style={{boxShadow:'0 4px 20px 5px rgba(0, 0, 0, 0.05), 0 10px 20px 20px rgba(0, 0, 0, 0.03)'}} 
                    className='flex flex-col w-[90%] lg:w-3/4 h-auto bg-white rounded-md'>
                    <div className='flex flex-col w-full h-auto p-8'>
                        {cpd.startDate &&
                            <p className='text-sm text-[rgb(180,180,180)] font-jostSemi tracking-wide'>
                                {cpd.startDate.toLocaleString('default', { month: 'long' })+' '+cpd.startDate.getDate()+', '+cpd.startDate.getFullYear()}
                            </p>
                        }
                        {cpd.shortname &&
                            <p className='flex w-full h-auto pt-4 text-2xl text-[rgb(59,59,59)] font-semibold font-leBeauneNew'>
                                {cpd.shortname}
                            </p>
                        }
                        {cpd.fullname &&
                            <p className='flex w-full text-lg text-[rgb(100,100,100)] font-jostMedium capitalize'>
                                 {cpd.fullname}
                            </p>
                        }
                        <div className='flex flex-col space-y-2 w-auto h-auto mt-8'>
                            {user && hasAuthority(user,'UPDATE_CPD') && 
                            <button 
                                onClick={(e) => {
                                    e.preventDefault();
                                    setDialog({
                                        show:true,
                                        Component:() => 
                                            <EditCPD id={id} update={setCpd}/>
                                    })
                                }}
                                className='flex w-fit flex-row space-x-2 items-center text-sm shrink-0 text-[rgb(0,175,240)] font-jostSemi hover:underline'>
                                <AiOutlineEdit size={24}/>
                                <p>Edit</p>
                            </button>}
                        </div>
                    </div>
                    <div className='flex flex-col space-y-4 w-full h-auto px-8 py-16 shrink-0 bg-[rgb(243,244,245)]'>
                        <ExpandableList name='Tariffs'>
                            {cpd.tariffs && cpd.tariffs.map((tariff,i) => 
                                <div key={i} className={`flex flex-row w-full h-auto space-x-4 font-jostBook ${cpd.defaultTariff && cpd.defaultTariff.id == tariff.id?'text-[rgb(0,175,240)]':'text-[rgb(85,85,85)]'}`}>
                                    <LiaMoneyBillWaveSolid  size={20} className='flex m-auto shrink-0'/>
                                    <div className='flex flex-row w-full h-auto'>
                                        <p className='w-1/3 text-sm tracking-wider  whitespace-nowrap overflow-hidden overflow-ellipsis capitalize'>
                                            {tariff.name}
                                        </p>
                                        <p className='w-1/3 text-sm tracking-wider whitespace-nowrap overflow-hidden overflow-ellipsis capitalize'>
                                            {tariff.description}
                                        </p>
                                        <p className='w-1/3 text-sm text-right tracking-wider whitespace-nowrap overflow-hidden overflow-ellipsis capitalize'>
                                            {USDecimal.format(tariff.price)}
                                        </p>
                                    </div>
                                </div>)
                            }
                        </ExpandableList>
                        <ExpandableList name='Users'>
                            {userCpdRoles && userCpdRoles.map((userCpdRole,i) => 
                                <div key={i} className='flex flex-row w-full h-auto space-x-4 font-jostBook text-[rgb(85,85,85)]'>
                                    <TbUser  size={20} className='flex m-auto shrink-0'/>
                                    <div className='flex flex-row w-full h-auto'>
                                        {userCpdRole.user && userCpdRole.user.firstname && userCpdRole.user.lastname &&
                                            <p className='w-1/3 text-sm tracking-wider  whitespace-nowrap overflow-hidden overflow-ellipsis capitalize'>
                                                {`${userCpdRole.user.firstname} ${userCpdRole.user.lastname}`}
                                            </p>
                                        }
                                        {userCpdRole.user && userCpdRole.user.role && userCpdRole.user.role.displayName &&
                                            <p className='w-1/3 text-sm tracking-wider  whitespace-nowrap overflow-hidden overflow-ellipsis capitalize'>
                                                {userCpdRole.user.role.displayName}
                                            </p>
                                        }
                                        {userCpdRole.role && userCpdRole.role.name &&
                                            <p className='w-1/3 text-sm tracking-wider  whitespace-nowrap overflow-hidden overflow-ellipsis capitalize'>
                                                {userCpdRole.role.name}
                                            </p>
                                        }
                                    </div>
                                </div>)
                            }
                        </ExpandableList>
                    </div>
                </div>
            </div> :
            <div>
                
            </div>
        }
    </div>
  )
}

export default CPD