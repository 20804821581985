import React, {useState,useContext} from 'react';
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate,useParams } from 'react-router-dom';
import UserService from '../services/UserService'
import { ImUser } from "react-icons/im";
import Message from './Message';

const Confirm = () => {
    const {setLoading} = useContext(GlobalContext);
    const {username} = useParams();
    const [code, setCode] = useState(""); 
    const [message,setMessage] = useState({content:'',success:false}); 
    const navigate = useNavigate();

    const confirm = async (e) => {
        setLoading(true);
        e.preventDefault();
        UserService.confirm(username,code)
        .then((response) => {
            setLoading(false);
            if(response.status && response.status === 'SUCCESSFUL') {
                setMessage({content:response.message,success:true});
                navigate('/access/login');
            }  else if(response.message) {
                setMessage({content:response.message,success:false});
            } else {
                setMessage({content:response,success:false});
            }
        })
        .catch((error) => {
            setLoading(false);
            setMessage({content:error.message,success:false});
        })
    };

    const sendConfirmationCode = async (e) => {
        setLoading(true);
        e.preventDefault();
        UserService.sendConfirmationCode(username)
        .then((response) => {
            setLoading(false);
            if(response.status && response.status === 'SUCCESSFUL') {
                setMessage({content:response.message,success:true});
            }  else if(response.message) {
                setMessage({content:response.message,success:false});
            } else {
                setMessage({content:response,success:false});
            }
        })
        .catch((error) => {
            setLoading(false);
            setMessage({content:error.message,success:false});
        })
    };

  return (
    <div className='flex flex-col w-full h-full justify-center items-center m-auto rounded-r-xl overflow-hidden'>
        <ImUser size={64} className='flex mx-auto mb-4 text-[rgba(84,84,87,.7)]'/>
        <input 
            type="text" 
            name="username"
            value={username}
            disabled={true}
            placeholder="User name..."
            className='flex w-[315px] h-10 shrink-0 p-2 mb-4 focus:outline-none font-thin text-sm whitespace-nowrap bg-transparent border rounded-lg'
        />
        <input 
            type="text" 
            name="code"
            value={code}
            onChange={e => setCode(e.target.value)}
            placeholder='Confirmation code...'
            className='flex  w-[315px] h-10 shrink-0 p-2 focus:outline-none font-thin text-sm whitespace-nowrap bg-transparent border rounded-lg'
        />
        <Message message={message}/>
        <button onClick={(e) => sendConfirmationCode(e)} className='flex shrink-0  w-[315px] h-6 mb-4 items-center justify-center text-sm font-thin italic text-gray-600 hover:underline whitespace-nowrap overflow-ellipsis'>
            Resend confirmation code?
        </button>
        <button onClick={(e) => confirm(e)} className='flex shrink-0  w-[315px] h-10 rounded-lg items-center justify-center bg-[rgb(0,175,240)] hover:bg-[rgba(0,175,240,.7)] text-white'>
            Confirm
        </button>
    </div>
  )
}

export default Confirm