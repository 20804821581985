import React, {useState, useContext} from 'react';
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate } from 'react-router-dom';
import EventService from '../services/EventService';
import FormValidator, {useFormValidator} from './FormValidator';
import { IoCloseOutline } from "react-icons/io5";
import Scrollable from './Scrollable';
import Checkbox from './Checkbox';
import Inputs from './Inputs';
import Message from './Message';
import FormDialog from './FormDialog';

const AddEvent = ({reloadTable}) => {
    const {setLoading,setDialog} = useContext(GlobalContext);
    const [event,setEvent] = useState({
        name:'',
        description:'',
        venue:'',
        date: new Date(),
        startHour:'',
        startMin:'',
        endHour:'',
        endMin:'',
        tariffApplicable:false
    });
    const [message,setMessage] = useState({content:'',success:false});

    const minWidth = 240;
    const [inputWidth,setInputWidth] = useState(minWidth);

    const onChange = (e) => {
        const value = e.target.value;
        if(value === '') {
            setEvent({...event, [e.target.name]: null});
        } else {
            setEvent({...event, [e.target.name]: value});
        }
    }

    const onDate = (e) => {
        const value = e.target.value;
        setEvent({...event, [e.target.name]: new Date(value)});
    };


    const navigate = useNavigate();

    const submit = async (e) => {
        setMessage({content:'',success:false});
        setLoading(true);
        EventService.saveEvent(event)
        .then((response) => {
            setLoading(false);
            if(response.status) {
                if(response.status === 'SUCCESSFUL') {
                    reloadTable && reloadTable();
                    setDialog(null);
                } else {
                    setMessage({content:response.message,success:false});
                }
            } else  {
                setMessage({content:response,success:false});
            }
        })
        .catch((error) => {
            setMessage({content:error.message,success:false});
            setLoading(false);
        });
    };

    const [register,handleChange,handleSubmit,errors] = useFormValidator(submit);

    const inputs = [
        {
            label:'name',
            type:'text', 
            name:'name',
            value:event && event.name?event.name:'',   
            placeholder:'Enter name...',
            onChange:(e) => {handleChange(e,onChange)},
            register:register,
            errors:errors
        },
        {
            label:'Description',
            type:'text', 
            name:'description',
            value:event && event.description?event.description:'',   
            placeholder:'Enter description...',
            onChange:(e) => {handleChange(e,onChange)}
        },
        {
            label:'Date',
            type:'date', 
            name:'date',
            value:event?event.date.toISOString().slice(0, 10):'',   
            placeholder:'Enter description...',
            onChange:(e) => handleChange(e,onDate),
            register:register,
            errors:errors
        },
        {
            label:'Venue',
            type:'text', 
            name:'venue',
            value:event?event.venue:'',   
            placeholder:'Enter venue...',
            onChange:(e) => {handleChange(e,onChange)}
        },
        {
            label:'Start',
            id:'start',
            type:'time', 
            value:event? {
                hour:event.startHour?event.startHour:'',
                min:event.startMin?event.startMin:''
            }:null,   
            onChange:(e) => {handleChange(e,(e)=>{
                if(e.target.name.includes('hour')) {
                    setEvent({...event,startHour:e.target.value});
                } else if(e.target.name.includes('min')) {
                    setEvent({...event,startMin:e.target.value});
                }
            })},
            register:register,
            errors:errors
        },
        {
            label:'End',
            id:'end',
            type:'time', 
            value:event? {
                hour:event.endHour?event.endHour:'',
                min:event.endMin?event.endMin:''
            }:null,   
            onChange:(e) => {handleChange(e,(e)=>{
                if(e.target.name.includes('hour')) {
                    setEvent({...event,endHour:e.target.value});
                } else if(e.target.name.includes('min')) {
                    setEvent({...event,endMin:e.target.value});
                }
            })},
            register:register,
            errors:errors
        },
        {
            label:'Tariff Applicable',
            type:'checkbox',
            name:'tariffApplicable',
            value:event?event.tariffApplicable:false,   
            onChange:(e) => {
                setEvent({...event,tariffApplicable: !event.tariffApplicable});
            }
        }
    ]

    return (
        <FormDialog title='Add Event' height={500}>
            <FormValidator>
                <div className='flex flex-col w-full h-[450px] p-8'>
                    <Scrollable>
                        <div className='flex flex-col w-full h-auto shrink-0'>
                            <Inputs inputs={inputs} minWidth={240} paddingX={0} spaceX={32} id='add_event' setCalcWidth={setInputWidth}/>
                            <Message message={message}/>
                            <button style={{'--width':inputWidth+'px'}} 
                                onClick={handleSubmit} className='flex shrink-0 w-full lg:w-[var(--width)] h-10 mx-auto rounded-lg items-center justify-center bg-[rgb(0,175,240)] hover:bg-[rgba(0,175,240,.7)] text-white'>
                                Submit
                            </button>
                        </div>
                    </Scrollable>
                </div>
            </FormValidator>
        </FormDialog>
      )
}

export default AddEvent