import React, {useEffect,useState,useContext} from 'react'
import { useNavigate,useParams,useLocation } from 'react-router-dom';
import { GlobalContext } from '../contexts/GlobalContext';
import PaymentService from '../services/PaymentService';
import UserService from '../services/UserService';
import ReceivableService from '../services/ReceivableService';
import TariffService from '../services/TariffService';
import Message from './Message';

const Payment = () => {
    const [tab,setTab] = useState(null);
    const {setLoading,setReloadUser} = useContext(GlobalContext);
    const [message,setMessage] = useState({content:'',success:false});
    const [onSubmit,setOnSubmit] = useState({method:() => {}});
    const navigate = useNavigate();
    const {state} = useLocation();
    const params = state?state.params:null;

    useEffect(() => {
        
    },[]);
    return (
        <div className='flex flex-col w-full h-full overflow-hidden font-jostMedium'>
            <div className='flex flex-row w-full h-10 px-4 space-x-4 shrink-0 border-b'>
                <button onClick={e => setTab({
                        name:'Mobile Money',
                        Component: () => <MobileMoney params={params} setMessage={setMessage} setOnSubmit={setOnSubmit}/>
                    })} 
                    className={`w-1/2 h-full ${tab && tab.name === 'Mobile Money'?'border-b-2 border-[rgb(0,175,240)]':''}`}>
                    Mobile Money
                </button>
                <button onClick={e => setTab({
                        name:'Card',
                        Component: () => <Card/>
                    })} 
                    className={`w-1/2 h-full ${tab && tab.name === 'Card'?'border-b-2 border-[rgb(0,175,240)]':''}`}>
                    Card
                </button>
            </div>
            {tab && <tab.Component/>}
            <Message message={message}/>
            <button onClick={(e) => onSubmit.method()} className='flex items-center justify-center w-32 h-10 mx-auto shrink-0 bg-[rgb(0,175,240)] hover:bg-[rgba(0,175,240,.7)] text-white text-xs font-jostSemi tracking-widest uppercase rounded-md'>
                Submit
            </button>
        </div>
    )
    }

    export default Payment

    const MobileMoney = ({params,setMessage,setOnSubmit}) => {
    const {setDialog,setReloadUser,loading,setLoading} = useContext(GlobalContext);
    const [receivable,setReceivable] = useState(null);
    const [tariff,setTariff] = useState(null);
    const [phoneNumber,setPhoneNumber] = useState({
        part1:'260',
        part2:'',
        part3:'',
        part4:''
    });

    const onPhoneNumber = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let value = e.target.value;
        if(isNaN(value)) {
            return;
        }
        setPhoneNumber({...phoneNumber,[e.target.name]:value})
    }
    const navigate = useNavigate();

    const confirm = async () => {
        setMessage({content:"",success:false});
        setLoading(true);
        if(!params || !receivable) {
            setLoading(false);
            return;
        }
        if(phoneNumber.part1 === '') {
            setLoading(false);
            setMessage({content:'Invalid phone number',success:false});
            return;
        }
        if(phoneNumber.part2 === '') {
            setLoading(false);
            setMessage({content:'Invalid phone number',success:false});
            return;
        }
        if(phoneNumber.part3 === '') {
            setLoading(false);
            setMessage({content:'Invalid phone number',success:false});
            return;
        }
        if(phoneNumber.part4 === '') {
            setLoading(false);
            setMessage({content:'Invalid phone number',success:false});
            return;
        }
        let narration = `Payment for ${receivable.name}`;
        let number = phoneNumber.part1+phoneNumber.part2+phoneNumber.part3+phoneNumber.part4;
        params.set('phone',number);
        params.set('narration',narration);
        PaymentService.makePayment(params,false)
        .then((response) => {
            if(response.status) {
                if(response.status === 'SUCCESSFUL' && response.content) {
                    if(response.content.status === 'SUCCESSFUL') {
                        setMessage({content:'Payment was successfull',success:true});
                    } else if(response.content.status === 'PENDING') { 
                        setMessage({content:"Transaction Id is "+response.content.transactionId,success:true}); 
                    }
                }else if(response.message) {
                    setMessage({content:response.message,success:false});
                }
            } else {
                setMessage({content:response,success:false});
            }
            setLoading(false);
        })
        .catch((error) => {
            setMessage({content:error.message,success:false});
            setLoading(false);
        })
        setReloadUser({reload:true});
    } 

    useEffect(() => {
        (async () => {
            if(params && params.get('receivableId')) {
                await ReceivableService.getReceivable(params.get('receivableId'))
                .then(async (response) => {
                    if(response.status && response.status === 'SUCCESSFUL' && response.content) {
                        setReceivable(response.content);
                    } else {
                        console.log(response.message);
                        setReceivable(null);
                        setMessage({content:response.message,success:false});
                    }
                })
                .catch((error) => {
                    console.log(error.message);
                    setReceivable(null);
                    setMessage({content:error.message,success:false});
                })
            }
            
            if(params) {
                await PaymentService.getPaymentTariff(params)
                .then(async (response) => {
                    if(response.status && response.status === 'SUCCESSFUL' && response.content) {
                        setTariff(response.content);
                    } else {
                        console.log(response.message);
                        setTariff(null);
                        setMessage({content:response.message,success:false});
                        navigate('/access/login')
                    }
                })
                .catch((error) => {
                    console.log(error.message);
                    setTariff(null);
                    setMessage({content:error.message,success:false});
                    navigate('/access/login')
                })
            }
        })()
        setOnSubmit({method:()=>confirm()});
    },[phoneNumber])

    return (
        <div className='flex flex-col space-y-6 lg:justify-center w-full h-full lg:h-fit p-2 lg:p-8 no-scrollbar overflow-auto'>
            <div className='flex flex-row w-full h-fit justify-between'>
            <div className='w-24 h-24 p-2 rounded-3xl overflow-hidden'>
                <img src='/images/airtel.png' alt='Airtel' className='w-full h-full object-contain object-center'/>
            </div>
            <div className='w-24 h-24 p-2 rounded-3xl overflow-hidden'>
                <img src='/images/mtn.png' alt='Mtn' className='w-full h-full object-contain object-center'/>
            </div>
            <div className='w-24 h-24 p-2 rounded-3xl overflow-hidden'>
                <img src='/images/zamtel.png' alt='Zamtel' className='w-full h-full object-contain object-center'/>
            </div>
            </div>
            <div className='flex flex-row w-full h-10 items-center text-gray-600 text-sm'>
            <label htmlFor='amount' className='w-1/3 capitalize'>Amount</label>
            <div className='flex flex-row space-x-1 w-2/3 h-full items-center overflow-hidden'>
                <p className='flex w-11 shrink-0'>ZMW</p>
                <input
                id='amount'
                name='amount'
                type='number'
                value={tariff? tariff.price:''}
                disabled={true}
                className={`flex w-full h-10 p-1 focus:outline-none font-thin whitespace-nowrap bg-transparent border rounded-lg`}
                />
            </div>
            </div>
            <div className='flex flex-row w-full h-10 items-center text-gray-600 text-sm'>
            <label htmlFor='number' className='w-1/3 capitalize'>Phone number</label>
            <div className='flex flex-row space-x-1 w-2/3 h-full items-center justify-between overflow-hidden'>
                <input 
                    type='text'
                    inputMode='numeric'
                    maxLength={3}
                    name="part1"
                    disabled={true}
                    value={phoneNumber.part1}
                    placeholder="000"
                    onChange={onPhoneNumber}
                    className={`flex w-full h-10 p-1 focus:outline-none font-thin whitespace-nowrap bg-transparent border ${phoneNumber.part1 === ''?'border-red-500':''} rounded-lg`}
                />
                <p className=''>-</p>
                <input 
                    type='text'
                    inputMode='numeric'
                    maxLength={3}
                    name="part2"
                    value={phoneNumber.part2}
                    placeholder="000"
                    onChange={onPhoneNumber}
                    className={`flex w-full h-10 p-1 focus:outline-none font-thin whitespace-nowrap bg-transparent border ${phoneNumber.part2 === ''?'border-red-500':''} rounded-lg`}
                />
                <p className=''>-</p>
                <input 
                    type='text'
                    inputMode='numeric'
                    maxLength={3}
                    name="part3"
                    value={phoneNumber.part3}
                    placeholder="000"
                    onChange={onPhoneNumber}
                    className={`flex w-full h-10 p-1 focus:outline-none font-thin whitespace-nowrap bg-transparent border ${phoneNumber.part3 === ''?'border-red-500':''} rounded-lg`}
                />
                <p className=''>-</p>
                <input 
                    type='text'
                    inputMode='numeric'
                    maxLength={3}
                    name="part4"
                    value={phoneNumber.part4}
                    placeholder="000"
                    onChange={onPhoneNumber}
                    className={`flex w-full h-10 p-1 focus:outline-none font-thin whitespace-nowrap bg-transparent border ${phoneNumber.part4 === ''?'border-red-500':''} rounded-lg`}
                />
            </div>
            </div>
        </div>
    )
}

const Card = () => {
  return (
    <div>Card</div>
  )
}