import axios from "axios";

class UserCPDRoleUService {

    async getUserCPDRole (userId,cpdId) {
        let responseObject = null;
        await axios.get(`usercpdrole/${userId}/${cpdId}`)
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data) {
                responseObject =  error.response.data;
            } else {
                responseObject =  error.message;
            }
        })

        return responseObject;
    }

    async getUserCPDRolesUser (userId) {
        let responseObject = null;
        await axios.get(`usercpdroles/user/${userId}`)
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data) {
                responseObject =  error.response.data;
            } else {
                responseObject =  error.message;
            }
        })

        return responseObject;
    }

    async getUserCPDRolesCPD (cpdId) {
        let responseObject = null;
        await axios.get(`usercpdroles/cpd/${cpdId}`)
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data) {
                responseObject =  error.response.data;
            } else {
                responseObject =  error.message;
            }
        })

        return responseObject;
    }
}

export default new UserCPDRoleUService();