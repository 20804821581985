import React, {useState, useEffect, useContext} from 'react';
import {sex,provinces,nationalities,idTypes,professionalCategories} from '../data';
import UserService from '../services/UserService'
import RoleService from '../services/RoleService'
import PaymentService from '../services/PaymentService'
import { useNavigate} from 'react-router-dom';
import { GlobalContext } from '../contexts/GlobalContext';
import { BiSolidLogInCircle,BiSolidContact } from "react-icons/bi";
import { ImUser } from "react-icons/im";
import { IoSchoolSharp } from "react-icons/io5";
import { FaUserDoctor } from "react-icons/fa6";
import { MdImage } from "react-icons/md";
import UserForm, {Page,ImageUpload} from './UserForm'
import {useFormValidator} from './FormValidator';

const Register = () => {
    const {setLoading} = useContext(GlobalContext);
    const [roles,setRoles] = useState([]);
    const [province,setProvince] = useState(provinces[0]);
    const [image, setImage] = useState(null);
    const [message,setMessage] = useState({content:'',success:false});
    const [confirmPassword,setConfirmPassword] = useState('');
    
    const [user, setUser] = useState({
        id:'',
        username:'',
        password:'',
        firstname:'',
        lastname:'',
        middlename:'',
        sex:sex[0],
        dateOfBirth: new Date(),
        idType:idTypes[0],
        idNumber:'',
        nationality:nationalities[55],
        primaryEmail:'',
	    secondaryEmail:'',
        phone1:'',
        phone2:'',
        physicalAddress:'',
        postalAddress:'',
        province:province.name,
        district:province.districts[0],
        program:'',
        institution:'',
        professionalCategory:'',
        employed:false,
        employer:'',
        organizationalUnit:'',
        currentPosition:'',
        facility:'',
        image:'',
        ledgerAccountRef:'',
        moodleId:'',
        confirmed:false,
        changePassword:false,
        accountNonLocked:false,
        enabled:'',
        role:null
    });

    const minWidth = 240;

    const onChange = (e) => {
        const value = e.target.value;
        if(value === '') {
            setUser({...user, [e.target.name]: null});
        } else {
            setUser({...user, [e.target.name]: value});
        }
    };

    const onDateOfBirth = (e) => {
        const value = e.target.value;
        setUser({...user, dateOfBirth: new Date(value)});
    };

    const onProvince = (e) => {
        const value = e.target.value;
        const province = provinces.find((province) => {return province.name == value});
        if(province) {
            setProvince(province);
            setUser({...user,province:province.name});
        }
    };

    const onRole = (e) => {
        const value = e.target.value;
        const role = roles.find((role) => {return role.name == value});
        if(role) {
            setUser({...user,role:role});
        }
    };

    const onEmployed = (e) => {
        setUser({...user,employed: !user.employed});
    }

    const navigate = useNavigate();

    const submit = async () => {
        setMessage({content:'',success:false});
        if(user.password != confirmPassword) {
            setMessage({content:'Password and confirm password mismatch',success:false});  
            return;
        }
        setLoading(true);
        await UserService.saveUserSelf(user,false)
        .then(async (saveResponse) => {
            if(saveResponse.content && saveResponse.content != null && saveResponse.content != {}) {
                let user = saveResponse.content;
                if(user.role && user.role.name.toLowerCase() === 'pending_payment') {
                    if(user.reserveRole) {
                        await PaymentService.getPaymentTariff(user.reserveRole.id,0,null,false)
                        .then(async (response) => {
                            if(response.status && response.status === 'SUCCESSFUL' && response.content) {
                                navigate(`/access/payment/${user.reserveRole.id}/${response.content.id}`);
                            } else {
                                console.log(response.message)
                            }
                        })
                        .catch((error) => {
                            console.log(error.message);
                        });
                    }
                } else {
                    navigate('/access/login');
                }
                
            } else {
                setMessage({content:saveResponse,success:false});
            }
            setLoading(false);
        })
        .catch((error) => {
           setMessage({content:error.message,success:false});
           setLoading(false);
        }); 
    };

    const [register,handleChange,handleSubmit,errors] = useFormValidator(submit);

    const pages = [
        <Page
            key={1}
            Icon={BiSolidLogInCircle}
            id='login'
            name='Login Details'
            inputs={[
                {
                    label:'Username',
                    type:'text',
                    name:'username', 
                    value:user.username,
                    placeholder:'Enter username...',
                    onChange:(e) => handleChange(e,onChange),
                    register:register,
                    errors:errors
                },
                {
                    label:'Role',
                    type:'select',
                    options:() => {
                        let options = [];
                        roles.map((option,i) => options.push(<option key={i} value={option.name}>{option.displayName}</option>));
                        return options;
                    },
                    name:'role', 
                    value:user && user.role?user.role.name:'',
                    placeholder:'Select role...',
                    onChange:(e) => handleChange(e,onRole),
                    register:register,
                    errors:errors
                },
                {
                    label:'Password',
                    type:'password',
                    name:'password', 
                    value:user.password,
                    placeholder:'Enter password...',
                    onChange:(e) => handleChange(e,onChange),
                    register:register,
                    errors:errors
                },
                {
                    label:'Confirm Password',
                    type:'password',
                    name:'confirmPassword', 
                    value:confirmPassword,
                    placeholder:'Confirm password...',
                    onChange:(e) => handleChange(e,(e)=> setConfirmPassword(e.target.value)),
                    register:register,
                    errors:errors
                }
            ]}
            minWidth={minWidth}
        />,
        <Page
            key={2}
            Icon={ImUser}
            id='personal'
            name='Personal Details'
            inputs={[
                {
                    label:'Firstname',
                    type:'text', 
                    name:'firstname',
                    value:user.firstname,   
                    placeholder:'Enter firstname...',
                    onChange:(e) => {handleChange(e,onChange)},
                    register:register,
                    errors:errors
                },
                {
                    label:'Lastname',
                    type:'text',
                    name:'lastname', 
                    value:user.lastname,
                    placeholder:'Enter lastname...',
                    onChange:(e) => handleChange(e,onChange),
                    register:register,
                    errors:errors
                },
                {
                    label:'Middlename',
                    type:'text',
                    name:'middlename', 
                    value:user.middlename,
                    placeholder:'Enter middlename...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Sex',
                    type:'select',
                    options:() => {
                        let options = [];
                        sex.map((option,i) => options.push(<option key={i} value={option}>{option}</option>));
                        return options;
                    },
                    name:'sex', 
                    value:user.sex,
                    placeholder:'Select sex...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Date of Birth',
                    type:'date',
                    name:'dateOfBirth', 
                    value:user.dateOfBirth.toISOString().slice(0, 10),
                    placeholder:'Pick date of birth...',
                    onChange:(e) => handleChange(e,onDateOfBirth)
                },
                {
                    label:'ID type',
                    type:'select',
                    options:() => {
                        let options = [];
                        idTypes.map((option,i) => options.push(<option key={i} value={option}>{option}</option>));
                        return options;
                    },
                    name:'idType', 
                    value:user.idType,
                    placeholder:'Select ID type...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'ID number',
                    type:'text',
                    name:'idNumber', 
                    value:user.idNumber,
                    placeholder:'Enter ID number...',
                    onChange:(e) => handleChange(e,onChange),
                    register:register,
                    errors:errors
                },
                {
                    label:'Nationality',
                    type:'select',
                    options:() => {
                        let options = [];
                        nationalities.map((option,i) => options.push(<option key={i} value={option}>{option}</option>));
                        return options;
                    },
                    name:'nationality', 
                    value:user.nationality,
                    placeholder:'Select nationality...',
                    onChange:(e) => handleChange(e,onChange)
                }
            ]}
            minWidth={minWidth}
        />,
        <Page
            key={3}
            Icon={BiSolidContact}
            id='contact'
            name='Contact Details'
            inputs={[
                {
                    label:'Email',
                    type:'text',
                    name:'email',
                    value:user.email,   
                    placeholder:'Enter email...',
                    onChange:(e) => {handleChange(e,onChange)},
                    register:register,
                    errors:errors
                },
                {
                    label:'Phone 1',
                    type:'text',
                    name:'phone1', 
                    value:user.phone1,
                    placeholder:'Enter phone 1...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Phone 2',
                    type:'text',
                    name:'phone2', 
                    value:user.phone2,
                    placeholder:'Select phone2...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Physical address',
                    type:'text',
                    name:'physicalAddress', 
                    value:user.physicalAddress,
                    placeholder:'Enter physical address...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Postal Address',
                    type:'text',
                    name:'postalAddress', 
                    value:user.postalAddress,
                    placeholder:'Enter postal address...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Province',
                    type:'select',
                    options:() => {
                        let options = [];
                        provinces.map((option,i) => options.push(<option key={i} value={option.name}>{option.name}</option>));
                        return options;
                    },
                    name:'province', 
                    value:user.province,
                    placeholder:'Enter province...',
                    onChange:(e) => handleChange(e,onProvince)
                },
                {
                    label:'District',
                    type:'select',
                    options:() => {
                        let options = [];
                        province.districts.map((option,i) => options.push(<option key={i} value={option}>{option}</option>));
                        return options;
                    },
                    name:'district', 
                    value:user.district,
                    placeholder:'Select district...',
                    onChange:(e) => handleChange(e,onChange)
                }
            ]}
            minWidth={minWidth}
        />,
        <Page
            key={4}
            Icon={IoSchoolSharp}
            id='education'
            name='Educational Details'
            inputs={[
                {
                    label:'Program',
                    type:'text',
                    name:'program',
                    value:user.program,   
                    placeholder:'Enter program...',
                    onChange:(e) => {handleChange(e,onChange)}
                },
                {
                    label:'Institution',
                    type:'text',
                    name:'institution', 
                    value:user.institution,
                    placeholder:'Enter Institution...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Professional Category',
                    type:'select',
                    options:() => {
                        let options = [];
                        professionalCategories.menus.map((option,i) => options.push(<option key={i} value={option.name}>{option.name}</option>));
                        return options;
                    },
                    name:'professionalCategory', 
                    value:user.professionalCategory?user.professionalCategory:'',
                    placeholder:'Select professional category...',
                    onChange:(e) => handleChange(e,onChange)
                }
            ]}
            minWidth={minWidth}
        />,
        <Page
            key={5}
            Icon={FaUserDoctor}
            id='employment'
            name='Employment Details'
            inputs={[
                {
                    label:'Employed',
                    type:'checkbox',
                    name:'employed',
                    value:user.employed,   
                    onChange:(e) => onEmployed(e)
                },
                {
                    label:'Employer',
                    type:'text',
                    name:'employer', 
                    value:user.employer,
                    disabled:!user.employed,
                    placeholder:'Enter employer...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Organizational Unit',
                    type:'text',
                    name:'organizationalUnit', 
                    value:user.organizationalUnit,
                    disabled:!user.employed,
                    placeholder:'Enter organizational unit...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Current Position',
                    type:'text',
                    name:'currentPosition', 
                    value:user.currentPosition,
                    disabled:!user.employed,
                    placeholder:'Enter current position...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Facility',
                    type:'text',
                    name:'facility', 
                    value:user.facility,
                    disabled:!user.employed,
                    placeholder:'Enter facility...',
                    onChange:(e) => handleChange(e,onChange)
                }
            ]}
            minWidth={minWidth}
        />,
        <ImageUpload 
            key={6}
            Icon={MdImage}
            id='image'
            image={image}
            setImage={setImage}
        />
    ]
    useEffect(() => {
        ( async () => {
            setLoading(true);
            await RoleService.getRoles()
            .then((response) => {
                setLoading(false);
                if(response.content) {
                    setRoles(response.content.filter(role => !role.reserved));
                }  else {
                    setRoles([]);
                }
            })
            .catch((error) => {
                console.log(error.message);
                setLoading(false);
                setRoles([]);
            })
        }
        )();
    },[]);
  return (
    <UserForm pages={pages} onSubmit={handleSubmit} errors={errors} message={message}/>
  )
}

export default Register