import React, {useContext} from 'react';
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate } from 'react-router-dom';
import CPDService from '../services/CPDService';
import FormDialog from './FormDialog';
import { IoSchoolOutline } from "react-icons/io5";
import Table from './Table';
import PaymentService from '../services/PaymentService';
import ReceivableService from '../services/ReceivableService';
import UserCPDRoleService from '../services/UserCPDRoleService';
import CPDRoleSelector from './CPDRoleSelector';

const CPDEnrollment = ({user}) => {
    const {setLoading,hasAuthority,setDialog} = useContext(GlobalContext);
    const columns = [
        {
            name:'shortname',
            label:'Shortname',
            type:'text',
            filter: ''
        },
        {
            name:'fullname',
            label:'Fullname',
            type:'text',
            Filter: ''
        },
        {
            name:'startDate',
            label:'Start Date',
            type:'date',
            filter:''
        }
    ]

    const navigate = useNavigate();

    const getCPDs = async () => {
        if(!user || !user.role || !hasAuthority(user,'ELEARNING')) {
            return {status:'ERROR',content:[]};
        }
        setLoading(true);
        let object = {}
        let userCpdRoles = [];
        await UserCPDRoleService.getUserCPDRolesUser(user.id) 
        .then((userCpdRoleResponse) => {
            if(userCpdRoleResponse.content) {
                userCpdRoles = userCpdRoleResponse.content;
            }
        })
        await CPDService.getCPDs(false)
        .then(async (response) => {
            setLoading(false);
            if(response.content) {
                let cpds = [];
                for(let cpd of response.content) {
                    let found = userCpdRoles.find((userCpdRole)=>{return userCpdRole.cpdId == cpd.id});
                    if(!found) {
                        cpd.startDate = new Date(cpd.startDate);
                        cpds.push(cpd);
                    } 
                }
                object = {status:'SUCCESSFUL',content:cpds}
            } else {
                object = {status:'FAILED',content:[]};
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log(error.message);
            object = {status:'ERROR',content:[]};
        });
        return object;
    }

    const handleEnroll = async (e,cpd,setMessage) => {
        e.preventDefault();
        if(!cpd) {
            return;
        }

        setDialog(null);
        setDialog({
            show:true,
            Component:() => 
                <CPDRoleSelector user={user} cpd={cpd}/>
        })
        /* await ReceivableService.hasUserPaid(cpd.id,user.id)
        .then((paidResponse) => {
            if(paidResponse.status) {
                if(paidResponse.status === 'YES') {
                    setDialog(null);
                } else {
                    
                    if(cpd.tariff) {
                        setDialog(null);
                        navigate(`/access/payment/${cpd.id}/${cpd.tariff.id}`);
                    }
                }
            } 
        })
        .catch((error) => {
            setMessage({content:error.message,success:false});
            console.log(error.message);
        }); */
    }

    const getButtons = (user,setMessage) => {
        let buttons = [];
        if(hasAuthority(user,'ELEARNING')) {
            buttons.push({
                name:'Enroll',
                disabled:(selected) => {
                    return selected?false:true;
                },
                onClick:(e,selected) => handleEnroll(e,selected,setMessage),
                Icon:IoSchoolOutline
            });  
        } 
        return buttons;
    }
  return (
    <FormDialog title='CPD Enrollment'>
        <Table columns={columns} retrieveRecords={getCPDs} getButtons={getButtons}/>
    </FormDialog>
  )
}

export default CPDEnrollment