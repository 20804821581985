import React from 'react'
import { GiMegaphone } from "react-icons/gi";
import { FaRegCircleDot } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";

const AdvocacyInHealth = () => {
  return (
    <div className='flex flex-col space-y-16 w-full h-auto'>
        <div className='flex flex-col space-y-4 w-full h-auto'>
            <div className='flex flex-row space-x-4'>
                <GiMegaphone size={24} className='text-[rgb(85,85,85)] shrink-0'/>
                <p className='text-lg tracking-wider font-jostSemi text-[rgb(85,85,85)] uppercase'>
                    JUDICIAL ADVOCACY
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <GiMegaphone size={24} className='text-[rgb(85,85,85)] shrink-0'/>
                <p className='text-lg tracking-wider font-jostSemi text-[rgb(85,85,85)] uppercase'>
                    HUMAN RESOURCES FOR HEALTH ADVOCACY
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <GiMegaphone size={24} className='text-[rgb(85,85,85)] shrink-0'/>
                <p className='text-lg tracking-wider font-jostSemi text-[rgb(85,85,85)] uppercase'>
                    LOCAL GOVERNANCE ADVOCACY
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <GiMegaphone size={24} className='text-[rgb(85,85,85)] shrink-0'/>
                <p className='text-lg tracking-wider font-jostSemi text-[rgb(85,85,85)] uppercase'>
                    STATE ADVOCACY
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <GiMegaphone size={24} className='text-[rgb(85,85,85)] shrink-0'/>
                <p className='text-lg tracking-wider font-jostSemi text-[rgb(85,85,85)] uppercase'>
                    PUBLIC HEALTH ADVOCACY
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <GiMegaphone size={24} className='text-[rgb(85,85,85)] shrink-0'/>
                <p className='text-lg tracking-wider font-jostSemi text-[rgb(85,85,85)] uppercase'>
                    ACCESS TO HEALTH CARE
                </p>
            </div>
        </div>
        <div className='flex flex-col space-y-8 w-full h-auto'>
            <p className='flex w-full h-auto font-semibold font-leBeauneNew text-[22px] lg:text-4xl text-[rgb(59,59,59)]'>
                STATE ADVOCACY 
            </p>
            <p className='w-full h-auto text-lg text-[rgb(100,100,100)] font-jostBook'>
                At the state level, the COAZ shapes the policy of influential national policymaking, partners with outside 
                experts and stakeholder groups and influences the enactment and/or defeat of state legislation and regulation
            </p>
            <p className='text-lg tracking-wider font-jostSemi text-[rgb(85,85,85)]'>
                Our State legislative and regulatory priorities
            </p>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    Legislative Amendment to the medicines and Allied substances act
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    Legislative amendment to the Health Professions council of Zambia to reflect communities of practice, 
                    that considers of clinical practitioners' community of practice
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    Legislative amendment to the food and nutrition act of 2020
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    Legislative enactment of the National Health services act
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    Legislative enactment of the Clinical Officers bill
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    Legislative enactment of Affordable Care Act
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    Opening regulatory documents for Clinical officer specialists with HPCZ
                </p>
            </div>
        </div>
        <div className='flex flex-col space-y-8 w-full h-auto'>
            <p className='flex w-full h-auto font-semibold font-leBeauneNew text-[22px] lg:text-4xl text-[rgb(59,59,59)]'>
                ACCESS TO HEALTH CARE 
            </p>
            <p className='w-full h-auto text-lg text-[rgb(100,100,100)] font-jostBook'>
                The COAZ works tirelessly to preserve health care access and coverage for Zambians across the nation, 
                especially the country’s most vulnerable and underserved patient populations
            </p>
            <p className='text-lg tracking-wider font-jostSemi text-[rgb(85,85,85)]'>
                Our current focus on access to Health care include: 
            </p>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    National health insurance (NHIMA) coverage for all Zambians
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    Freedom of choice
                </p>
            </div>
            <div className='flex flex-row space-x-4'>
                <FaRegCircleDot size={16} className='text-[rgb(85,85,85)] mt-[5px] shrink-0'/>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    Freedom of practice for clinical officers to increase universal access for patients
                </p>
            </div>
        </div>
    </div>
  )
}

export default AdvocacyInHealth