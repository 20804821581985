import React, {useState, useEffect,useRef, useContext} from 'react';
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate,useParams } from 'react-router-dom';
import UserService from '../services/UserService';
import { RiLockPasswordFill } from "react-icons/ri";
import FormValidator, {useFormValidator} from './FormValidator';
import Input from './Input';
import Message from './Message'

const FinishRegistration = () => {
    const {setLoading} = useContext(GlobalContext);
    const [details,setDetails] = useState({
        username:'',
        newUsername:'',
        email:'',
        oldPassword:'',
        newPassword:'',
        confirmPassword:''
    }) 
    const [message,setMessage] = useState({content:'',success:false}); 
    const navigate = useNavigate();

    const [itemSize,setItemSize] = useState({});

    const onChange = (e) => {
        setDetails({...details,[e.target.name]:e.target.value});
    }

    const submit = async (e) => {
        setLoading(true);
        setMessage({content:'',success:true});
        if(details.newPassword === details.confirmPassword) {
            UserService.finishRegistration(details.username,details.newUsername,details.email,details.oldPassword,details.newPassword)
            .then((response) => {
                setLoading(false);
                if(response.status) {
                    if(response.status === 'SUCCESSFUL') {
                        setMessage({content:response.message,success:true});
                        navigate('/access/login');
                    } else {
                        setMessage({content:response.message,success:false});
                    }
                } else  {
                    setMessage({content:response,success:false});
                }
            })
            .catch((error) => {
                setLoading(false);
                setMessage({content:error.message,success:false});
            });
        } else {
            setLoading(false);
            setMessage({content:"New password and confirm password didn't match, try again!",success:false});
        }
    };

    const [register,handleChange,handleSubmit,errors] = useFormValidator(submit);

    const inputs = [
        <Input 
            label="Username"
            type="text" 
            id="username" 
            name="username"  
            value={details.username}
            placeholder="Enter username..."
            onChange= {(e) => handleChange(e,onChange)}
            register={register}
            errors={errors}
            width={itemSize.width}
        />,
        <Input 
            label="New username"
            type="text" 
            id="newUsername" 
            name="newUsername"  
            value={details.newUsername}
            placeholder="Enter new username..."
            onChange= {(e) => handleChange(e,onChange)}
            register={register}
            errors={errors}
            width={itemSize.width}
        />,
        <Input 
            label="Email"
            type="text"
            id="email" 
            name="email"  
            value={details.email}
            placeholder="Enter email..."
            onChange= {(e) => handleChange(e,onChange)}
            register={register}
            errors={errors}
            width={itemSize.width}
        />,
        <Input 
            label="Old Password"
            type="password"
            id="oldPassword" 
            name="oldPassword"  
            value={details.oldPassword}
            placeholder="Enter old password..."
            onChange= {(e) => handleChange(e,onChange)}
            register={register}
            errors={errors}
            width={itemSize.width}
        />,
        <Input 
            label="New Password"
            type="password"
            id="newPassword" 
            name="newPassword"  
            value={details.newPassword}
            placeholder="Enter new password..."
            onChange= {(e) => handleChange(e,onChange)}
            register={register}
            errors={errors}
            width={itemSize.width}
        />,
        <Input 
            label="Confirm Password"
            type="password"
            id="confirmPassword" 
            name="confirmPassword"  
            value={details.confirmPassword}
            placeholder="Confirm password..."
            onChange= {(e) => handleChange(e,onChange)}
            register={register}
            errors={errors}
            width={itemSize.width}
        />
    ]

    const mainContainerRef = useRef(null);
    const [columns,setColumns] = useState(inputs.length);
    let paddingX = 32;
    let spaceX = 32;

    const calWidth = (pw,mw,pa,sp,cols) => {
        if(cols === 0){
            return {w:0,cols:0};
        }
        let w = 0;
        let aw = pw - (pa*2) - (sp * (cols - 1));
        w = aw/cols;
        if(w < mw && cols > 1) {
            cols -= 1;
            return calWidth(pw,mw,pa,sp,cols)
        } else {
            return {w,cols};
        }
    }

    useEffect(() => {
        
        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                let parentWidth = entry.target.getBoundingClientRect().width;
                let minWidth = 224;
                let {w,cols} = calWidth(parentWidth,minWidth,paddingX,spaceX,columns); 
                let h = 80;
                setItemSize({width:w,height:h});
                setColumns(cols);
                
            }
        });

        if(mainContainerRef.current) {
            observer.observe(mainContainerRef.current)
        }
        return () => {
            observer.disconnect();
        }
    },[]);

    return (
        <FormValidator>
            <div ref={mainContainerRef} 
                style={{paddingLeft:paddingX+'px',paddingRight:paddingX+'px'}}
                className='flex flex-col w-full h-full no-scrollbar py-8 space-y-8 overflow-auto'>
                <RiLockPasswordFill size={64} className='flex shrink-0 mx-auto text-[rgba(84,84,87,.7)]'/>
                {inputs && (
                    (() => {
                        const rows = [];
                        let rowKey = 0;
                        for (let i = 0; i < inputs.length;) {
                            const row = [];
                            for (let j = 0; j < columns; j++) {
                                if(i < inputs.length) {
                                    row.push(inputs[i]);
                                    i++;
                                } else {
                                    break;
                                }
                            }
                            rows.push(<div key={rowKey} className={`flex flex-row w-full h-auto items-center shrink-0 space-x-8`}>{row}</div>);
                            rowKey++;
                        }
                        return rows;
                    })()
                )}
                <Message message={message}/>
                <button style={{width:itemSize.width+'px'}} 
                    onClick={handleSubmit} className='flex shrink-0 h-10 mx-auto rounded-lg items-center justify-center bg-[rgb(0,175,240)] hover:bg-[rgba(0,175,240,.7)] text-white'>
                    Submit
                </button>
            </div>
        </FormValidator>
    )
}

export default FinishRegistration
