import axios from "axios";

class CPDRoleService {

    async getCPDRole (id) {
        let responseObject = null;
        await axios.get(`cpd/role/${id}`)
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data) {
                responseObject =  error.response.data;
            } else {
                responseObject =  error.message;
            }
        })

        return responseObject;
    }

    async getCPDRoles () {
        let responseObject = null;
        await axios.get("cpd/roles")
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data) {
                responseObject =  error.response.data;
            } else {
                responseObject =  error.message;
            }
        })

        return responseObject;
    }
}

export default new CPDRoleService();