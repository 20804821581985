import React, {useState,useEffect,useContext} from 'react'
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate } from 'react-router-dom'
import {sex,provinces,nationalities,idTypes,professionalCategories,sectors} from '../data';
import UserService from '../services/UserService';
import RoleService from '../services/RoleService';
import UserForm, {Page,ImageUpload} from './UserForm'
import {useFormValidator} from './FormValidator';
import { IoCloseOutline } from "react-icons/io5";
import { BiSolidLogInCircle,BiSolidContact } from "react-icons/bi";
import { ImUser } from "react-icons/im";
import { IoSchoolSharp } from "react-icons/io5";
import { FaUserDoctor } from "react-icons/fa6";
import { MdImage } from "react-icons/md";
import FormDialog from './FormDialog';

const AddUser = ({reload}) => {
    const {setDialog,setReloadUser,screenSize,setLoading} = useContext(GlobalContext);
    const [autoPassword,setAutoPassword] = useState(true);
    const [roles,setRoles] = useState([]);
    const [province,setProvince] = useState(provinces[0]);
    const [image, setImage] = useState(null);
    const [message,setMessage] = useState({content:'',success:false});
    const [user,setUser] = useState({
        username:'',
        password:'',
        firstname:'',
        lastname:'',
        middlename:'',
        sex:sex[0],
        dateOfBirth: new Date(),
        idType:idTypes[0],
        idNumber:'',
        nationality:nationalities[55],
        email:'',
        phone1:'',
        phone2:'',
        physicalAddress:'',
        postalAddress:'',
        province:province.name,
        district:province.districts[0],
        program:'',
        institution:'',
        professionalCategory:null,
        employed:false,
        sector:null,
        employer:'',
        organizationalUnit:'',
        currentPosition:'',
        facility:'',
        image:'',
        ledgerAccountRef:'',
        moodleId:'',
        role:null
    });

    const navigate = useNavigate();

    const onAutoPassword = (e) => {
        setAutoPassword(!autoPassword);
    }

    const onChange = (e) => {
        const value = e.target.value;
        if(value === '') {
            setUser({...user, [e.target.name]: null});
        } else {
            setUser({...user, [e.target.name]: value});
        }
    };

    const onRole = (e) => {
        const value = e.target.value;
        const role = roles.find((role) => {return role.name == value});
        if(role) {
            setUser({...user,role:role});
        }
    };

    const onDateOfBirth = (e) => {
        const value = e.target.value;
        setUser({...user, dateOfBirth: new Date(value)});
    };

    const onProvince = (e) => {
        const value = e.target.value;
        const province = provinces.find((province) => {return province.name == value});
        if(province) {
            setProvince(province);
            setUser({...user,province:province.name});
        }
    };

    const onEmployed = (e) => {
        let value = !user.employed;
        setUser({...user,employed:value,sector:value?sectors[0]:null});
    }

    const onSubmit = async  () => {
        setLoading(true);
        setMessage({content:'',success:false});
        await UserService.saveUser(user,autoPassword,false)
        .then(async (saveResponse) => {
            setLoading(false);
            if(saveResponse.content && saveResponse.content != null && saveResponse.content != {}) {
                setMessage({content:saveResponse.message,success:true});
                reload();
                setDialog(null);
            } else {
                setMessage({content:saveResponse,success:false});
            }
        })
        .catch((error) => {
            setLoading(false);
            setMessage({content:error.message,success:false});
        }); 
    }

    const [register,handleChange,handleSubmit,errors] = useFormValidator(onSubmit);
    const minWidth = screenSize !== 'lg'?240:320;
    const pages = [
        <Page
            key={1}
            Icon={BiSolidLogInCircle}
            id='1'
            name='Login Details'
            inputs={[
                {
                    label:'Username',
                    type:'text',
                    name:'username', 
                    value:user && user.username?user.username:'',
                    placeholder:'Enter username...',
                    onChange:(e) => handleChange(e,onChange),
                    register:register,
                    errors:errors
                },
                {
                    label:'Role',
                    type:'select',
                    options:() => {
                        let options = [];
                        roles.map((option,i) => options.push(<option key={i} value={option.name}>{option.displayName}</option>));
                        return options;
                    },
                    name:'role', 
                    value:user && user.role?user.role.name:'',
                    placeholder:'Select role...',
                    onChange:(e) => handleChange(e,onRole),
                    register:register,
                    errors:errors
                },
                {
                    label:'Auto Generate Password',
                    type:'checkbox',
                    name:'autoPassword',
                    value:autoPassword,   
                    onChange:(e) => onAutoPassword(e)
                },
                {
                    label:'Password',
                    type:'password',
                    name:'password', 
                    value:user && user.password?user.password:'',
                    disabled:autoPassword,
                    placeholder:'Enter password...',
                    onChange:(e) => handleChange(e,onChange)
                }
            ]}
            minWidth={minWidth}
        />,
        <Page
            key={2}
            Icon={ImUser}
            id='2'
            name='Personal Details'
            inputs={[
                {
                    label:'Firstname',
                    type:'text', 
                    name:'firstname',
                    value:user && user.firstname?user.firstname:'',   
                    placeholder:'Enter firstname...',
                    onChange:(e) => {handleChange(e,onChange)},
                    register:register,
                    errors:errors
                },
                {
                    label:'Lastname',
                    type:'text',
                    name:'lastname', 
                    value:user && user.lastname?user.lastname:'',
                    placeholder:'Enter lastname...',
                    onChange:(e) => handleChange(e,onChange),
                    register:register,
                    errors:errors
                },
                {
                    label:'Middlename',
                    type:'text',
                    name:'middlename', 
                    value:user && user.middlename?user.middlename:'',
                    placeholder:'Enter middlename...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Sex',
                    type:'select',
                    options:() => {
                        let options = [];
                        sex.map((option,i) => options.push(<option key={i} value={option}>{option}</option>));
                        return options;
                    },
                    name:'sex', 
                    value:user && user.sex?user.sex:'',
                    placeholder:'Select sex...',
                    onChange:(e) => handleChange(e,onChange),
                    register:register,
                    errors:errors
                },
                {
                    label:'Date of Birth',
                    type:'date',
                    name:'dateOfBirth', 
                    value:user && user.dateOfBirth?user.dateOfBirth.toISOString().slice(0, 10):'',
                    placeholder:'Pick date of birth...',
                    onChange:(e) => handleChange(e,onDateOfBirth)
                },
                {
                    label:'ID type',
                    type:'select',
                    options:() => {
                        let options = [];
                        idTypes.map((option,i) => options.push(<option key={i} value={option}>{option}</option>));
                        return options;
                    },
                    name:'idType', 
                    value:user && user.idType?user.idType:'',
                    placeholder:'Select ID type...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'ID number',
                    type:'text',
                    name:'idNumber', 
                    value:user && user.idNumber?user.idNumber:'',
                    placeholder:'Enter ID number...',
                    onChange:(e) => handleChange(e,onChange),
                    register:register,
                    errors:errors
                },
                {
                    label:'Nationality',
                    type:'select',
                    options:() => {
                        let options = [];
                        nationalities.map((option,i) => options.push(<option key={i} value={option}>{option}</option>));
                        return options;
                    },
                    name:'nationality', 
                    value:user && user.nationality?user.nationality:'',
                    placeholder:'Select nationality...',
                    onChange:(e) => handleChange(e,onChange)
                }
            ]}
            minWidth={minWidth}
        />,
        <Page
            key={3}
            Icon={BiSolidContact}
            id='3'
            name='Contact Details'
            inputs={[
                {
                    label:'Email',
                    type:'text',
                    name:'email',
                    value:user && user.email?user.email:'',   
                    placeholder:'Enter email...',
                    onChange:(e) => {handleChange(e,onChange)},
                    register:register,
                    errors:errors
                },
                {
                    label:'Phone 1',
                    type:'text',
                    name:'phone1', 
                    value:user && user.phone1?user.phone1:'',
                    placeholder:'Enter phone 1...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Phone 2',
                    type:'text',
                    name:'phone2', 
                    value:user && user.phone2?user.phone2:'',
                    placeholder:'Select phone2...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Physical address',
                    type:'text',
                    name:'physicalAddress', 
                    value:user && user.physicalAddress?user.physicalAddress:'',
                    placeholder:'Enter physical address...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Postal Address',
                    type:'text',
                    name:'postalAddress', 
                    value:user && user.postalAddress?user.postalAddress:'',
                    placeholder:'Enter postal address...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Province',
                    type:'select',
                    options:() => {
                        let options = [];
                        provinces.map((option,i) => options.push(<option key={i} value={option.name}>{option.name}</option>));
                        return options;
                    },
                    name:'province', 
                    value:user && user.province?user.province:'',
                    placeholder:'Enter province...',
                    onChange:(e) => handleChange(e,onProvince)
                },
                {
                    label:'District',
                    type:'select',
                    options:() => {
                        let options = [];
                        province.districts.map((option,i) => options.push(<option key={i} value={option}>{option}</option>));
                        return options;
                    },
                    name:'district', 
                    value:user && user.district?user.district:'',
                    placeholder:'Select district...',
                    onChange:(e) => handleChange(e,onChange)
                }
            ]}
            minWidth={minWidth}
        />,
        <Page
            key={4}
            Icon={IoSchoolSharp}
            id='4'
            name='Educational Details'
            inputs={[
                {
                    label:'Program',
                    type:'text', 
                    name:'program',
                    value:user && user.program?user.program:'',   
                    placeholder:'Enter program...',
                    onChange:(e) => {handleChange(e,onChange)}
                },
                {
                    label:'Institution',
                    type:'text',
                    name:'institution', 
                    value:user && user.institution?user.institution:'',
                    placeholder:'Enter Institution...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Professional Category',
                    type:'select',
                    options:() => {
                        let options = [];
                        professionalCategories.menus.map((option,i) => options.push(<option key={i} value={option.name}>{option.name}</option>));
                        return options;
                    },
                    name:'professionalCategory', 
                    value:user && user.professionalCategory?user.professionalCategory:'',
                    placeholder:'Select professional category...',
                    onChange:(e) => handleChange(e,onChange)
                }
            ]}
            minWidth={minWidth}
        />,
        <Page
            key={5}
            Icon={FaUserDoctor}
            id='5'
            name='Employment Details'
            inputs={[
                {
                    label:'Employed',
                    type:'checkbox', 
                    name:'employed',
                    value:user?user.employed:'',   
                    onChange:(e) => onEmployed(e)
                },
                {
                    label:'Employer',
                    type:'text',
                    name:'employer', 
                    value:user && user.employer?user.employer:'',
                    disabled:user?!user.employed:true,
                    placeholder:'Enter employer...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Sector',
                    type:'select',
                    options:() => {
                        let options = [];
                        if(user.employed) {
                            sectors.map((option,i) => options.push(<option key={i} value={option}>{option}</option>));
                        }
                        return options;
                    },
                    name:'sector', 
                    value:user && user.sector && user.employed?user.sector:'',
                    disabled:user?!user.employed:true,
                    placeholder:'Select sector...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Organizational Unit',
                    type:'text',
                    name:'organizationalUnit', 
                    value:user && user.organizationalUnit?user.organizationalUnit:'',
                    disabled:user?!user.employed:true,
                    placeholder:'Enter organizational unit...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Current Position',
                    type:'text',
                    name:'currentPosition', 
                    value:user && user.currentPosition?user.currentPosition:'',
                    disabled:user?!user.employed:true,
                    placeholder:'Enter current position...',
                    onChange:(e) => handleChange(e,onChange)
                },
                {
                    label:'Facility',
                    type:'text',
                    name:'facility', 
                    value:user && user.facility?user.facility:'',
                    disabled:user?!user.employed:true,
                    placeholder:'Enter facility...',
                    onChange:(e) => handleChange(e,onChange)
                }
            ]}
            minWidth={minWidth}
        />,
        <ImageUpload 
            key={6}
            Icon={MdImage}
            id='image'
            image={image}
            setImage={setImage}
        />
    ]

    useEffect(() => {
        ( async () => {
            setLoading(true);
            await RoleService.getRoles()
            .then((response) => {
                setLoading(false);
                if(response.content) {
                    setRoles(response.content);
                }  else {
                    setRoles([]);
                }
            })
            .catch((error) => {
                console.log(error.message);
                setLoading(false);
                setRoles([]);
            })
        }
        )();
    },[]);

  return (
    <FormDialog title='Add User' height={600}>
        <UserForm pages={pages} onSubmit={handleSubmit} errors={errors} message={message}/>
    </FormDialog>
  )
}

export default AddUser