import React, {useEffect,useState, useContext, useRef} from 'react';
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate } from 'react-router-dom';
import CPDService from '../services/CPDService';
import CPDRoleService from '../services/CPDRoleService';
import RoleService from '../services/RoleService';
import FormValidator, {useFormValidator} from './FormValidator';
import { IoCloseOutline } from "react-icons/io5";
import { BsCalendar4cpd } from "react-icons/bs";
import { PiTrashLight,PiPlusSquareFill,PiMinusSquareFill,PiXSquareFill  } from "react-icons/pi";
import { LiaPlusSolid,LiaMinusSolid } from "react-icons/lia";
import { AiOutlineEdit } from "react-icons/ai";
import { LiaAngleUpSolid,LiaAngleDownSolid } from "react-icons/lia";
import {CIUserSettings,CIcpd,CIAccount,CITransaction} from "./CoazIcons";
import Inputs from './Inputs';
import Input from './Input';
import Select from './Select';
import Checkbox from './Checkbox';
import Message from './Message';
import { Component } from 'react';
import Scrollable from './Scrollable';
import FormDialog from './FormDialog';

const EditCPD = ({id,update}) => {
    const {setLoading,setDialog,setReloadUser} = useContext(GlobalContext);
    const [cpd,setCpd] = useState(null);
    const [addingAuthority,setAddingAuthority] = useState(false);
    const [addingTariff,setAddingTariff] = useState(false);
    const [editingTariff,setEditingTariff] = useState(false);
    const [newTariff,setNewTariff] = useState(null);
    const [editTariff,setEditTariff] = useState(null);
    const [selectedTariff,setSelectedTariff] = useState(null);
    const [selectedAuth,setSelectedAuth] = useState(null);
    const [roles,setRoles] = useState([]);
    const [cpdRoles,setCpdRoles] = useState([]);
    const [addAuthority,setAddAuthority] = useState(null);
    const [message,setMessage] = useState({content:'',success:false});
    const minWidth = 240;
    const [inputWidth,setInputWidth] = useState(minWidth);
    const addButtonRef = useRef(null);


    let USDecimal = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const onChange = (e) => {
        const value = e.target.value;
        if(value === '') {
            setCpd({...cpd, [e.target.name]: null});
        } else {
            setCpd({...cpd, [e.target.name]: value});
        }
    }

    const add = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setAddingAuthority(true);
        if(addButtonRef.current) {
            let maxHeight = 200;
            let padding = 0;
            let itemHeight = 32;
            let avaiAuthorities = [];
            await CPDService.getAuthorities()
            .then((response) => {
                if(response instanceof Array && cpd && cpd.authorities) {
                    for(let authority of response) {
                        if(!cpd.authorities.find((cpdAuthority)=>{return cpdAuthority == authority})) {
                            avaiAuthorities.push(authority);
                        }
                    }
                }
            })
            .catch((error)=>{
                console.log(error.message);
            });
            let height = (padding * 2) + (avaiAuthorities.length * itemHeight);
            height = height > maxHeight? maxHeight:height;
            let rect = addButtonRef.current.getBoundingClientRect();
            let vh = document.documentElement.clientHeight;
            let top = rect.bottom;
            if (rect.bottom + height > vh) {
                top = rect.top - height;
            }
            setAddAuthority({Component:() => 
                <div onClick={e => e.stopPropagation()} 
                    style={{left:rect.left+'px',top:top+'px',height:height+'px',paddingTop:padding+'px',paddingBottom:padding+'px'}} 
                    className='fixed flex flex-col w-fit shadow-lg bg-[rgb(238,238,238)] overflow-y-auto'>
                    {avaiAuthorities.map((authority,i) => 
                        <div key={i} 
                            onClick={(e) => {
                                let cpdAuthorities = cpd.authorities;
                                cpdAuthorities.push(authority);
                                setCpd({...cpd,authorities:cpdAuthorities});
                                setAddAuthority(null);
                                setAddingAuthority(false);
                            }} 
                            style={{height:itemHeight+'px'}} 
                            className='flex w-full shrink-0 items-center font-jostBook text-sm text-[rgb(93,93,93)] hover:text-white hover:bg-[rgb(0,175,240)] cursor-pointer'>
                                <p className='px-8'>{authority}</p>
                        </div>
                    )}
                </div>
            });
        }
    }

    const navigate = useNavigate();

    const submit = async (e) => {
        setMessage({content:'',success:false});
        setLoading(true);
        CPDService.updateCPD(cpd)
        .then((response) => {
            setLoading(false);
            if(response.status) {
                if(response.status === 'SUCCESSFUL' && response.content) {
                    response.content.startDate = new Date(response.content.startDate);
                    update(response.content);
                    setDialog(null);
                } else {
                    setMessage({content:response.message,success:false});
                }
            } else  {
                setMessage({content:response,success:false});
            }
        })
        .catch((error) => {
            setMessage({content:error.message,success:false});
            setLoading(false);
        });
    };

    const [register,handleChange,handleSubmit,errors] = useFormValidator(submit);

    const inputs = [
        {
            label:'Shortname',
            type:'text', 
            name:'shortname',
            disabled:true,
            value:cpd && cpd.shortname?cpd.shortname:'',   
            placeholder:'Enter shortname...',
            onChange:(e) => {handleChange(e,onChange)}
        },
        {
            label:'Fullname',
            type:'text', 
            name:'fullname',
            disable:true,
            value:cpd && cpd.fullname?cpd.fullname:'',   
            placeholder:'Enter fullname...',
            onChange:(e) => {handleChange(e,onChange)}
        },
        {
            label:'Start Date',
            type:'date', 
            name:'startDate',
            disabled:true,
            value:cpd?cpd.startDate.toISOString().slice(0, 10):'', 
            onChange:(e) => {handleChange(e,onChange)}
        },
        {
            label:'Tariff Applicable',
            type:'checkbox',
            name:'employed',
            value:cpd?cpd.tariffApplicable:false,   
            onChange:(e) => {
                setCpd({...cpd,tariffApplicable: !cpd.tariffApplicable});
            }
        },
        {
            label:'Default Tariff',
            type:'select',
            options:() => {
                let options = [];
                cpd && cpd.tariffs && cpd.tariffs.map((option,i) => options.push(<option key={i} value={option.name}>{option.name}</option>));
                return options;
            },
            name:'defaultTariff', 
            disabled:cpd? !cpd.tariffApplicable:true,
            value:cpd && cpd.defaultTariff?cpd.defaultTariff.name:'',
            placeholder:'Select default tariff...',
            onChange:(e) => handleChange(e,(e) => {
                if(cpd && cpd.tariffs) {
                    let value = cpd.tariffs.find(tariff => {return tariff.name == e.target.value});
                    if(value) {
                        setCpd({...cpd,defaultTariff:value});
                    }
                }
            })
        }
    ]

    const getTariffCriteriaValueName = (key) => {
        let id = '';
        let value = null;
        if(addingTariff && newTariff && newTariff.criteria) {
            value = newTariff.criteria[key];
        } else if(editingTariff && editTariff && editTariff.criteria) {
            value = editTariff.criteria[key];
        }
        if(value && value.id) {
            id = value.id;
        }
        return id;
    }

    const onTariffCriteria = (e,key) => {
        let id = e.target.value;
        let value = null;
        if(key == 'role' && roles) {
            value = roles.find(role => {return role.id == id});
        } if(key == 'cpd_role' && cpdRoles) {
            value = cpdRoles.find(cpdRole => {return cpdRole.id == id})
        }
        if(addingTariff && newTariff) {
            let criteria = newTariff.criteria?newTariff.criteria:{}
            criteria[key] = value;
            setNewTariff({...newTariff,criteria:criteria});
        } else if(editingTariff && editTariff) {
            let criteria = editTariff.criteria?editTariff.criteria:{};
            criteria[key] = value;
            setNewTariff({...editTariff,criteria:criteria});
        }
        
    }

    useEffect(() => {
        ( async () => {
            setLoading(true);
            await CPDService.getCPD(id)
            .then((response) => {
                setLoading(false);
                if(response.content) {
                    response.content.startDate = new Date(response.content.startDate);
                    if(!response.content.defaultTariff && response.content.tariffs && response.content.tariffs.length > 0) {
                        response.content.defaultTariff = response.content.tariffs[0];
                    }
                    setCpd(response.content);
                }  else {
                    setCpd(null);
                    setDialog(null);
                }
                setReloadUser({reload:true});
            })
            .catch((error) => {
                console.log(error.message);
                setLoading(false);
                setCpd(null);
                setDialog(null);
                setReloadUser({reload:true});
            })

            await RoleService.getRoles()
            .then((response) => {
                setLoading(false);
                if(response.content) {
                    setRoles(response.content);
                }  else {
                    setRoles([]);
                }
            })
            .catch((error) => {
                setRoles(null);
            })

            await CPDRoleService.getCPDRoles()
            .then((response) => {
                setLoading(false);
                if(response.content) {
                    setCpdRoles(response.content);
                }  else {
                    setCpdRoles([]);
                }
            })
            .catch((error) => {
                setLoading(false);
                setCpdRoles([]);
            })
        }
        )();
    },[]);

    return (
        <div onClick={(e) => {
                setAddAuthority(null);
                setAddingAuthority(false);
            }} 
            >
            <FormDialog title='Edit CPD'>
                {cpd && <FormValidator>
                    <div className='flex flex-col w-full h-[450px] p-8'>
                        <Scrollable vertical={true}>
                            <div className='flex flex-col w-full h-auto shrink-0'>
                                <Inputs inputs={inputs} minWidth={minWidth} paddingX={0} spaceX={32} id='add_cpd' setCalcWidth={setInputWidth}/>
                                {cpd && cpd.tariffApplicable &&
                                    <div className='flex flex-col w-full h-auto space-y-2'>
                                        <p className='text-gray-600 text-sm capitalize'>Tariffs</p>
                                        <div className='flex flex-col w-full h-auto p-4 space-y-4 border rounded-lg'>
                                            {cpd && cpd.tariffs && cpd.tariffs.map((tariff,i) => 
                                            <div key={i} 
                                                    onClick={(e) => {
                                                    setSelectedTariff(tariff); 
                                                    }}
                                                    className='flex flex-row space-x-4'>
                                                    <div className={`flex flex-row w-full h-auto text-sm tracking-wider font-jostBook ${selectedTariff && selectedTariff.name === tariff.name?'text-[rgb(0,175,240)]':''} cursor-pointer`}>
                                                        <p className='w-1/3 whitespace-nowrap overflow-ellipsis capitalize'>
                                                            {tariff.name}
                                                        </p>
                                                        <p className='w-1/3 whitespace-nowrap overflow-ellipsis capitalize'>
                                                            {tariff.description}
                                                        </p>
                                                        <p className='w-1/3 text-right whitespace-nowrap overflow-ellipsis capitalize'>
                                                            {USDecimal.format(tariff.price)}
                                                        </p>
                                                    </div>
                                                </div>)
                                            }
                                        </div>
                                        <div className='flex flex-row w-auto h-auto space-x-4'>
                                            <button onClick={(e) => {
                                                    setAddingTariff(!addingTariff);
                                                    setNewTariff(addingTariff?null:{});
                                                    setSelectedTariff(null);
                                                    setEditingTariff(null);
                                                }} 
                                                className={`flex w-6 h-6 items-center justify-center ${addingTariff?'border border-[rgb(0,175,240)] text-[rgb(0,175,240)]':'bg-[rgb(0,175,240)] text-white hover:bg-[rgba(0,175,240,.7)]'}  rounded-sm`}>
                                                <LiaPlusSolid size={16}/>
                                            </button>
                                            <button onClick={(e) => {
                                                    setEditingTariff(!editingTariff);
                                                    setAddingTariff(false);
                                                    setNewTariff(null);
                                                    setEditTariff(selectedTariff);
                                                }} 
                                                disabled={!selectedTariff}
                                                className={`flex w-6 h-6 items-center justify-center ${editingTariff?'border border-[rgb(0,175,240)] text-[rgb(0,175,240)]':selectedTariff?'bg-[rgb(0,175,240)] text-white hover:bg-[rgba(0,175,240,.7)]':'text-white bg-[rgba(0,175,240,.7)]'}  rounded-sm`}>
                                                <AiOutlineEdit size={16}/>
                                            </button>
                                            <button onClick={(e) => {
                                                    let tariffs = cpd.tariffs.filter((tariff => tariff.name !== selectedTariff.name));
                                                    setCpd({...cpd,tariffs:tariffs});
                                                    setSelectedTariff(null);
                                                }} 
                                                disabled={!selectedTariff}
                                                className={`flex w-6 h-6 items-center justify-center ${selectedTariff?'bg-[rgb(0,175,240)] hover:bg-[rgba(0,175,240,.7)]':'bg-[rgba(0,175,240,.7)]'} text-white rounded-sm`}>
                                                <PiTrashLight size={16}/>
                                            </button>
                                        </div>
                                        <div style={{transition:'all .5s ease-in-out'}}
                                            onKeyDown={(e) => {
                                                    setMessage({content:'',success:false});
                                                    if (addingTariff && e.key === "Enter") {
                                                        if(!newTariff || !newTariff.name || newTariff.name === '' ||
                                                            !newTariff.price || newTariff.price === ''
                                                        ) {
                                                            setMessage({content:'Missing tariff fields',success:false});
                                                        } else {
                                                            let duplicate = cpd.tariffs.find(tariff => {return tariff.name == newTariff.name})
                                                            if(!duplicate) {
                                                                cpd.tariffs.push(newTariff);
                                                                setCpd({...cpd,tariffs:cpd.tariffs});
                                                                setNewTariff(null);
                                                                setSelectedTariff(null);
                                                                setAddingTariff(false);
                                                            }
                                                        }
                                                    }
                                                    if (editingTariff && e.key === "Enter") {
                                                        if(!editTariff || !editTariff.name || editTariff.name === '' ||
                                                            !editTariff.price || editTariff.price === ''
                                                        ) {
                                                            setMessage({content:'Missing tariff fields',success:false});
                                                        } else {
                                                            if(!editTariff.id) {
                                                                setMessage({content:'Tariff has to be saved first',success:false}); 
                                                            } else {
                                                                let duplicate = cpd.tariffs.find(tariff => {return tariff.name == editTariff.name})
                                                                if(!duplicate || (duplicate.id && duplicate.id === editTariff.id)) {
                                                                    let tariffs = cpd.tariffs.filter(tariff => tariff.id !== editTariff.id);
                                                                    tariffs.push(editTariff)
                                                                    setCpd({...cpd,tariffs:tariffs});
                                                                    setEditTariff(null);
                                                                    setSelectedTariff(null);
                                                                    setEditingTariff(false);
                                                                }
                                                            }
                                                        }
                                                    }
                                                    
                                                }
                                            }
                                            className={`flex flex-col w-auto ${addingTariff || editingTariff?'h-[420px] border-t':'h-0'} pt-1 space-y-4 overflow-hidden`}>
                                            <Input 
                                                label="Name"
                                                type="text"
                                                id="tariffName" 
                                                name="tariffName"  
                                                value={addingTariff?newTariff && newTariff.name?newTariff.name:'':editingTariff?editTariff && editTariff.name?editTariff.name:'':''}
                                                onChange= {(e) => {
                                                    if(addingTariff) {
                                                        setNewTariff({...newTariff,name:e.target.value})
                                                    } else if(editingTariff) {
                                                        setEditTariff({...editTariff,name:e.target.value});
                                                    }
                                                }}
                                                width={inputWidth}
                                            />
                                            <Input 
                                                label="Description"
                                                type="text"
                                                id="tariffDescription" 
                                                name="tariffDescription"  
                                                value={addingTariff?newTariff && newTariff.description?newTariff.description:'':editingTariff?editTariff && editTariff.description?editTariff.description:'':''}
                                                onChange= {(e) => {
                                                    if(addingTariff) {
                                                        setNewTariff({...newTariff,description:e.target.value});
                                                    } else if(editingTariff) {
                                                        setEditTariff({...editTariff,description:e.target.value});
                                                    }
                                                }}
                                                width={inputWidth}
                                            />
                                            <Input 
                                                label='Price'
                                                type='text'
                                                inputMode='numeric'
                                                id='price' 
                                                name='price'  
                                                value={addingTariff?newTariff && newTariff.price?newTariff.price:'':editingTariff?editTariff && editTariff.price?editTariff.price:'':''}
                                                onChange= {(e) => {
                                                    let value = e.target.value;
                                                    if(isNaN(value)) {
                                                        return;
                                                    }
                                                    if(addingTariff) {
                                                        setNewTariff({...newTariff,price:value});
                                                    } else if(editingTariff) {
                                                        setEditTariff({...editTariff,price:value});
                                                    }
                                                }}
                                                width={inputWidth}
                                            />
                                            <Select 
                                                label="Role Criteria"
                                                type="text"
                                                id="rolecCriteria" 
                                                name="roleCriteria"  
                                                value={getTariffCriteriaValueName('role')}
                                                onChange= {(e) => onTariffCriteria(e,'role')}
                                                width={inputWidth}
                                            >
                                                <option value=''>None</option>
                                                {roles && roles.map((role,i) => 
                                                    <option key={i} value={role.id}>{role.name}</option>
                                                )
                                                }
                                            </Select>
                                            <Select 
                                                label="CPD Role Criteria"
                                                type="text"
                                                id="cpdRolecCriteria" 
                                                name="cpdRoleCriteria"  
                                                value={getTariffCriteriaValueName('cpd_role')}
                                                onChange= {(e) => onTariffCriteria(e,'cpd_role')}
                                                width={inputWidth}
                                            >
                                                <option value=''>None</option>
                                                {cpdRoles && cpdRoles.map((role,i) => 
                                                    <option key={i} value={role.id}>{role.name}</option>
                                                )
                                                }
                                            </Select>
                                        </div>
                                    </div>
                                }
                                <Message message={message}/>
                                <button style={{'--width':inputWidth+'px'}} 
                                    onClick={handleSubmit} className='flex shrink-0 w-full lg:w-[var(--width)] h-10 mx-auto rounded-lg items-center justify-center bg-[rgb(0,175,240)] hover:bg-[rgba(0,175,240,.7)] text-white'>
                                    Submit
                                </button>
                            </div>
                        </Scrollable>
                    </div>
                </FormValidator>}
                {addAuthority && 
                <addAuthority.Component/>
                }
            </FormDialog>
        </div>
      )
}

export default EditCPD