import React,{useState,useEffect} from 'react'
import {CIJob} from "./CoazIcons"
import { FaRegCircle, FaRegDotCircle  } from "react-icons/fa";
import { LiaAngleUpSolid,LiaAngleDownSolid } from "react-icons/lia";
import { FaRegCircleDot } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";
import ExpandableList from "./ExpandableList"

const Careers = () => {
  const [countryFilter, setCountryFilter] = useState({property:'country',values:[]});
  const [filteredVacancies,setFilteredVacancies] = useState([]);
  const [selectAll,setSelectAll] = useState(false);
 
  let vacancies = [
    {
      position:'Manager – Finance and Administration',
      location:'LUSAKA, COAZ HQ',
      salary:'TBA',
      startMonth:'December 2024',
      details:`The Manager – Finance and Administration will be part of the management team of the
                organization She or he will specifically focus front to back on the operations carried out by
                the association. The main responsibility will include implementing, overseeing and refining
                of the operations strategy to the highest quality. The candidate will assist the organization to
                develop and document operational procedures in the Operations Policy (Manual), lead
                implementation and ensure compliance as the first line of defence. As part of the
                management team, under the supervision of the secretary General, s/he will provide input
                into the development of the overall institutional strategy and plans, and in its
                implementation.`,
      purpose:`Monitor and organize administrative procurement, aWendance checking, assets, and
                reception management according to the needs of various work of the branch. Cooperate with
                and assist the administrative manager to organize and provide administrative services and
                work support and ensure the normal operation of administrative work.`,
      responsibilities:[
        `Supporting the Secretary general in the planning process of association’s branch operations`,
        `Responsible for the standardized management of office administration while upholding corporate governance principles.`,
        `Overseeing administrative aspects of branches to assure efficient use of branch
        resources (staffing, fixed assets, time allocation – including travel, cost control, etc.)
        and escalating any efficiency issues to Secretary General.`,
        `Supervise and manage the procurement ledger registration.`,
        `According to the organization’s Employee Handbook and Hire Manual; supervise subordinates, verify attendance data, and ensure the timely and accurate attendance.`,
        `Responsible for the allocation and management of administrative processes, and ensure the timely and reasonable allocation of assets`,
        `Responsible for supervising and managing the registration and management of the
        standing books, organizing regular administrative inventory, ensuring the accuracy of
        the standing books data and ensuring the safety stock.`,
        `Manage the daily affairs of the department as well as the allocation and coordination
        of personnel to ensure the orderly work of the department`,
        `Responsible for the business guidance and performance appraisal of the staff of the department.`,
        `Manage calendar, scheduling & coordinate meetings for Association Executives and board members.`,
        `Serve as the first point of contact for office communications; manage correspondence and maintain professional relationships.`,
        `Prepare meeting agendas & take minutes.`,
        `Handle sensitive information with discretion and always maintain confidentiality.`,
        `Oversee office logistics & supplies.`,
        `Arrange domestic and international travel, including itineraries, accommodations, and transportation.`,
        `Assist in the preparation of presentations and reports for Executive board meetings and other functions`,
        `Providing input into the design of new products and services as required`,
        `Regular (weekly, monthly, quarterly, annual) reporting to Secretary general`,
        `Support the national executive and the investment board on selected projects.`
      ],
      qualifications:[
        `Bachelor’s degree in finance, Public Administration, Economics, international relations,`,
        `A master’s degree in a relevant field is an added advantage`,
        `2 or more years related experience.`,
        `Understanding of corporate governance and professional associations`,
        `Purchase of General office materials experience.`,
        `Good communication skills.`
      ],
      comment:`Our organization is a professional society representing over 20,000 clinical officers and
                clinical professionals in Zambia, both in private and public sector. We are committed to
                improving education, health, and economic security, with a focus on building leadership
                capacity and promoting inclusivity. It is an equal opportunity employer that values diversity
                and inclusion and we strive to develop and maintain a culture that honors the perspectives
                and identities of our employees, our communities, and those impacted by our work.`,
      howToApply:`How to Apply: Interested candidates should submit their CV, Qualifications and cover letter
                  to info@coaz.org and copy Joseph.kamfwa@yahoo.com by 25th November 2024. Please
                  indicate "Manager – Finance and Administration Application" in the subject line. Only
                  shortlisted candidates will be contacted.`,
      deadLine:'25th November 2024'
    },
    {
      position:'Finance officer',
      location:'LUSAKA, COAZ HQ',
      salary:'TBA',
      startMonth:'December 2024',
      summary:`The Finance officer reports to the Finance and AdministraDon manager, plays a crucial role in
                assisDng the Finance and Administration manager in implementing the organization’s
                Finance and administration, Human resources and Operations and management mandates`,
      responsibilities:[
        {
          department:'Finance and administration',
          items:[
              `Assist in Supporting day-to-day running of the financial, administrative and operations
                activities including overseeing systems for providing financial, administrative, and
                operational support to staff at all levels.`,
              `Assist to Provide recording, documenting, and reporting of all financial transactions,
                including the preparation of monthly financial expense reports, cash, and bank
                reconciliation statements, quarterly reports for the Board and key donors, Annual reports
                and partners, tracking of advances and liquidations, and review of monthly payroll,
                Prepare financial reports.`,
              `Assist in design, implement and maintain effective internal controls.`,
              `Perform Reconciliations for General Ledger Accounts including organization accounts`,
              `Assist in Preparation of all financial reports – Monthly Management Accounts in time
                before due date`,
              `Assist in the Preparation of annual budgets and forecasts and in financial planning`,
              `Preparation, submission and payment of all statutory obligations such as VAT, PAYE, NAPSA, NHIMA before the due date`,
              `Identify, control and escalate potential risks which may lead to increased costs`,
              `Forecast, manage and optimize financial resources ensuring all expenditure is in line with the agreed budget`,
              `Payroll Duties including preparation of salary schedules and issuance of pay slips`,
              `Prepare the annual audit file for External Auditors and manage Auditors queries`,
              `Assist in Performing routine financial analysis on program expenditures, documenting and
                monitoring overall performance, analysing trends, and identifying gaps to ensure efficient
                and sound financial management.`
          ]
        },
        {
          department:'Human resources',
          items:[
              `Support the Finance and Admin in providing support to COAZ district leaderships and
              branch staff in both offices on project procedures, including building their capacity in
              finance, administration, and project management.`,
              `Assist in overseeing the preparation of monthly pay sheets, payroll, including
              computation of income tax, member contributions, other income & other fringe
              benefits.`,
              `Assist in Monitoring effective maintenance of up-to-date personnel records.`
          ]
        },
        {
          department:'Operations and management',
          items:[
            `Assist in procurement efforts of the projects including logistics for projects activities,`,
            `Assist in Reviewing purchase requisitions and purchase orders, ensuring adequate funds,
            expense authorization, supporting documentation, the accuracy of amounts, and control
            over payments.`,
            `Supporting in ensuring the organization registration is up to date with the relevant
            authorities`,
            `Supporting the Finance and Admin in compliance with procurement and COAZ
            accounting policies and procedures, including adherence to grant and donor rules and
            regulations; Managing procedures and staff developing project inventory and property
            lists, ensuring their accuracy and timely reviews and updates;`,
            `Other duties as needed.`
          ]
        }
      ],
      qualifications:[
        `ACCA II, CIMA II, CA II and/or a Diploma or bachelor’s degree in Business
        Administration, Finance, Accounting, or related field.`,
        `ZICA accreditation.`,
        `2-3 years of accounting work experience.`,
        `Analytical thinker with strong conceptual and problem-solving skills.`,
        `Meticulous attention to detail with superb organizational skills`,
        `Ability to work under pressure and meet tight deadlines.`,
        `Ability to work independently and as part of a team.`,
        `Excellent report-writing, communication, and IT skills`,
        `Excellent communication and stakeholder engagement skills.`,
        `Proven track record in financial management and Strong organizational skills with the
        ability to multitask and prioritize.`
      ],
      comment:`Our organization is a professional society representing over 20,000 clinical officers and
                clinical professionals in Zambia, both in private and public sector. We are committed to
                improving education, health, and economic security, with a focus on building leadership
                capacity and promoting inclusivity. It is an equal opportunity employer that values diversity
                and inclusion and we strive to develop and maintain a culture that honors the perspectives
                and identities of our employees, our communities, and those impacted by our work.`,
      howToApply:`How to Apply: Interested candidates should submit their CV, Qualifications and cover letter
                  to info@coaz.org and copy Joseph.kamfwa@yahoo.com by 25th November 2024. Please
                  indicate "Finance Officer Application" in the subject line. Only shortlisted candidates will be
                  contacted.`,
      deadLine:'25th November 2024'
    },
    {
      position:'Assistant – Information Technology',
      location:'LUSAKA, COAZ HQ',
      salary:'TBA',
      startMonth:'December 2024',
      summary:`The Assistant – Information Technology should absolutely have good practical knowledge of
              information technology systems and functions in the financial services sector and the foresight
              to see how the related intricate day-to-day activities link with and support the achievement
              of the mission and vision of the clinical officers Association of Zambia.
              To assist with the monitoring and maintenance of the computer systems, networks, software
              and other information technology functions of the Branches and Head Office.
              Accountable for supporting the installation and configuration of computer systems, diagnosis
              of hardware/software faults and solving of technical and applications problems`,
      responsibilities:[
        `Provide user support, Install and configure computer hardware, operating systems
        and applications`,
        `Troubleshoot systems and network problems, diagnose and solve hardware and
        software faults`,
        `Website design and systems management`,
        `Managing the domain, user accounts and user access`,
        `Providing routine updates to the COAZ CPD and Learning platform`,
        `Monitoring of COAZ networks and maintenance of their security. Ensuring the security
        of the COAZ servers, data and software, Anti-Virus and Windows updates systems are
        working properly`,
        `Ensuring that backups are taken according to COAZ policies and procedures`,
        `Ensuring that all software is current and legal (properly licensed)`,
        `Supporting Branches with computer operations, connecting new Branches to the network`,
        `Maintaining firewall configuration and websites filters`,
        `Maintaining the inventory of computer equipment up to date`,
        `Purchasing and ensuring effective repair and maintenance of all IT equipment and
        peripherals`,
        `Deleting irrelevant information from computers and servers with appropriate Senior
        Management referral and authorization`,
        `Install computer applications required by the COAZ employees`,
        `Supporting the installation and maintenance of LAN and WAN network for the
        transaction software AMBS at the Head Office and Branches`,
        `Adding new employees into the system and cancelling access by former employees`,
        `Training ASA personnel to use the software or hardware required for their job`,
        `Coordinating and implementing network security measures`,
        `Maintaining and troubleshooting email configuration`,
        `Ensuring appropriate internet connectivity configuration of Computer hardware &
        software installation, troubleshooting and maintenance`,
        `Perform any other related duties and responsibilities as assigned.`
      ],
      qualifications:[
        `Diploma or degree in Computer Science, preferably in System Engineering`,
        `Minimum of 2-3 years’ related experience will be an added advantage`,
        `Strong analysis, organization and planning skills`,
        `Ability to work autonomously`,
        `Considerable knowledge of Microsoft operating systems and software`,
        `In depth technical knowledge of PC hardware`,
        `Excellent verbal and wriden communication skill`
      ],
      comment:`Our organization is a professional society representing over 20,000 clinical officers and
                clinical professionals in Zambia, both in private and public sector. We are committed to
                improving education, health, and economic security, with a focus on building leadership
                capacity and promoting inclusivity. It is an equal opportunity employer that values diversity
                and inclusion and we strive to develop and maintain a culture that honors the perspectives
                and identities of our employees, our communities, and those impacted by our work.`,
      howToApply:`How to Apply: Interested candidates should submit their CV, Qualifications and cover letter
                  to info@coaz.org and copy Joseph.kamfwa@yahoo.com by 25th November 2024. Please
                  indicate "Assistant – Information Technology Application" in the subject line. Only
                  shortlisted candidates will be contacted.`,
      deadLine:'25th November 2024'
    }
  ];

  const doFilter = () => {
    let includes = [];
    vacancies.forEach(vacancy => {
      let include = false;
      if(countryFilter.values.find(value => {return value === vacancy.country})) {
        include = true;
      }
      if(include) {
        includes.push(vacancy);
      }
    });
    setFilteredVacancies(includes);
  }

  return (
    <div className='w-full h-auto mt-8'>
        <div style={{boxShadow:'0 4px 20px 5px rgba(0, 0, 0, 0.05), 0 10px 20px 20px rgba(0, 0, 0, 0.03)'}} 
            className='flex flex-col lg:flex-row h-auto mx-auto mb-24 bg-[rgb(247,247,247)]'>
            <div className='flex flex-col space-y-4 items-center justify-center w-full lg:w-1/3 p-8'>
                <CIJob size={80} fill='black'/>
                <p className='text-3xl font-leBeauneNew font-semibold'>Jobs</p>
                <p className='text-lg tracking-wider font-jostBook text-[rgb(85,85,85)]'>
                    Explore job opportunities and career pathways available to clinical officers in Zambia. COAZ 
                    provides information on job openings, training programs, and professional development 
                    opportunities to support clinical officers in their career advancement and contribute to 
                    meaningful healthcare impact
                </p>
            </div>
            <div className='flex flex-col w-full lg:w-2/3 h-64 sm:h-96 lg:h-[512px] shrink-0 bg-[url(/public/images/img_34.jpg)] bg-cover'></div>
        </div>
        <div className='flex flex-col space-y-4 w-full h-auto mb-8'>
          <div className='flex flex-row space-x-8 w-full h-auto mx-auto'>
              <p className='text-3xl font-leBeauneNew'>Vacancies</p>
              <div className='w-full h-[1px] border-t border-[rgb(204,204,204)] my-auto'/>
          </div>
          <div className='flex flex-row w-full h-auto mx-auto'>
              {/* <div className='flex flex-col w-[30%] h-auto'>
                  <div className='flex flex-row justify-between w-full h-8 px-2'>
                      <p className='w-full m-auto font-jostSemi tracking-wider text-xs text-[rgb(120,120,120)] uppercase'>Filters</p>
                      <p className='m-auto font-jostSemi tracking-wider text-xs text-[rgb(120,120,120)] uppercase cursor-pointer'>Reset</p>
                  </div>
                  <Filter filter={countryFilter} vacancies={vacancies} selectAll={selectAll} setSelectAll={setSelectAll} doFilter={doFilter}/>
              </div> */}
              <Vacancies vacancies={vacancies}/>
          </div>
        </div>
    </div>
  )
}

export default Careers

const Vacancies = ({vacancies}) => {
  return (
    <div className='flex flex-col space-y-[1px] w-full h-auto mt-8'>
        {vacancies.map((vacancy,i) => 
          <ExpandableList key={i} name={vacancy.position}>
              <div className='flex flex-row w-full h-auto font-jostBook'>
                  <p className='flex text-[rgb(59,59,59)] font-jostSemi w-1/2 h-auto'>Position</p>
                  <p className='flex text-[rgb(100,100,100)] w-1/2 font-jostBook h-auto'>{vacancy.position}</p>
              </div>
              <div className='flex flex-row w-full h-auto'>
                  <p className='flex text-[rgb(59,59,59)] font-jostSemi w-1/2 h-auto'>Location</p>
                  <p className='flex text-[rgb(100,100,100)] w-1/2 font-jostBook h-auto'>{vacancy.location}</p>
              </div>
              <div className='flex flex-row w-full h-auto'>
                  <p className='flex text-[rgb(59,59,59)] font-jostSemi w-1/2 h-auto'>Salary</p>
                  <p className='flex text-[rgb(100,100,100)] w-1/2 font-jostBook h-auto'>{vacancy.salary}</p>
              </div>
              <div className='flex flex-row w-full h-auto'>
                  <p className='flex text-[rgb(59,59,59)] font-jostSemi w-1/2 h-auto'>Start Month</p>
                  <p className='flex text-[rgb(100,100,100)] w-1/2 font-jostBook h-auto'>{vacancy.startMonth}</p>
              </div>
              <div className='flex flex-col w-full h-auto space-y-8 py-8'>
                {vacancy.details && 
                <div className='flex flex-col w-full space-y-4 h-auto font-jostBook'>
                    <p className='flex text-[rgb(59,59,59)] font-jostSemi w-full h-auto uppercase'>Job details</p>
                    <p className='flex text-[rgb(100,100,100)] w-full h-auto font-jostBook'>{vacancy.details}</p>
                </div>}
                {vacancy.summary && 
                <div className='flex flex-col w-full space-y-4 h-auto'>
                    <p className='flex text-[rgb(59,59,59)] font-jostSemi w-full h-auto uppercase'>Job summary</p>
                    <p className='flex text-[rgb(100,100,100)] w-full h-auto font-jostBook'>{vacancy.summary}</p>
                </div>}
                <div className='flex flex-col w-full space-y-4 h-auto'>
                    <p className='flex text-[rgb(59,59,59)] font-jostSemi w-full h-auto uppercase'>Responsibilities</p>
                    {vacancy.responsibilities.map((responsibility,i) => 
                        <div key={i} className='flex flex-row space-x-4'>
                          <GoDotFill size={16} className='text-[rgb(85,85,85)] mt-[6px] shrink-0'/>
                          {responsibility.department?
                            <div className='flex flex-col'>
                                <p className='tracking-wider font-jostSemi text-[rgb(85,85,85)]'>{responsibility.department}</p>
                                {responsibility.items.map((item,i) => 
                                    <div className='flex flex-row space-x-4 mb-4'>
                                      <p className='text-[rgb(85,85,85)] shrink-0'>{i+1}</p>
                                      <p className='tracking-wider font-jostBook text-[rgb(85,85,85)]'>{item}</p>
                                    </div>
                                )}
                            </div>:
                            <p className='tracking-wider font-jostBook text-[rgb(85,85,85)]'>{responsibility}</p>
                          }
                          
                        </div>
                    )}
                </div>
                <div className='flex flex-col w-full space-y-4 h-auto'>
                    <p className='flex text-[rgb(59,59,59)] font-jostSemi w-full h-auto uppercase'>Qualifications & Experience Required</p>
                    {vacancy.qualifications.map((qualification,i) => 
                        <div className='flex flex-row space-x-4'>
                          <GoDotFill size={16} className='text-[rgb(85,85,85)] mt-[6px] shrink-0'/>
                          <p className='tracking-wider font-jostBook text-[rgb(85,85,85)]'>{qualification}</p>
                        </div>
                    )}
                </div>
                <p className='flex text-[rgb(100,100,100)] w-full h-auto italic'>{vacancy.comment}</p>
                <div className='flex flex-col w-full space-y-4 h-auto'>
                    <p className='flex text-[rgb(59,59,59)] font-jostSemi w-full h-auto uppercase'>Application Instructions</p>
                    <p className='flex text-[rgb(100,100,100)] w-full h-auto font-jostBook'>{vacancy.howToApply}</p>
                </div>
                <div className='flex flex-row w-full h-auto space-x-4'>
                    <p className='flex text-[rgb(59,59,59)] font-jostSemi h-auto'>{`Application Deadline :`}</p>
                    <p className='flex text-[rgb(100,100,100)] font-jostBook h-auto'>{vacancy.deadLine}</p>
                </div>
              </div>
          </ExpandableList>
        )}
    </div>
  )
}

const Filter = ({filter,vacancies,selectAll,setSelectAll,doFilter}) => {
  const [expanded,setExpanded] = useState(false);
  const [filterItems,setFilterItems] = useState([]);

  useEffect(() => {
    for (const vacancy of vacancies) {
      if(!filterItems.find((filterItem) => {return filterItem.value === vacancy[filter.property]})) {
        filterItems.push({value:vacancy[filter.property],selected:false});
      }
    }
  },[])

  return (
    <div className='flex flex-col space-y-1 w-full h-auto bg-[rgb(247,247,247)]'>
        <div onClick={(e) => setExpanded(!expanded)} 
            className='flex flex-row w-full h-8 shrink-0 justify-between px-2 cursor-pointer'>
            <p className='w-full m-auto font-jostBook tracking-wider text-sm text-[rgb(120,120,120)] capitalize'>
              {filter.property}
            </p>
            <div className='w-4 h-auto m-auto text-[rgb(120,120,120)]'>
                {expanded? <LiaAngleUpSolid size={16}/>:<LiaAngleDownSolid size={16}/>}
            </div>
        </div>
        <div style={{transition:'all 0.3s ease-in-out'}}
            className={`flex flex-col w-full ${expanded?'h-auto':'h-0'} overflow-hidden`}>
            {filterItems.map((filterItem,i) => <FilterItem key={i} filterItem={filterItem} filter={filter} doFilter={doFilter}/>)}
            <All selectAll={selectAll} setSelectAll={setSelectAll}/>
        </div>
    </div>
  )
}

const FilterItem = ({filterItem,filter,doFilter}) => {

  const handleClick = (e) => {
    e.preventDefault();
    filterItem.selected = !filterItem.selected;
    if(filterItem.selected) {
      filter.values.push(filterItem.value);
    } else {
      filter.values = filter.values.filter((value) => {return value !== filterItem.value});
    }
    doFilter();
  }
  return (
    <div onClick={handleClick}
      className='flex flex-row space-x-4 px-2 w-full h-8 cursor-pointer'>
      {filterItem.selected?
        <FaRegDotCircle  size={14} className='m-auto text-[rgb(0,175,240)]'/>:
        <FaRegCircle  size={14} className='m-auto text-[rgb(120,120,120)]'/>
      }
      <p className='w-full m-auto font-jostBook tracking-wider text-sm text-[rgb(120,120,120)] capitalize'>
        {filterItem.value}
      </p>
    </div>
  )
}

const All = ({selectAll,setSelectAll}) => {
  const handleClick = (e) => {
    e.preventDefault();
    setSelectAll(!selectAll);
  }
  return (
    <div onClick={handleClick}
      className='flex flex-row space-x-4 px-2 w-full h-8 cursor-pointer'>
      {selectAll?
        <FaRegDotCircle  size={14} className='m-auto text-[rgb(0,175,240)]'/>:
        <FaRegCircle  size={14} className='m-auto text-[rgb(120,120,120)]'/>
      }
      <p className='w-full m-auto font-jostBook tracking-wider text-sm text-[rgb(120,120,120)] capitalize'>
        All
      </p>
    </div>
  )
}