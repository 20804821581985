import React, {useState, useEffect,useRef, useContext} from 'react';
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate,useOutletContext } from 'react-router-dom';
import UserService from '../services/UserService';
import RoleService from '../services/RoleService';
import EventService from '../services/EventService';
import { RiLockPasswordFill } from "react-icons/ri";
import FormValidator, {useFormValidator} from './FormValidator';
import Input from './Input';
import {sex,idTypes} from '../data';
import Message from './Message';
import YesNoDialog from './YesNoDialog';
import Select from './Select';
import PaymentService from '../services/PaymentService';

const EventRegistration = () => {
    const {setLoading,setDialog} = useContext(GlobalContext);
    const [receivableid,userid] = useOutletContext();
    const [event,setEvent] = useState(null);
    const [user,setUser] = useState({
        username:'',
        firstname:'',
        lastname:'',
        middlename:'',
        email:'',
        sex:sex[0],
        dateOfBirth: new Date(),
        idType:idTypes[0],
        idNumber:'',
        password:'',
        confirmPassword:'',
        role:null
    }) 
    const [message,setMessage] = useState({content:'',success:false}); 
    const navigate = useNavigate();

    const [itemSize,setItemSize] = useState({});

    const onChange = (e) => {
        setUser({...user,[e.target.name]:e.target.value});
    }

    const onDate = (e) => {
        const value = e.target.value;
        setUser({...user, [e.target.name]: new Date(value)});
    };

    const save = async (saveUser) => {
        UserService.saveUserSelf(saveUser,false)
        .then((response) => {
            if(response.status && response.status === 'SUCCESSFUL' && response.content) {
                login(response.content.username,user.password);
            } else  {
                setMessage({content:response,success:false});
            }
        })
        .catch((error) => {
            console.log(error.message);
            setMessage({content:error.message,success:false});
        });
    }

    const login = async (username,password) => {
        UserService.login(username,password)
        .then((response) => {
            if(response.message && response.message.toLowerCase().includes('successfull')) {
                makePayment();
            } else {
                console.log(response.message)
                setMessage({content:response.message,success:true});
            }
        })
        .catch((error) => {
            console.log(error.message)
            setMessage({content:error.message,success:false});
        });
    }

    const makePayment = async () => {
        await PaymentService.getPaymentTariff(event.id,null,false)
        .then(async (response) => {
            if(response.status && response.status === 'SUCCESSFUL' && response.content) {
                alert('payment')
                navigate(`payment/${event?event.id:'0'}/${response.content.id}`);
            } else {
                console.log(response.message)
                setMessage({content:response.message,success:true});
            }
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
            console.log(error.message);
        });
    }

    const submit = async (e) => {
        setLoading(true);
        setMessage({content:'',success:true});
        if(user.password === user.confirmPassword) {
            await RoleService.getRole('event_member')
            .then(async (response) => {
                if(response.status && response.status === 'SUCCESSFUL' && response.content) {
                    user.role = response.content;
                    save(user);
                } else {
                    console.log(response.message)
                    setMessage({content:response.message,success:true});
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error.message);
            });
        } else {
            setLoading(false);
            setMessage({content:"Password and confirm password didn't match, try again!",success:false});
        }
    };

    const [register,handleChange,handleSubmit,errors] = useFormValidator(submit);

    const inputs = [
        <Input 
            label="Username"
            type="text" 
            id="username" 
            name="username"  
            value={user.username}
            placeholder="Enter username..."
            onChange= {(e) => handleChange(e,onChange)}
            register={register}
            errors={errors}
            width={itemSize.width}
        />,
        <Input 
            label="Firstname"
            type="text" 
            id="firstname" 
            name="firstname"  
            value={user.firstname}
            placeholder="Enter firstname..."
            onChange= {(e) => handleChange(e,onChange)}
            register={register}
            errors={errors}
            width={itemSize.width}
        />,
        <Input 
            label="Lastname"
            type="text" 
            id="lastname" 
            name="lastname"  
            value={user.lastname}
            placeholder="Enter lastname..."
            onChange= {(e) => handleChange(e,onChange)}
            register={register}
            errors={errors}
            width={itemSize.width}
        />,
        <Input 
            label="Middlename"
            type="text" 
            id="middlename" 
            name="middlename"  
            value={user.middlename}
            placeholder="Enter middlename..."
            onChange= {(e) => handleChange(e,onChange)}
            width={itemSize.width}
        />,
        <Input 
            label="Email"
            type="text"
            id="email" 
            name="email"  
            value={user.email}
            placeholder="Enter email..."
            onChange= {(e) => handleChange(e,onChange)}
            register={register}
            errors={errors}
            width={itemSize.width}
        />,
        <Select
            label="Sex"
            type="text"
            id="sex" 
            name="sex"  
            value={user.sex}
            placeholder="Select sex..."
            onChange= {(e) => handleChange(e,onChange)}
            register={register}
            errors={errors}
            width={itemSize.width}
        >
            {sex.map((option,i) => <option key={i} value={option}>{option}</option>)}
        </Select>,
        <Input 
            label="Date of Birth"
            type="date"
            id="dateOfBirth" 
            name="dateOfBirth"  
            value={user.dateOfBirth?user.dateOfBirth.toISOString().slice(0, 10):null}
            onChange= {(e) => handleChange(e,onDate)}
            register={register}
            errors={errors}
            width={itemSize.width}
        />,
        <Select
            label="Id Type"
            type="text"
            id="idType" 
            name="idType"  
            value={user.idType}
            placeholder="Select id type..."
            onChange= {(e) => handleChange(e,onChange)}
            register={register}
            errors={errors}
            width={itemSize.width}
        >
            {idTypes.map((option,i) => <option key={i} value={option}>{option}</option>)}
        </Select>,
        <Input 
            label="Id Number"
            type="text"
            id="idNumber" 
            name="idNumber"  
            value={user.idNumber}
            placeholder="Enter id number..."
            onChange= {(e) => handleChange(e,onChange)}
            register={register}
            errors={errors}
            width={itemSize.width}
        />,
        <Input 
            label="Password"
            type="password"
            id="password" 
            name="password"  
            value={user.password}
            placeholder="Enter password..."
            onChange= {(e) => handleChange(e,onChange)}
            register={register}
            errors={errors}
            width={itemSize.width}
        />,
        <Input 
            label="Confirm Password"
            type="password"
            id="confirmPassword" 
            name="confirmPassword"  
            value={user.confirmPassword}
            placeholder="Confirm password..."
            onChange= {(e) => handleChange(e,onChange)}
            register={register}
            errors={errors}
            width={itemSize.width}
        />
    ]

    const mainContainerRef = useRef(null);
    const [columns,setColumns] = useState(inputs.length);
    let paddingX = 32;
    let spaceX = 32;

    const calWidth = (pw,mw,pa,sp,cols) => {
        if(cols === 0){
            return {w:0,cols:0};
        }
        let w = 0;
        let aw = pw - (pa*2) - (sp * (cols - 1));
        w = aw/cols;
        if(w < mw && cols > 1) {
            cols -= 1;
            return calWidth(pw,mw,pa,sp,cols)
        } else {
            return {w,cols};
        }
    }

    useEffect(() => {
        ( async () => {
            await EventService.getEvent(receivableid)
            .then(async (response) => {
                if(response.status && response.status === 'SUCCESSFUL' && response.content) {
                    setEvent(response.content);
                } else {
                    navigate('/home')
                }
            })
            .catch((error) => {
                console.log(error.message);
                navigate('/home')
            })
        }
        )(); 
        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                let parentWidth = entry.target.getBoundingClientRect().width;
                let minWidth = 224;
                let {w,cols} = calWidth(parentWidth,minWidth,paddingX,spaceX,columns); 
                let h = 80;
                setItemSize({width:w,height:h});
                setColumns(cols);
                
            }
        });

        if(mainContainerRef.current) {
            observer.observe(mainContainerRef.current)
        }
        return () => {
            observer.disconnect();
        }
    },[]);

    return (
        <FormValidator>
            <div ref={mainContainerRef} 
                style={{paddingLeft:paddingX+'px',paddingRight:paddingX+'px'}}
                className='flex flex-col w-full h-full no-scrollbar space-y-8 overflow-auto'>
                <RiLockPasswordFill size={64} className='flex shrink-0 mx-auto text-[rgba(84,84,87,.7)]'/>
                {inputs && (
                    (() => {
                        const rows = [];
                        let rowKey = 0;
                        for (let i = 0; i < inputs.length;) {
                            const row = [];
                            for (let j = 0; j < columns; j++) {
                                if(i < inputs.length) {
                                    row.push(inputs[i]);
                                    i++;
                                } else {
                                    break;
                                }
                            }
                            rows.push(<div key={rowKey} className={`flex flex-row w-full h-auto items-center shrink-0 space-x-8`}>{row}</div>);
                            rowKey++;
                        }
                        return rows;
                    })()
                )}
                <Message message={message}/>
                <button style={{width:itemSize.width+'px'}} 
                    onClick={handleSubmit} className='flex shrink-0 h-10 mx-auto rounded-lg items-center justify-center bg-[rgb(0,175,240)] hover:bg-[rgba(0,175,240,.7)] text-white'>
                    Submit
                </button>
            </div>
        </FormValidator>
    )
}

export default EventRegistration
