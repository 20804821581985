import React,{useEffect,useState,useContext} from 'react'
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate } from 'react-router-dom';
import UserService from '../services/UserService'
import AccountService from '../services/AccountService'
import Table from './Table'
import { LiaMoneyBillWaveSolid } from "react-icons/lia";

const Accounts = () => {
  const {setDialog,hasAuthority,setLoading,setReloadUser} = useContext(GlobalContext);
    const [subDialog,setSubDialog] = useState(null);

    const navigate = useNavigate();

    const columns = [
        {
            name:'name',
            label:'Name',
            type:'text',
            filter: ''
        },
        {
            name:'holder',
            label:'Holder',
            type:'text',
            Filter: ''
        },
        {
            name:'holderType',
            label:'Holder Type',
            type:'text',
            Filter: ''
        },
        {
            name:'balance',
            label:'Balance',
            type:'number',
            Filter: ''
        }
    ]

    const getAccounts = async (filter,page) => {
        let object = {}
        let current = null;
        await UserService.getCurrentUser()
        .then((response) => {
            if(response.content) {
                current = response.content;
            } 
        })
        .catch((error) => {
            console.log(error.message);
        });

        if(current && hasAuthority(current,'READ_ACCOUNT')) {
            await AccountService.getAccounts(page,false)
            .then((response) => {
                if(response.content) {
                    let accounts = [];
                    for(let account of response.content) {
                        let holder = '';
                        let holderType = '';
                        if(account.holder) {
                            holder = account.holder.name;
                            holderType = account.holder.classname;
                        }
                        accounts.push({
                            id:account.id,
                            name:account.name,
                            holder:holder,
                            holderType:holderType,
                            balance:account.balance
                        })
                    }
                    object = {
                      status:'SUCCESSFUL',
                      content:accounts,
                      pageNo:response.pageNo,
                      pageSize:response.pageSize,
                      totalElements:response.totalElements,
                      totalPages:response.totalPages,
                      last:response.last
                  }
                } else {
                    object = {status:'FAILED',content:[]};
                }
            })
            .catch((error) => {
                console.log(error.message);
                object = {status:'ERROR',content:[]};
            });
        }
        return object;
    }

    const getButtons = (user) => {
        let buttons = [
            {
                name:'Open Account',
                onClick:(e,selected) => {
                    if(selected) {
                        navigate(`/settings/accounts/${selected.id}`)
                    }
                },
                disabled:(selected) => {
                    return selected?false:true;
                },
                Icon:LiaMoneyBillWaveSolid
            }
        ];   
        return buttons;
    }
  return (
    <div className='relative flex w-full h-full'>
        <div className='flex w-full h-full no-scrollbar overflow-y-auto'>
            <Table columns={columns} retrieveRecords={getAccounts} getButtons={getButtons}/>
        </div>
    </div>
  )
}

export default Accounts