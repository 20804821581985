import React, {useEffect,useState, useContext, useRef} from 'react';
import { GlobalContext } from '../contexts/GlobalContext';
import { useNavigate } from 'react-router-dom';
import RoleService from '../services/RoleService';
import FormValidator, {useFormValidator} from './FormValidator';
import { IoCloseOutline } from "react-icons/io5";
import { BsCalendar4Role } from "react-icons/bs";
import { PiTrashLight,PiPlusSquareFill,PiMinusSquareFill,PiXSquareFill  } from "react-icons/pi";
import { LiaPlusSolid,LiaMinusSolid } from "react-icons/lia";
import { AiOutlineEdit } from "react-icons/ai";
import { LiaAngleUpSolid,LiaAngleDownSolid } from "react-icons/lia";
import {CIUserSettings,CIRole,CIAccount,CITransaction} from "./CoazIcons";
import Inputs from './Inputs';
import Input from './Input';
import Scrollable from './Scrollable';
import Message from './Message';
import FormDialog from './FormDialog';
import { Component } from 'react';

const AddRole = ({reloadTable}) => {
    const {setLoading,setDialog,setReloadUser} = useContext(GlobalContext);
    const [role,setRole] = useState({
      name:'',
      displayName:'',
      description:'',
      reserved:false,
      tariffApplicable:false,
      defaultTariff:null,
      authorities:[],
      tariffs:[]
    });
    const [addingAuthority,setAddingAuthority] = useState(false);
    const [addingTariff,setAddingTariff] = useState(false);
    const [editingTariff,setEditingTariff] = useState(false);
    const [newTariff,setNewTariff] = useState(null);
    const [editTariff,setEditTariff] = useState(null);
    const [selectedTariff,setSelectedTariff] = useState(null);
    const [selectedAuth,setSelectedAuth] = useState(null);
    const [authorities,setAuthorities] = useState([]);
    const [addAuthority,setAddAuthority] = useState(null);
    const [message,setMessage] = useState({content:'',success:false});
    const minWidth = 240;
    const [inputWidth,setInputWidth] = useState(minWidth);
    const addButtonRef = useRef(null);

    const submit = async (e) => {
        setMessage({content:'',success:false});
        setLoading(true);
        RoleService.saveRole(role)
        .then((response) => {
            setLoading(false);
            if(response.status) {
                if(response.status === 'SUCCESSFUL' && response.content) {
                    reloadTable && reloadTable();
                    setDialog(null);
                } else {
                    setMessage({content:response.message,success:false});
                }
            } else  {
                setMessage({content:response,success:false});
            }
        })
        .catch((error) => {
            setMessage({content:error.message,success:false});
            setLoading(false);
        });
    };

    const [register,handleChange,handleSubmit,errors] = useFormValidator(submit);

    const inputs = [
        {
            label:'name',
            type:'text', 
            name:'name',
            value:role && role.name?role.name:'',   
            placeholder:'Enter name...',
            onChange:(e) => {handleChange(e,onChange)},
            register:register,
            errors:errors
        },
        {
            label:'Dislay Name',
            type:'text', 
            name:'displayName',
            value:role && role.displayName?role.displayName:'',   
            placeholder:'Enter display name...',
            onChange:(e) => {handleChange(e,onChange)},
            register:register,
            errors:errors
        },
        {
            label:'Description',
            type:'text', 
            name:'description',
            value:role && role.description?role.description:'',   
            placeholder:'Enter description...',
            onChange:(e) => {handleChange(e,onChange)}
        },
        {
            label:'Tariff Applicable',
            type:'checkbox',
            name:'tariffApplicable',
            value:role?role.tariffApplicable:false,   
            onChange:(e) => {
                setRole({...role,tariffApplicable: !role.tariffApplicable});
            }
        },
        {
            label:'Reserved',
            type:'checkbox',
            name:'reserved',
            value:role?role.reserved:false,   
            onChange:(e) => {
                setRole({...role,reserved: !role.reserved});
            }
        },
        {
            label:'Default Tariff',
            type:'select',
            options:() => {
                let options = [];
                role && role.tariffs && role.tariffs.map((option,i) => options.push(<option key={i} value={option.name}>{option.name}</option>));
                return options;
            },
            name:'defaultTariff', 
            disabled:role? !role.tariffApplicable:true,
            value:role && role.defaultTariff?role.defaultTariff.name:'',
            placeholder:'Select default tariff...',
            onChange:(e) => handleChange(e,(e) => {
                if(role && role.tariffs) {
                    let value = role.tariffs.find(tariff => {return tariff.name == e.target.value});
                    if(value) {
                        setRole({...role,defaultTariff:value});
                    }
                }
            })
        }
    ]

    let USDecimal = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const onChange = (e) => {
        const value = e.target.value;
        if(value === '') {
            setRole({...role, [e.target.name]: null});
        } else {
            setRole({...role, [e.target.name]: value});
        }
    }

    const add = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        setAddingAuthority(true);
        if(addButtonRef.current) {
            let maxHeight = 200;
            let padding = 0;
            let itemHeight = 32;
            let avaiAuthorities = [];
            await RoleService.getAuthorities()
            .then((response) => {
                if(response instanceof Array && role && role.authorities) {
                    for(let authority of response) {
                        if(!role.authorities.find((roleAuthority)=>{return roleAuthority == authority})) {
                            avaiAuthorities.push(authority);
                        }
                    }
                }
            })
            .catch((error)=>{
                console.log(error.message);
            });
            let height = (padding * 2) + (avaiAuthorities.length * itemHeight);
            height = height > maxHeight? maxHeight:height;
            let rect = addButtonRef.current.getBoundingClientRect();
            let vh = document.documentElement.clientHeight;
            let top = rect.bottom;
            if (rect.bottom + height > vh) {
                top = rect.top - height;
            }
            setAddAuthority({Component:() => 
                <div onClick={e => e.stopPropagation()} 
                    style={{left:rect.left+'px',top:top+'px',height:height+'px',paddingTop:padding+'px',paddingBottom:padding+'px'}} 
                    className='fixed flex flex-col w-fit shadow-lg bg-[rgb(238,238,238)] overflow-y-auto'>
                    {avaiAuthorities.map((authority,i) => 
                        <div key={i} 
                            onClick={(e) => {
                                let roleAuthorities = role.authorities;
                                roleAuthorities.push(authority);
                                setRole({...role,authorities:roleAuthorities});
                                setAddAuthority(null);
                                setAddingAuthority(false);
                            }} 
                            style={{height:itemHeight+'px'}} 
                            className='flex w-full shrink-0 items-center font-jostBook text-sm text-[rgb(93,93,93)] hover:text-white hover:bg-[rgb(0,175,240)] cursor-pointer'>
                                <p className='px-8'>{authority}</p>
                        </div>
                    )}
                </div>
            });
        }
    }

    const navigate = useNavigate();

    useEffect(() => {
    },[]);

    return (
        <div onClick={(e) => {
                setAddAuthority(null);
                setAddingAuthority(false);
            }} 
            >
            <FormDialog title='Add Role' height={500}>
                {role && <FormValidator>
                    <div className='flex flex-col w-full h-[450px] p-8'>
                        <Scrollable>
                            <div className='flex flex-col w-full h-auto shrink-0'>
                                <Inputs inputs={inputs} minWidth={minWidth} paddingX={0} spaceX={32} id='add_role' setCalcWidth={setInputWidth}/>
                                <div className='flex flex-col w-full h-auto space-y-2'>
                                    <p className='text-gray-600 text-sm capitalize'>Authorities</p>
                                    <div className='flex flex-col w-full h-auto p-4 space-y-4 border rounded-lg'>
                                        {role && role.authorities && role.authorities.map((authority,i) => 
                                        <div key={i} 
                                            onClick={(e) => {
                                                setSelectedAuth(authority); 
                                            }}
                                            className='flex flex-row space-x-4'>
                                            <p className={`flex flex-row w-full h-auto text-sm tracking-wider whitespace-nowrap overflow-ellipsis capitalize font-jostBook ${selectedAuth === authority?'text-[rgb(0,175,240)]':''} cursor-pointer`}>
                                                {authority}
                                            </p>
                                        </div>)
                                        }
                                    </div>
                                    <div className='flex flex-row w-auto h-auto space-x-4'>
                                        <button ref={addButtonRef} 
                                            onClick={(e) => {
                                                add(e);
                                            }} 
                                            className={`flex w-6 h-6 items-center justify-center ${addingAuthority?'border border-[rgb(0,175,240)] text-[rgb(0,175,240)]':'bg-[rgb(0,175,240)] text-white hover:bg-[rgba(0,175,240,.7)]'}  rounded-sm`}>
                                            <LiaPlusSolid size={16}/>
                                        </button>
                                        <button onClick={(e) => {
                                                let authorities = role.authorities.filter((roleAuthority => roleAuthority !== selectedAuth));
                                                setRole({...role,authorities:authorities});
                                                setSelectedAuth(null);
                                            }} 
                                            disabled={!selectedAuth}
                                            className={`flex w-6 h-6 items-center justify-center ${selectedAuth?'bg-[rgb(0,175,240)] hover:bg-[rgba(0,175,240,.7)]':'bg-[rgba(0,175,240,.7)]'} text-white rounded-sm`}>
                                            <PiTrashLight size={16}/>
                                        </button>
                                    </div>
                                </div>
                                {role && role.tariffApplicable &&
                                    <div className='flex flex-col w-full h-auto space-y-2'>
                                        <p className='text-gray-600 text-sm capitalize'>Tariffs</p>
                                        <div className='flex flex-col w-full h-auto p-4 space-y-4 border rounded-lg'>
                                            {role && role.tariffs && role.tariffs.map((tariff,i) => 
                                            <div key={i} 
                                                    onClick={(e) => {
                                                    setSelectedTariff(tariff); 
                                                    }}
                                                    className='flex flex-row space-x-4'>
                                                    <div className={`flex flex-row w-full h-auto text-sm tracking-wider font-jostBook ${selectedTariff && selectedTariff.name === tariff.name?'text-[rgb(0,175,240)]':''} cursor-pointer`}>
                                                        <p className='w-1/3 whitespace-nowrap overflow-ellipsis capitalize'>
                                                            {tariff.name}
                                                        </p>
                                                        <p className='w-1/3 whitespace-nowrap overflow-ellipsis capitalize'>
                                                            {tariff.description}
                                                        </p>
                                                        <p className='w-1/3 text-right whitespace-nowrap overflow-ellipsis capitalize'>
                                                            {USDecimal.format(tariff.price)}
                                                        </p>
                                                    </div>
                                                </div>)
                                            }
                                        </div>
                                        <div className='flex flex-row w-auto h-auto space-x-4'>
                                            <button onClick={(e) => {
                                                    setAddingTariff(!addingTariff);
                                                    setNewTariff(null);
                                                    setSelectedTariff(null);
                                                    setEditingTariff(null);
                                                }} 
                                                className={`flex w-6 h-6 items-center justify-center ${addingTariff?'border border-[rgb(0,175,240)] text-[rgb(0,175,240)]':'bg-[rgb(0,175,240)] text-white hover:bg-[rgba(0,175,240,.7)]'}  rounded-sm`}>
                                                <LiaPlusSolid size={16}/>
                                            </button>
                                            <button onClick={(e) => {
                                                    setEditingTariff(!editingTariff);
                                                    setAddingTariff(false);
                                                    setNewTariff(null);
                                                    setEditTariff(selectedTariff);
                                                }} 
                                                disabled={!selectedTariff}
                                                className={`flex w-6 h-6 items-center justify-center ${editingTariff?'border border-[rgb(0,175,240)] text-[rgb(0,175,240)]':selectedTariff?'bg-[rgb(0,175,240)] text-white hover:bg-[rgba(0,175,240,.7)]':'text-white bg-[rgba(0,175,240,.7)]'}  rounded-sm`}>
                                                <AiOutlineEdit size={16}/>
                                            </button>
                                            <button onClick={(e) => {
                                                    let tariffs = role.tariffs.filter((tariff => tariff.name !== selectedTariff.name));
                                                    setRole({...role,tariffs:tariffs});
                                                    setSelectedTariff(null);
                                                }} 
                                                disabled={!selectedTariff}
                                                className={`flex w-6 h-6 items-center justify-center ${selectedTariff?'bg-[rgb(0,175,240)] hover:bg-[rgba(0,175,240,.7)]':'bg-[rgba(0,175,240,.7)]'} text-white rounded-sm`}>
                                                <PiTrashLight size={16}/>
                                            </button>
                                        </div>
                                        <div style={{transition:'all .5s ease-in-out'}}
                                            onKeyDown={(e) => {
                                                    setMessage({content:'',success:false});
                                                    if (addingTariff && e.key === "Enter") {
                                                        if(!newTariff || !newTariff.name || newTariff.name === '' ||
                                                            !newTariff.price || newTariff.price === ''
                                                        ) {
                                                            setMessage({content:'Missing tariff fields',success:false});
                                                        } else {
                                                            let duplicate = role.tariffs.find(tariff => {return tariff.name == newTariff.name})
                                                            if(!duplicate) {
                                                                role.tariffs.push(newTariff);
                                                                setRole({...role,tariffs:role.tariffs});
                                                                setNewTariff(null);
                                                                setSelectedTariff(null);
                                                                setAddingTariff(false);
                                                            }
                                                        }
                                                    }
                                                    if (editingTariff && e.key === "Enter") {
                                                        if(!editTariff || !editTariff.name || editTariff.name === '' ||
                                                            !editTariff.price || editTariff.price === ''
                                                        ) {
                                                            alert(JSON.stringify(editTariff));
                                                            setMessage({content:'Missing tariff fields',success:false});
                                                        } else {
                                                            if(!editTariff.id) {
                                                                setMessage({content:'Tariff has to be saved first',success:false}); 
                                                            } else {
                                                                let duplicate = role.tariffs.find(tariff => {return tariff.name == editTariff.name})
                                                                if(!duplicate || (duplicate.id && duplicate.id === editTariff.id)) {
                                                                    let tariffs = role.tariffs.filter(tariff => tariff.id !== editTariff.id);
                                                                    tariffs.push(editTariff)
                                                                    setRole({...role,tariffs:tariffs});
                                                                    setEditTariff(null);
                                                                    setSelectedTariff(null);
                                                                    setEditingTariff(false);
                                                                }
                                                            }
                                                        }
                                                    }
                                                    
                                                }
                                            }
                                            className={`flex flex-col w-auto ${addingTariff || editingTariff?'h-[250px] border-t':'h-0'} pt-1 space-y-4 overflow-hidden`}>
                                            <Input 
                                                label="Name"
                                                type="text"
                                                id="tariffName" 
                                                name="tariffName"  
                                                value={addingTariff?newTariff && newTariff.name?newTariff.name:'':editingTariff?editTariff && editTariff.name?editTariff.name:'':''}
                                                onChange= {(e) => {
                                                    if(addingTariff) {
                                                        setNewTariff({...newTariff,name:e.target.value})
                                                    } else if(editingTariff) {
                                                        setEditTariff({...editTariff,name:e.target.value});
                                                    }
                                                }}
                                                width={inputWidth}
                                            />
                                            <Input 
                                                label="Description"
                                                type="text"
                                                id="tariffDescription" 
                                                name="tariffDescription"  
                                                value={addingTariff?newTariff && newTariff.description?newTariff.description:'':editingTariff?editTariff && editTariff.description?editTariff.description:'':''}
                                                onChange= {(e) => {
                                                    if(addingTariff) {
                                                        setNewTariff({...newTariff,description:e.target.value});
                                                    } else if(editingTariff) {
                                                        setEditTariff({...editTariff,description:e.target.value});
                                                    }
                                                }}
                                                width={inputWidth}
                                            />
                                            <Input 
                                                label='Price'
                                                type='text'
                                                inputMode='numeric'
                                                id='price' 
                                                name='price'  
                                                value={addingTariff?newTariff && newTariff.price?newTariff.price:'':editingTariff?editTariff && editTariff.price?editTariff.price:'':''}
                                                onChange= {(e) => {
                                                    let value = e.target.value;
                                                    if(isNaN(value)) {
                                                        return;
                                                    }
                                                    if(addingTariff) {
                                                        setNewTariff({...newTariff,price:value});
                                                    } else if(editingTariff) {
                                                        setEditTariff({...editTariff,price:value});
                                                    }
                                                }}
                                                width={inputWidth}
                                            />
                                        </div>
                                    </div>
                                }
                                <Message message={message}/>
                                <button style={{'--width':inputWidth+'px'}} 
                                    onClick={handleSubmit} className='flex shrink-0 w-[var(--width)] h-10 mx-auto rounded-lg items-center justify-center bg-[rgb(0,175,240)] hover:bg-[rgba(0,175,240,.7)] text-white'>
                                    Submit
                                </button>
                            </div>
                        </Scrollable>
                    </div>
                </FormValidator>}
                {addAuthority && 
                <addAuthority.Component/>
                }
            </FormDialog>
        </div>
      )
}

export default AddRole