import axios from "axios";
import UserService from "./UserService";
import PaymentService from "./PaymentService";

class EventService {
    
    async saveEvent(event,refresh){
        let responseObject = {};
        await axios.post("event", event,{headers:{Authorization:`bearer ${sessionStorage.getItem("access_token")}`}})
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.saveEvent(event,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response && error.response.data && error.response.data.message) {
                        responseObject =  error.response.data.message;
                    } else {
                        responseObject =  error.message;
                    }
                }); 
            }
        })
        return responseObject;
    }

    async updateEvent(event,refresh){
        let responseObject = {};
        await axios.put("event", event,{headers:{Authorization:`bearer ${sessionStorage.getItem("access_token")}`}})
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.updateEvent(event,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if(error.response && error.response.data && error.response.data.message) {
                        responseObject =  error.response.data.message;
                    } else {
                        responseObject =  error.message;
                    }
                }); 
            }
        })
        return responseObject;
    }

    async getEvent(id){
        let responseObject = {};
        await axios.get(`event/${id}`)
        .then((response) => {
            if(response.data.content && response.data.content.users) {
                for(let user of response.data.content.users) {
                    user.authorities = [];
                }
            }
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            } 
        })

        return responseObject;
    }

    async getEvents (page) {
        let responseObject = null;
        await axios.get("events",{
            params: {
                pageNo:page.pageNo,
                pageSize:page.pageSize,
                sortBy:page.sortBy,
                sortDir:page.sortDir
            }
        })
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            } 
        })

        return responseObject;
    }

    async deleteEvent (id,refresh) {
        let responseObject = {};
        await axios.delete(`event/${id}`,{headers:{Authorization:`bearer ${sessionStorage.getItem("access_token")}`}})
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data) {
                responseObject =  error.response.data;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                responseObject = {status:'401',message:error.message};
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.deleteEvent (id,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                }); 
            } 
        })

        return responseObject;
    }

    async eventRegistration (id) {
        let url = '';
        let event = null;
        await this.getEvent(id)
        .then(async (response) => {
            if(response.status && response.status === 'SUCCESSFUL' && response.content) {
                event = response.content;
            }
        })
        .catch((error) => {
            console.log(error.message);
        });

        if(!event){
            return url;
        }

        await UserService.getCurrentUser()
        .then(async (response) => {
            if(response.status && response.status === 'SUCCESSFUL' && response.content) {
                await PaymentService.getPaymentTariff(event.id,0,null,false)
                .then(async (response) => {
                    if(response.status && response.status === 'SUCCESSFUL' && response.content) {
                        url = `/access/payment/${event?event.id:'0'}/${response.content.id}`;
                    } else {
                        console.log(response.message)
                    }
                })
                .catch((error) => {
                    console.log(error.message);
                });
            } else {
                url = `/access/eventregistration/${id}`;
            }
        })
        .catch((error) => {
            console.log(error.message);
        });

        return url;
    }
}

export default new EventService();