import axios from "axios";

class TariffService {
    async getTariff (tariff,refresh) {
        let responseObject = {};
        await axios.get(`tariff/${tariff}`,{headers:{Authorization:`bearer ${sessionStorage.getItem("access_token")}`}})
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                responseObject = {status:'401',message:error.message};
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.getTariff(tariff,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                }); 
            }
        })
        return responseObject;
    }

    async getTariffs (page,refresh) {
        let responseObject = {};
        await axios.get("tariffs",{
            params: {
                pageNo:page.pageNo,
                pageSize:page.pageSize,
                sortBy:page.sortBy,
                sortDir:page.sortDir
            },
            headers:{
                Authorization:`bearer ${sessionStorage.getItem("access_token")}`
            }
        })
        .then((response) => {
            responseObject = response.data;
        })
        .catch(async (error) => {
            if(error.response && error.response.data && error.response.data.message) {
                responseObject =  error.response.data.message;
            } else {
                responseObject =  error.message;
            }
            if(error.response && error.response.status === 401 && !refresh) {
                responseObject = {status:'401',message:error.message};
                await axios.get("token/refresh",{headers: {Authorization: `bearer ${sessionStorage.getItem("refresh_token")}`}})
                .then(async (response) => {
                    if(response.status === 200) {
                        sessionStorage.setItem("access_token",response.data['access_token']);
                        await this.getTariffs(page,true)
                        .then((data) => {
                            responseObject = data;
                        })
                        .catch((error) => {
                            console.log(error);
                            if(error.response && error.response.data && error.response.data.message) {
                                responseObject =  error.response.data.message;
                            } else {
                                responseObject =  error.message;
                            }
                        })
                    }
                })
                .catch((error) => {
                    console.log(error);
                }); 
            }
        })
        return responseObject;
    }
}

export default new TariffService();