import React,{useEffect,useState,useRef} from 'react'
import { LiaAngleDownSolid,LiaAngleRightSolid } from "react-icons/lia";

const ExpandableList = ({name,children}) => {
    const [expanded,setExpanded] = useState(false);
    const [chidrenHeight,setChildrenHeight] = useState(0);
    const childrenRef = useRef(null);

    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                setChildrenHeight(entry.target.scrollHeight);
            }
        });

        if(childrenRef.current) {
            observer.observe(childrenRef.current)
        }
        return () => {
            observer.disconnect();
        }
    },[]);
  return (
    <div className='flex flex-row w-full h-auto'>
        {/* <button onClick={(e)=> {
            if(childrenRef.current) {
                setChildrenHeight(childrenRef.current.scrollHeight);
            }
            setExpanded(!expanded);
        }} 
            className='flex w-8 h-8 items-center justify-center shrink-0 text-gray-600'>
            {expanded?<LiaAngleDownSolid size={20}/>:<LiaAngleRightSolid size={20}/>}
        </button> */}
        <div onClick={(e)=> {
            if(childrenRef.current) {
                setChildrenHeight(childrenRef.current.scrollHeight);
            }
            setExpanded(!expanded);
        }}  className='flex flex-col w-full h-auto space-y-4 cursor-pointer'>
            <p className='text-lg font-semibold'>{name}</p>
            <div style={{height:expanded?chidrenHeight+'px':0,transition:'all .5s ease-in-out'}} 
                className={`flex w-full overflow-hidden`}>
                <div ref={childrenRef} className='flex flex-col w-full h-auto space-y-4'>
                    {children}
                </div>     
            </div>
        </div>
    </div>
  )
}

export default ExpandableList